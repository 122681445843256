import React from "react";
import ExoTable from "../../../components/exo/ExoTable";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import useFetch from "../../../api/useFetch";
import DateLabel from "../../../components/label/DateLabel";
import { renderHours } from "../../time-tracking/worktime";
import { ExpandTimeSheet } from "../../tax-consultant/timesheets/ExpandTimeSheet";

export default function EmployeeTimesheets({ employeeInfo, own }) {
  const { t } = useTranslation();

  const { apiData, isLoading } = useFetch(
    own ? `time-sheets/own` : `time-sheets?userId[eq]=${employeeInfo.id}`
  );

  const columns = [
    {
      header: t("Month"),
      key: "startDate",
      thAlign: "left",
      tdAlign: "left",
      sortable: true,
      cell: ({ row }) => (
        <Box className="monospace">
          {<DateLabel format="MM/YYYY" date={row.endDate} />}
        </Box>
      ),
    },
    {
      header: t("Worktime"),
      key: "minutesTracked",
      thAlign: "left",
      tdAlign: "left",
      sortable: true,
      cell: ({ row }) => (
        <Box className="monospace">{renderHours(row.minutesTracked)} h</Box>
      ),
    },
  ];
  return (
    <Box className="w-full">
      <ExoTable
        columns={columns}
        data={apiData}
        extendElement={({ row, onDelete, onCreate }) => (
          <ExpandTimeSheet
            row={row}
            onDelete={onDelete}
            onCreate={onCreate}
            preventActions={true}
          />
        )}
        isLoading={isLoading}
      />
    </Box>
  );
}
