import { IndirectCostCenterIcon } from '../icons/IndirectCostCenterIcon';
import { DirectCostCenterIcon } from '../icons/DirectCostCenterIcon';
import { TimeCostCenterIcon } from '../icons/TimeCostCenterIcon';
import ExoDialog from './ExoDialog';
import useFetch from '../../api/useFetch';
import ExoCostCenter from './ExoCostCenter';
import ExoTable from './ExoTable';
import DateLabel from '../label/DateLabel';
import { useTranslation } from 'react-i18next';
import { TimeLabel } from '../label/TimeLabel';
import { Box, useTheme } from '@mui/material';
import moment from 'moment';
import { renderHours } from '../../scenes/time-tracking/worktime';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import MoneyLabel from '../label/MoneyLabel';
import { tokens } from '../../global/theme/tokens';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import RenderHTML from '../preview/RenderHTML';

const ExoCostCenterInfo = ({ costCenter, open, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { apiData, isLoading } = useFetch(
    `cost-centers/${costCenter.id}/items`,
    true
  );

  const receiptColumns = [
    {
      header: t('Title'), // header of column
      key: 'position', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      hiddenMobile: false, // hides the column on mobile
    },
    {
      header: t('Date'), // header of column
      key: 'receiptDate', // key of column in row-object
      thAlign: 'right', // header text alignment
      tdAlign: 'right', // cell text alignment
      width: '150px', // width of column
      cell: ({ row }) => (
        <Box className="monospace">
          <DateLabel date={row.receiptDate} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
      hiddenMobile: true, // hides the column on mobile
    },
    {
      header: t('Costs'), // header of column
      key: 'amount', // key of column in row-object
      thAlign: 'right', // header text alignment
      tdAlign: 'right', // cell text alignment
      width: '140px', // width of column
      cell: ({ row }) => (
        <Box
          className="monospace"
          sx={{
            fontWeight: '600',
            color:
              row.amount < 0 ? colors.money.positive : colors.money.negative,
          }}
        >
          <MoneyLabel money={row.amount} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];
  const timeColumns = [
    {
      header: t('Day'), // header of column
      key: 'timeStart', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      sortable: true, // enable sort function for column
      width: '100px', // width of column
      cell: ({ row }) => (
        <Box className="monospace">
          <DateLabel date={row.timeStart} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t('Start & End'), // header of column
      key: 'timeStart', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      sortable: true, // enable sort function for column
      width: '200px', // width of column
      cell: ({ row }) => (
        <Box className="monospace">
          <TimeLabel time={row.timeStart} /> {` - `}
          <TimeLabel time={row.timeEnd} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t('Amount'), // header of column
      key: 'timeStart', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      sortable: false, // enable sort function for column
      width: '100px', // width of column
      cell: ({ row }) => (
        <Box className="monospace font-semibold">
          {renderHours(
            (moment(row.timeEnd).utc() - moment(row.timeStart).utc()) /
              (1000 * 60)
          )}{' '}
          h
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t('Location'), // header of column
      key: 'timeStart', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      sortable: false, // enable sort function for column
      width: '100px', // width of column
      cell: ({ row }) =>
        row.clockInMetadata && row.clockOutMetadata ? (
          <Box className="flex flex-row gap-2">
            {row.clockInMetadata.city} <TransferWithinAStationIcon />
            {row.clockOutMetadata.city}
          </Box>
        ) : (
          <>{t('Unknown')}</>
        ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t('Comment'), // header of column
      key: 'comment', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      sortable: false, // enable sort function for column
      cell: ({ row }) => <RenderHTML HTML={row.comment} />,
    },
  ];

  return (
    <ExoDialog open={open} onClose={onClose} className="flex flex-col gap-2">
      <ExoCostCenter costCenter={costCenter} />
      <ExoTable
        data={apiData}
        isLoading={isLoading}
        columns={
          costCenter.type === 'TIME_COSTS' ? timeColumns : receiptColumns
        }
      />
    </ExoDialog>
  );
};

export default ExoCostCenterInfo;
