import {
  Box,
  Button,
  Collapse,
  Dialog,
  Typography,
  useTheme,
} from '@mui/material';
import { tokens } from '../../../global/theme/tokens';
import { useEffect, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SdStorageIcon from '@mui/icons-material/SdStorage';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { API_DELETE, DBRequest, useConfig } from '../../../api/api';
import Share from '../../special/Share';
import IosShareIcon from '@mui/icons-material/IosShare';
import IconInfo from './media-info/IconInfo';
import InfoControlls from './media-info/InfoControlls';
import SpeedDialMenu from '../../menu/SpeedDialMenu';

import DeleteIcon from '@mui/icons-material/Delete';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import MediaPreview from './MediaPreview';
import { useTranslation } from 'react-i18next';

const MediaInfo = ({ item, onDelete, onSelect, onClose, noControls }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(Boolean(item.title));
  }, [item]);

  const previewSize = '200px';

  // actions
  const config = useConfig();
  function handleDelete() {
    DBRequest({
      config,
      path: `files/${item.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(item),
    });
  }

  const [openShare, setOpenShare] = useState(false);

  const actions = [
    {
      icon: <IosShareIcon />,
      name: t('Share'),
      onClick: () => setOpenShare(true),
    },

    {
      icon: <DeleteIcon />,
      name: t('Delete'),
      onClick: handleDelete,
    },
  ];

  return (
    <Box
      className="relative h-full"
      sx={{
        backgroundColor: colors.glassSolid,
        display: Boolean(item.title) ? 'block' : 'none',
      }}
    >
      <Button
        onClick={() => setOpen(!open)}
        variant="contained"
        color="secondary"
        sx={{
          position: 'absolute',
          padding: '0px',
          minWidth: 'unset',
        }}
        className=" -translate-x-7 w-7 flex justify-center items-center h-full rounded-l-md ml-0 cursor-pointer backdrop-blur-lg transition-colors"
      >
        <KeyboardArrowRightIcon className={open ? 'rotate-0' : 'rotate-180'} />
      </Button>

      <Collapse orientation="horizontal" in={open}>
        <Box className="p-2 flex flex-col gap-2">
          <MediaPreview
            src={item.temporaryUrl}
            mimeType={item.mimeType}
            size="236px"
          />

          <Box className="flex pb-2">
            <Typography
              sx={{ width: previewSize, wordBreak: 'break-all' }}
              fontSize={14}
              fontWeight={500}
            >
              {item.title}
            </Typography>
            {!onSelect && (
              <SpeedDialMenu className="relative" noBlur actions={actions} />
            )}
          </Box>
          <IconInfo icon={<FingerprintIcon />} text={`ID-${item.id}`} />
          <IconInfo
            icon={<SdStorageIcon />}
            text={formatBytes(item.fileSize)}
          />
          <IconInfo icon={<AspectRatioIcon />} text={item.resolution} />
          <IconInfo icon={<InsertDriveFileIcon />} text={item.type} />
        </Box>
        {Boolean(onSelect) && !noControls && (
          <InfoControlls
            onSelect={() => onSelect()}
            onClose={onClose}
            onDelete={handleDelete}
          />
        )}
      </Collapse>

      <Dialog open={openShare} onClose={() => setOpenShare(false)}>
        <Share
          url={item.temporaryUrl}
          title={t('Share the File')}
          text={t('The Link is 24h valid')}
        />
      </Dialog>
    </Box>
  );
};

export function formatBytes(bytes) {
  const kilobytes = bytes / 1024;
  if (kilobytes < 1) {
    return bytes + ' B';
  }
  const megabytes = kilobytes / 1024;
  if (megabytes < 1) {
    return kilobytes.toFixed(2) + ' KB';
  }
  const gigabytes = megabytes / 1024;
  if (gigabytes < 1) {
    return megabytes.toFixed(2) + ' MB';
  }
  return gigabytes.toFixed(2) + ' GB';
}

export default MediaInfo;
