import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Day, event } from './interfaces';
import { tokens } from '../../../global/theme/tokens';
import moment from 'moment';
import { ExoCalEvent } from './ExoCalEvent.tsx';
import { useTranslation } from 'react-i18next';

export const ExoCalDay = ({
  day,
  noBlur,
  onMouseDown,
  onMouseEnter,
  isSelected,
  selectedEvent,
  isFirst,
  isLast,
  selectionActive,
  onDoubleClick,
  onEventEdit,
  onEventSelect,
}: {
  day: Day;
  noBlur?: Boolean;
  onMouseDown: Function;
  onMouseEnter: Function;
  isSelected?: Boolean;
  isFirst?: Boolean;
  isLast?: Boolean;
  selectionActive: Boolean;
  onEventSelect: Function;
  onDoubleClick: Function;
  onEventEdit: Function;
  selectedEvent: event;
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const isCurrentInterval = day.isCurrentInterval;
  const isToday =
    day.date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');

  // Function to check if the day is the first in the row (week)
  function isFirstInRow(day: Moment): boolean {
    return day.isoWeekday() === 1; // Monday is the first day
  }

  function isInLastRow(day: Moment): boolean {
    const lastDayOfMonth = day.clone().endOf('month');
    const startOfLastWeek = lastDayOfMonth.clone().startOf('isoWeek');
    return (
      day.isSameOrAfter(startOfLastWeek, 'day') &&
      day.isSameOrBefore(lastDayOfMonth, 'day')
    );
  }

  const isFirstInWeek = isFirstInRow(day.date);
  const isInLastWeek = isInLastRow(day.date);

  const fillEmptyLayers = (events) => {
    // 1️⃣ Sortiere alle Events nach Layer (aufsteigend)
    const sortedEvents = [...events].sort((a, b) => a.layer - b.layer);

    // 2️⃣ Bestimme die höchste belegte Layer-Nummer
    const highestLayer = sortedEvents.length
      ? Math.max(...sortedEvents.map((e) => e.layer))
      : 0;

    // 3️⃣ Erstelle eine Map für existierende Layer
    const existingLayers = new Set(sortedEvents.map((e) => e.layer));

    // 4️⃣ Fülle leere Layer mit Platzhalter-Events
    for (let i = 0; i <= highestLayer; i++) {
      if (!existingLayers.has(i)) {
        sortedEvents.push({
          id: `empty-${i}`,
          layer: i,
          isPlaceholder: true, // ⚠ Platzhalter-Event zur UI-Erkennung
        });
      }
    }

    // 5️⃣ Erneut nach Layer sortieren (da wir neue Elemente hinzugefügt haben)
    return sortedEvents.sort((a, b) => a.layer - b.layer);
  };

  return (
    <div
      onDoubleClick={onDoubleClick}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onTouchStart={onMouseDown}
      data-date={day.date.format('YYYY-MM-DD')}
      className="relative rounded-md flex flex-col gap-1"
      style={{
        opacity: isCurrentInterval ? 1 : 0.3,
        userSelect: 'none',
      }}
    >
      <Box
        sx={{
          borderWidth: `1px 1px ${isInLastWeek ? 1 : 0}px ${
            isFirstInWeek ? 1 : 0
          }px`,
          borderColor: colors.card,
        }}
        className="h-full absolute w-full top-0 left-0 -z-10"
      ></Box>
      <Box className="flex w-full justify-end pt-1 pr-1">
        <Typography
          fontSize={'15px'}
          fontWeight={800}
          className="opacity-80 rounded-md h-6 w-6 flex justify-center items-center"
          textAlign="right"
          data-date={day.date.format('YYYY-MM-DD')}
          sx={{
            backgroundColor: isToday ? colors.selected : 'transparent',
          }}
        >
          {day.date.format('D')}
        </Typography>
      </Box>
      <Box className="flex flex-col w-full gap-[3px] overflow-hidden pb-1">
        {/* **New Event Selection** */}
        {selectionActive && (
          <>
            {isSelected ? (
              <ExoCalEvent
                date={day.date.format('YYYY-MM-DD')}
                isFirst={isFirst}
                isLast={isLast}
                event={{ label: t('New Event'), wholeDay: true, type: 'new' }}
                isFirstInWeek={isFirstInWeek}
              />
            ) : (
              <Box className="w-full h-[45px] lg:h-[16px]" />
            )}
          </>
        )}

        {fillEmptyLayers(day.events).map((event) =>
          event.isPlaceholder ? (
            <Box className="w-full h-[45px] lg:h-[16px]" />
          ) : (
            <ExoCalEvent
              key={`${event.id}-${day.date.format('YYYY-MM-DD')}-${
                event.calendarId
              }`}
              date={day.date.format('YYYY-MM-DD')}
              isFirst={event.isFirst}
              isLast={event.isLast}
              event={event}
              isFirstInWeek={isFirstInWeek}
              onClick={(e) => onEventSelect(e, event)}
              onDoubleClick={(e) => onEventEdit(e, event)}
              isSelected={selectedEvent?.id && selectedEvent?.id === event?.id}
            />
          )
        )}
      </Box>
    </div>
  );
};
