import { Box } from '@mui/material';
import ExoAvatar from '../../exo/ExoAvatar';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../api/useFetch';
import LabeledBox from '../../special/LabeledBox';
import ExoDialog from '../../exo/ExoDialog';
import { useState } from 'react';
import SelectCompany from '../../select/SelectCompany';
import SelectContact from '../../select/SelectContact';

const TypeContact = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  size,
}) => {
  const { t } = useTranslation();
  const { apiData } = useFetch(
    field.filter ? `companies?${field.filter}` : 'companies'
  );

  const [open, setOpen] = useState(false);

  const value = dataCollection[field.key];

  function handleSelect(element) {
    setOpen(false);
    updateValidationOnChange(field.key, element);
  }

  return (
    <>
      <LabeledBox
        label={field.label}
        className="w-full"
        onClick={() => setOpen(true)}
        size={size}
      >
        {value && (
          <Box className="flex items-center gap-2">
            <ExoAvatar
              name={`${value.firstName} ${value.lastName}`}
              size="30px"
              type={'contact'}
            />
            {`${value.salutation} ${value.firstName} ${value.lastName}`}
          </Box>
        )}
      </LabeledBox>
      <ExoDialog open={open} onClose={() => setOpen(false)} limitWidth>
        <SelectContact onSelect={handleSelect} preSelected={value} />
      </ExoDialog>
    </>
  );
};

export default TypeContact;
