import { Box, useTheme } from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import { createElement } from 'react';

export const FormFieldsHighlightContainer = ({
  children,
  error,
  field,
  hasValidation,
  hidden,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="w-full flex gap-1"
      sx={{
        gridColumn: `span ${field.colspan} / span ${field.colspan}`,
      }}
    >
      {field.Icon && (
        <Box
          className="flex items-center justify-center px-2 rounded-lg"
          sx={{
            bgcolor: colors.bgInput,
          }}
        >
          {createElement(field.Icon, {
            sx: {
              color: hasValidation
                ? error
                  ? colors.warning
                  : colors.success
                : colors.text,
            },
          })}
        </Box>
      )}
      <Box
        className="w-full flex flex-col rounded-xl"
        sx={{
          borderLeft: hidden
            ? 'unset'
            : `4px solid ${
                hasValidation
                  ? error
                    ? colors.warning
                    : colors.success
                  : colors.selected
              }`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
