import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ContactsIcon from '@mui/icons-material/Contacts';
import { useTranslation } from 'react-i18next';
import LabeledBox from '../../special/LabeledBox';
import TypeCompany from './TypeCompany';
import TypeContact from './TypeContact';
import GroupsIcon from '@mui/icons-material/Groups';
const TypeClient = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  size,
}) => {
  const { t } = useTranslation();
  const type = dataCollection[`${field.key}Type`];

  function handleChangeType(event, newType) {
    if (newType !== type && newType) {
      updateValidationOnChange(
        `${field.key}Type`,
        { [`${field.key}Type`]: newType, [field.key]: null },
        true
      );
    }
  }

  return (
    <Box className="flex flex-row gap-2">
      <ToggleButtonGroup value={type} exclusive onChange={handleChangeType}>
        <ToggleButton
          value="company"
          sx={{
            width: size == 'small' ? '40px' : '53px',
            borderRadius: '10px 0px 0px 10px',
          }}
          size={size}
        >
          <Tooltip title={t('Company')}>
            <ApartmentIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          value="contact"
          sx={{ width: size == 'small' ? '40px' : '53px' }}
          size={size}
        >
          <Tooltip title={t('Contact')}>
            <ContactsIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          value="internal"
          sx={{
            width: size == 'small' ? '40px' : '53px',
            borderRadius: ' 0px 10px 10px 0px',
          }}
          size={size}
        >
          <Tooltip title={t('Internal')}>
            <GroupsIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      {type === 'company' && (
        <TypeCompany
          updateValidationOnChange={updateValidationOnChange}
          dataCollection={dataCollection}
          errors={errors}
          field={{ ...field, label: t('Company') }}
          size={size}
        />
      )}
      {type === 'contact' && (
        <TypeContact
          updateValidationOnChange={updateValidationOnChange}
          dataCollection={dataCollection}
          errors={errors}
          field={{ ...field, label: t('Contact') }}
          size={size}
        />
      )}
      {type === 'internal' && (
        <LabeledBox className="w-full" label={t('Internal')} size={size} />
      )}
    </Box>
  );
};

export default TypeClient;
