import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Transactions from "./Transactions";
import { useNavigate, useParams } from "react-router-dom";
import BankAccount from "./BankAccount";
import Header from "../../components/special/Header";
import { useTranslation } from "react-i18next";
import ExoDialog from "../../components/exo/ExoDialog";
import { DBRequest, useConfig } from "../../api/api";
import ExoBankAccounts from "../../components/exo/ExoBankAccounts";
import ExoAlert from "../../components/exo/ExoAlert";
import { updateOrCreate } from "../../components/special/updateOrCreate";

export default function ShowBankAccount() {
  const { id } = useParams();

  const { t } = useTranslation();
  const config = useConfig();
  const navigate = useNavigate();

  const [apiData, setApiData] = useState(null);
  const [openReauth, setOpenReauth] = useState(false);
  const [openNote, setOpenNote] = useState(null);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    requestBankAccount();
  }, [config]);

  function requestBankAccount() {
    DBRequest({
      config,
      path: `bankaccounts/${id}`,
      onResponse: handleResponse,
    });
  }

  function handleResponse(res) {
    setApiData(res);
    if (res.data.actionRequired) setOpenNote(true);
  }

  function handleClose() {
    requestBankAccount();
    setOpenReauth(false);
    setOpenNote(false);
    window.location.reload();
  }

  function startReauth() {
    setOpenReauth(true);
  }

  function handleCloseNote() {
    setOpenNote(false);
    const newAccount = { ...apiData.data, actionRequired: false };
    setApiData({ ...apiData, data: newAccount });
  }

  return (
    apiData && (
      <Box className="w-full flex flex-col h-full">
        <Header title={t("Bank Accounts")} />

        <Box
          className="rounded-xl mx-auto backdrop-blur-lg mb-4 w-full"
          sx={{
            maxWidth: "900px",
          }}
        >
          <BankAccount
            onClick={() => navigate("/bank-accounts")}
            bank={apiData.data}
          />
        </Box>

        <Box
          className="flex flex-col w-full overflow-y-auto"
          ref={scrollContainerRef}
        >
          <Transactions
            bankAccount={apiData.data}
            scrollContainerRef={scrollContainerRef}
          />
        </Box>
        <ExoAlert
          onSubmit={startReauth}
          open={openNote ?? false}
          onClose={handleCloseNote}
          alert={{
            type: "warning",
            message: t(
              "Access to online banking must be verified again. This process can take up to 5min."
            ),
            cancleLabel: t("Remind me later"),
            submitLabel: t("Re-verify now"),
          }}
        />
        <ExoDialog open={openReauth} onClose={() => setOpenReauth(false)}>
          {apiData?.data.actionRequired && (
            <ExoBankAccounts
              onClose={handleClose}
              webformApi={`bankaccount/${id}/reauthenticate-webform`}
            />
          )}
        </ExoDialog>
      </Box>
    )
  );
}
