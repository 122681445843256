import {
  Box,
  Dialog,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import NewUser from '../../components/new/NewUser';
import { useEffect, useState } from 'react';
import Header from '../../components/special/Header';
import EmployeeList from './EmployeeList';
import { API_GET, DBRequest, useConfig } from '../../api/api';
import EmployeeInfo from './EmployeeInfo';
import { useTranslation } from 'react-i18next';
import { updateOrCreate } from '../../components/special/updateOrCreate';
import ExoDialog from '../../components/exo/ExoDialog';
import useFetch from '../../api/useFetch';
import { tokens } from '../../global/theme/tokens';

const Employees = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const [showArchived, setShowArchived] = useState(false);

  const { apiData, setApiData, isLoading } = useFetch('users?withTrashed=true');

  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    if (!selectedData || selectedData.data.length == 0) return;
    handleEmployeeSelect(selectedData.data[0].id);
  }, [selectedData]);

  useEffect(() => {
    if (!apiData) return;

    calculateArchived();
  }, [apiData, showArchived]);

  function calculateArchived() {
    setSelectedData(null);
    if (showArchived) {
      setSelectedData({
        data: Object.entries(apiData.data)
          .filter(([key, user]) => user.deletedAt !== null)
          .map(([key, user]) => user),
      });
    } else {
      setSelectedData({
        data: Object.entries(apiData.data)
          .filter(([key, user]) => user.deletedAt === null)
          .map(([key, user]) => user),
      });
    }
  }

  // new user
  const [openNewEmployee, setOpenNewEmployee] = useState(false);
  function handleCreatedUser(newUser) {
    if (newUser) {
      const newEmployees = updateOrCreate(apiData, newUser);
      setApiData(newEmployees);
      handleEmployeeSelect(newUser.id);
    }
    calculateArchived();
  }

  function handleUpdateUser(updatedUser) {
    setApiData(updateOrCreate(apiData, updatedUser));
  }

  // employee info
  const [employeeInfo, setEmployeeInfo] = useState(null);
  function handleEmployeeSelect(id) {
    if (!id) return;
    if (employeeInfo && employeeInfo.data && employeeInfo.data.id == id) return;
    setOpenUserList(false);
    setEmployeeInfo(null);
    DBRequest({
      config,
      path: `users/${id}`,
      method: API_GET,
      onResponse: setEmployeeInfo,
    });
  }

  // mobile user List
  const [openUserList, setOpenUserList] = useState(false);
  return (
    <>
      <Box className="flex gap-0 md:gap-8 md:items-center justify-between md:justify-start pr-2 md:flex-row flex-col pb-4 md:pb-0">
        <Header title={t('Employees')} />

        <ToggleButtonGroup
          size="small"
          value={showArchived}
          exclusive
          onChange={() => setShowArchived(!showArchived)}
          aria-label="Show Archived Users"
          className="backdrop-blur-lg"
        >
          <ToggleButton value={false}>{t('Active')}</ToggleButton>
          <ToggleButton value={true}>{t('Archive')}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box className="flex w-full gap-4 flex-col-reverse lg:flex-row h-full overflow-hidden relative">
        {selectedData?.data.length == 0 ? (
          <Box className="w-full h-full flex justify-center items-center">
            <Box className="p-3 rounded-lg" sx={{ background: colors.glass }}>
              <Typography fontSize={20} fontWeight={500}>
                {t('No Employees have been archieved')}
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <EmployeeList
              onSelect={handleEmployeeSelect}
              employees={selectedData}
              isLoading={!selectedData}
              setOpenNewEmployee={setOpenNewEmployee}
              selected={employeeInfo && employeeInfo.data}
              className="hidden lg:flex"
              forbidNew={showArchived}
            />

            {selectedData && selectedData.data.length != 0 && (
              <EmployeeInfo
                employeeInfo={employeeInfo && employeeInfo.data}
                isLoading={!employeeInfo}
                onChange={handleCreatedUser}
                onUpdateUser={handleUpdateUser}
                onOpen={() => setOpenUserList(true)}
                own={false}
              />
            )}
          </>
        )}
        <ExoDialog open={openUserList} onClose={() => setOpenUserList(false)}>
          <EmployeeList
            onSelect={handleEmployeeSelect}
            employees={selectedData}
            isLoading={!selectedData}
            setOpenNewEmployee={setOpenNewEmployee}
            selected={employeeInfo && employeeInfo.data}
          />
        </ExoDialog>
        <ExoDialog open={openNewEmployee} limitWidth>
          <NewUser
            onClose={() => setOpenNewEmployee(false)}
            onCreate={handleCreatedUser}
          />
        </ExoDialog>
      </Box>
    </>
  );
};

export default Employees;
