import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GroupsIcon from '@mui/icons-material/Groups';
import { createElement, useEffect, useState } from 'react';
import ExoDialog from '../../exo/ExoDialog';
import SelectEmployees from '../../select/SelectEmployees';
import ExoAvatar from '../../exo/ExoAvatar';
import { tokens } from '../../../global/theme/tokens';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import HelpIcon from '@mui/icons-material/Help';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TypeCheckbox from './TypeCheckbox';

const TypeUsersCheckbox = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  size,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();
  const [partCollection, setPartCollection] = useState({});

  const [open, setOpen] = useState(null);

  function handleChange(key, newList) {
    // select default mail
    var updatedList = [];
    newList.forEach((element) => {
      if (key === 'employees' || element.selectedEmail)
        return updatedList.push({ ...element });

      updatedList.push({
        ...element,
      });
    });

    // create new participants list
    const newPartList = { ...partCollection, [key]: updatedList };
    setPartCollection(newPartList);

    var newParticipantsReq = [];
    // add users
    newPartList.employees?.forEach((element) => {
      newParticipantsReq.push({
        type: 'user',
        id: element.id,
        participant: element,
        [field.checkbox.key]:
          element[field.checkbox.key] ?? field.checkbox.default,
      });
    });

    updateValidationOnChange(field.key, newParticipantsReq);
  }

  const typeToPartKey = {
    company: 'companies',
    contact: 'contacts',
    user: 'employees',
  };

  function convertToPartData(data) {
    var newPartData = {};

    data?.forEach((element) => {
      if (!newPartData[typeToPartKey[element.type]])
        newPartData[typeToPartKey[element.type]] = [];
      newPartData[typeToPartKey[element.type]].push({
        ...element.participant,
        status: element.status,
        selectedEmail: element.email,
        hasNoMail: !element.email,
      });
    });

    return newPartData;
  }

  useEffect(() => {
    setPartCollection(convertToPartData(dataCollection[field.key]));
  }, [dataCollection[field.key]]);

  const statusMap = {
    0: {
      color: colors.grey[400],
      Icon: RadioButtonUncheckedIcon,
      bgTrans: '40',
    },
    1: {
      color: colors.success,
      Icon: ThumbUpIcon,
      bgTrans: '20',
    },
    2: {
      color: colors.error,
      Icon: ThumbDownIcon,
      bgTrans: '20',
    },
    3: {
      color: colors.warning,
      Icon: HelpIcon,
      bgTrans: '20',
    },
  };

  function removeElement(type, elementToRemove) {
    const updatedList = (partCollection[type] || []).filter(
      (obj) => obj.id !== elementToRemove.id
    );

    return handleChange(type, updatedList);
  }

  function handleCheckboxUpdate(employee, value) {
    const empIndex = partCollection.employees.findIndex(
      (el) => el.id == employee.id
    );
    let newList = partCollection.employees;
    newList[empIndex][field.checkbox.key] = value;

    handleChange('user', newList);
  }

  return (
    <Box className="pb-2">
      <Box className="flex flex-col gap-2 items-start">
        <Box className="w-full flex px-2 justify-between">
          <Box>
            <Typography className="pl-1 pb-1">{field.label}</Typography>

            <Button
              onClick={() => setOpen('employees')}
              variant="contained"
              color="info"
              size={size}
            >
              <Tooltip title={t('Employee')}>
                <Typography className="w-full flex items-center gap-3">
                  <GroupsIcon />
                  {t('Select Recipients')}
                </Typography>
              </Tooltip>
            </Button>
          </Box>
          {partCollection?.employees?.length > 0 && (
            <Box className="flex items-end">
              <Typography fontWeight={550}>{field.checkbox.label}</Typography>
            </Box>
          )}
        </Box>
        <Box className="w-full flex flex-col gap-2">
          {partCollection.employees && (
            <Box className="flex flex-col gap-1">
              <Typography variant="h6" fontSize={12} fontWeight={500}>
                {t('Employees')}
              </Typography>
              {partCollection.employees.map((employee) => (
                <Employee
                  key={'employee-' + employee.id}
                  employee={employee}
                  status={statusMap[employee.status]}
                  onRemove={removeElement}
                  partCollection={partCollection}
                  checkboxField={field.checkbox}
                  checkboxUpdate={(value) =>
                    handleCheckboxUpdate(employee, value)
                  }
                  checkboxValue={
                    dataCollection[field.key]?.find(
                      (emp) => emp.id == employee.id
                    )[field.checkbox.key]
                  }
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <ExoDialog open={open === 'employees'} onClose={() => setOpen(null)}>
        <SelectEmployees
          onSelect={(list) => handleChange('employees', list)}
          preSelected={partCollection.employees}
        />
      </ExoDialog>
    </Box>
  );
};

const Employee = ({
  employee,
  status,
  onRemove,
  partCollection,
  checkboxField,
  checkboxUpdate,
  checkboxValue,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const [dataCollection, setDataCollection] = useState({});

  useEffect(() => {
    const initialCheckboxValue = checkboxValue || checkboxField.default;
    handleCheckboxChange(null, initialCheckboxValue);

    setDataCollection({
      checkbox: initialCheckboxValue,
    });
  }, []);

  const { t } = useTranslation();
  const { picture, salutation, firstName, lastName, email, position } =
    employee;

  const name = `${firstName} ${lastName}`;

  function handleCheckboxChange(key, value) {
    setDataCollection({ checkbox: value });
    checkboxUpdate(value);
  }

  return (
    <Box
      className="flex items-center gap-2 rounded-md pl-1 justify-between"
      sx={{
        border: `1px solid ${status ? status.color : 'transparent'}`,
        bgcolor: status ? `${status.color}${status.bgTrans}` : 'transparent',
      }}
    >
      <Box className="flex items-center gap-2">
        {Boolean(status) && (
          <Tooltip title={status.label}>
            {createElement(status.Icon, {
              sx: { color: status.color },
            })}
          </Tooltip>
        )}
        <ExoAvatar picture={picture?.temporaryUrl} name={name} size="30px" />
        <Box className="flex flex-col">
          <Typography
            fontWeight={500}
          >{`${salutation} ${firstName} ${lastName}`}</Typography>
          <Typography fontSize={12} className="opacity-50">
            {position || email}
          </Typography>
        </Box>
      </Box>
      <TypeCheckbox
        field={{
          ...checkboxField,
          label: '',
          key: 'checkbox',
          noBackground: true,
        }}
        dataCollection={dataCollection}
        errors={{}}
        updateValidationOnChange={handleCheckboxChange}
      />
    </Box>
  );
};

export default TypeUsersCheckbox;
