import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import ExoAvatar from '../../../ExoAvatar';
import { tokens } from '../../../../../global/theme/tokens';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTranslation } from 'react-i18next';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { API_PATCH, DBRequest, useConfig } from '../../../../../api/api';
import { useEffect, useState } from 'react';
import Loader from '../../../../special/Loader';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

export const InviteCalendar = ({ invite, onChange, calendars }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const config = useConfig();

  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // calendar
  useEffect(() => {
    if (calendars) {
      var newFirstCal = null;
      calendars.forEach((cal) => {
        if (!newFirstCal && cal.id)
          newFirstCal = { name: cal.name, id: cal.id, color: cal.color };
      });
      setSelectedCalendar(newFirstCal);
    }
  }, [calendars]);

  const { sender, status } = invite;
  const senderName = `${sender.firstName} ${sender.lastName}`;

  function handleStatusChange(newStatus) {
    DBRequest({
      config,
      path: `invites/${invite.id}/calendar`,
      method: API_PATCH({ status: newStatus, calendar: selectedCalendar }),
      onResponse: onChange,
      onLoading: setIsLoading,
    });
  }

  const statusMap = {
    0: {
      label: t('Pending'),
      border: `1px solid ${colors.selected}`,
      bgcolor: colors.selected + '30',
      icon: <PanoramaFishEyeIcon />,
    },
    1: {
      label: t('Accepted'),
      border: `1px solid ${colors.success}`,
      bgcolor: colors.success + '30',
      icon: <ThumbUpIcon />,
    },
    2: {
      label: t('Declined'),
      border: `1px solid ${colors.error}`,
      bgcolor: colors.error + '30',
      icon: <ThumbDownIcon />,
    },
  };

  return (
    <Box
      className="px-4 py-1 rounded-md"
      sx={{
        border: statusMap[status]?.border,
        bgcolor: colors.card,
      }}
    >
      <Loader active={isLoading} />
      <Box className=" pb-2 flex justify-between">
        <Typography
          className="flex gap-1 items-center"
          fontSize={20}
          fontWeight={500}
        >
          <CalendarMonthIcon />
          {invite.label}
        </Typography>
        <Box
          className="px-3 py-1 rounded-full flex"
          sx={{
            bgcolor: statusMap[status]?.bgcolor,
            border: statusMap[status]?.border,
          }}
        >
          {statusMap[status]?.icon}
          <Typography className="pl-2"> {statusMap[status]?.label}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          height: '1px',
          bgcolor: colors.text + '30',
        }}
        className="rounded-full"
      />
      <Box className="flex gap-4 pb-3 pt-3">
        {status !== 1 && (
          <Button
            variant="contained"
            color="success"
            disableElevation
            size="small"
            onClick={() => handleStatusChange(1)}
          >
            <ThumbUpIcon sx={{ fontSize: 14 }} className="mr-[5px]" />
            {t('Accept')}
          </Button>
        )}
        {status !== 2 && (
          <Button
            variant="contained"
            color="error"
            disableElevation
            size="small"
            onClick={() => handleStatusChange(2)}
          >
            <ThumbDownIcon sx={{ fontSize: 14 }} className="mr-[5px]" />
            {t('Decline')}
          </Button>
        )}
      </Box>
      <Box className="flex items-center gap-2 w-full justify-end">
        <Typography className="opacity-50" fontSize={11}>
          {t('invited by')}
        </Typography>
        <ExoAvatar
          size="20px"
          name={senderName}
          picture={sender.picture?.temporaryUrl}
        />
        <Typography className="opacity-50" fontSize={12}>
          {senderName}
        </Typography>
      </Box>
    </Box>
  );
};
