import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ExoAvatar from "../exo/ExoAvatar";
import { tokens } from "../../global/theme/tokens";
import { API_DELETE, DBRequest, useConfig } from "../../api/api";
import Feedback from "./Feedback";

export const ArchiveUser = ({ employeeInfo, onClose, onUpdateUser }) => {
  const config = useConfig();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  function handleDelete() {
    DBRequest({
      config,
      path: `users/${employeeInfo.id}`,
      method: API_DELETE,
      onResponse: handleResponse,
      onFeedback: handleAlertOpen,
    });
  }

  function handleResponse(deletedUser) {
    onUpdateUser(deletedUser.data);
    onClose();
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <Box className="flex flex-col justify-center items-center mt-10 gap-[85px]">
      <Box className="flex flex-col justify-center items-center gap-5">
        <ExoAvatar
          size="100px"
          picture={employeeInfo.picture?.temporaryUrl}
          name={`${employeeInfo.firstName} ${employeeInfo.lastName}`}
        />
        <Typography fontSize={"20px"}>
          {`${employeeInfo.salutation} ${employeeInfo.firstName} ${employeeInfo.lastName}`}
        </Typography>
      </Box>
      <Typography variant="h3" className="text-center">
        {t("Are you sure you want to archive the following user?")}
      </Typography>
      <Button variant="contained" color="error" onClick={handleDelete}>
        {t(" Archive ")}
      </Button>
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};
