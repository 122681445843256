import { Box, Button, Skeleton, Typography, useTheme } from '@mui/material';
import useFetch from '../../../../api/useFetch';
import { API_POST, DBRequest, useConfig } from '../../../../api/api';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { updateOrCreate } from '../../../special/updateOrCreate';
import { tokens } from '../../../../global/theme/tokens';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTranslation } from 'react-i18next';

export const NewGoogleCalendar = () => {
  const { apiData, setApiData } = useFetch(`calendars/google/all`);
  const config = useConfig();

  const { t } = useTranslation();

  function sync(cal) {
    const { isConnected } = cal;

    DBRequest({
      config,
      path: `calendars/import`,
      method: API_POST({
        calendar: cal,
        method: isConnected ? 'disconnect' : 'connect',
      }),
      onResponse: () =>
        setApiData(
          updateOrCreate(apiData, {
            ...cal,
            isConnected: !isConnected,
          })
        ),
    });
  }

  return (
    <Box className="w-full flex flex-col justify-start items-start h-full gap-2 overflow-y-auto">
      <Box className="flex items-center gap-3">
        <img
          src="/assets/meeting-provider/google-calendar-logo-short.svg"
          className="h-[40px]"
        />
        <Typography variant="h3">{t('Goolge Calendar')}</Typography>
      </Box>
      <Typography>
        {t(
          'Connect your Google calendars and always synchronize your appointments from ExoPaper to ExoPaper.'
        )}
      </Typography>
      <Box className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full gap-3 pt-4">
        {!apiData
          ? Array(5)
              .fill(null)
              .map((_, index) => (
                <Skeleton
                  key={index}
                  sx={{
                    padding: 0,
                    margin: 0,
                    height: '119px',
                    transform: 'unset',
                    borderRadius: '8px',
                  }}
                />
              ))
          : apiData?.data.map((cal) => (
              <Calendar
                cal={cal}
                onSelect={sync}
                isConnected={cal.isConnected}
              />
            ))}
      </Box>
    </Box>
  );
};

export const Calendar = ({
  cal,
  onSelect,
  noIcon,
  ConnectedIcon = WifiIcon,
  InConnectedIcon = WifiOffIcon,
  isConnected,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  const { color, name, id } = cal;

  const Container = onSelect ? Button : Box;

  const Icon = isConnected ? EventAvailableIcon : CalendarMonthIcon;

  return (
    <Container
      onClick={onSelect ? () => onSelect(cal) : null}
      className=" whitespace-nowrap w-full flex flex-col items-center justify-center"
      size="small"
      sx={{
        bgcolor: colors.card,
        paddingX: '18px',
        border: `2px solid ${isConnected ? colors.success : colors.selected}`,
        ':hover': {
          bgcolor: colors.selected,
          border: `2px solid ${isConnected ? colors.success : colors.info}`,
        },
      }}
      disabled={isConnected}
    >
      <Icon sx={{ fontSize: '60px' }} />
      <Typography textAlign="center" fontSize="18px">
        {name}
      </Typography>
      {isConnected ? (
        <Typography sx={{ color: colors.success }}>
          {t('Calendar is connected')}
        </Typography>
      ) : (
        <Typography className="opacity-50">
          {t('Click to connected')}
        </Typography>
      )}
    </Container>
  );
};
