import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import WorktimeBarchart from "../../components/dashboard-modules/WorktimeWeek";
import TaxPrepayment from "../../components/dashboard-modules/TaxPrepayment";
import Sales from "../../components/dashboard-modules/Sales";
import Profit from "../../components/dashboard-modules/Profit";
import Expenses from "../../components/dashboard-modules/Expenses";
import { useTranslation } from "react-i18next";
import { generateMediaQuerysForDashboardGrid } from "../../components/dashboard-modules/dashboardGridManagement";
import { OpenFinancials } from "../../components/dashboard-modules/OpenFinancials";
import { DashboardTodos } from "../../components/dashboard-modules/DashboardTodos";
import { DashboardUpcomingEvents } from "../../components/dashboard-modules/DashboardUpcomingEvents";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const mediaQuery = generateMediaQuerysForDashboardGrid();
  return (
    <>
      <Header title={t("Dashboard")} />
      <Box
        className="grid px-2 md:px-0 gap-4 overflow-auto"
        sx={{
          gridAutoRows: "350px",
          ...mediaQuery,
        }}
      >
        <WorktimeBarchart />
        <DashboardTodos colSpan={1} rowSpan={1} />
        <DashboardUpcomingEvents colSpan={1} rowSpan={1} />
      </Box>
    </>
  );
};

export default Dashboard;
