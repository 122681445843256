import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import FormHelperText from "../FormHelperText";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TypeCustomSelect = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
  startDataCollection,
  size,
  fallbackLabel,
}) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  function handleChange(e) {
    updateValidationOnChange(field.key, e.target.value);
    setValue(e.target.value);
  }

  useEffect(() => {
    if (!startDataCollection || Object.keys(startDataCollection).length == 0)
      return;
    setValue(
      startDataCollection[field.key] === undefined
        ? ""
        : startDataCollection[field.key]
    );
  }, [startDataCollection]);

  useEffect(() => {
    const newValue =
      dataCollection[field.key] === undefined ? "" : dataCollection[field.key];
    setValue(newValue);
  }, [dataCollection[field.key]]);

  return (
    <FormControl fullWidth size={size}>
      <InputLabel id="origin-simple-select-label">{field.label}</InputLabel>
      <Select
        labelId="origin-simple-select-label"
        id="origin-simple-select"
        value={value}
        label={field.label}
        onChange={handleChange}
        error={Boolean(errors[field.key])}
      >
        {!field.noUnselect && <MenuItem value="">{t("No Select")}</MenuItem>}
        {field.options &&
          field.options.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              {typeof option.label == "string" ? t(option.label) : option.label}
            </MenuItem>
          ))}
        {fallbackLabel &&
          !field.options.some((option) => option.value == value) && (
            <MenuItem value={value} disabled>
              {fallbackLabel}
            </MenuItem>
          )}
      </Select>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </FormControl>
  );
};

export default TypeCustomSelect;
