import {
  Box,
  Button,
  IconButton,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { useNavigate, useParams } from "react-router-dom";
import { tokens } from "../../global/theme/tokens";
import useFetch from "../../api/useFetch";
import { useTranslation } from "react-i18next";
import DurationLabel from "../../components/label/DurationLabel";
import ExoAvatar from "../../components/exo/ExoAvatar";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import RenderHTML from "../../components/preview/RenderHTML";
import VideocamIcon from "@mui/icons-material/Videocam";
import DateLabel from "../../components/label/DateLabel";
import { TimeLabel } from "../../components/label/TimeLabel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import NavigationIcon from "@mui/icons-material/Navigation";
import StyledTextContainer from "../../components/preview/StyledTextContainer";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import HelpIcon from "@mui/icons-material/Help";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { API_POST_NO_TOKEN, DBRequest, useConfig } from "../../api/api";
import { ColorModeContext } from "../../global/theme/theme";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";

export const Invites = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  let navigate = useNavigate();
  const config = useConfig();
  const colorMode = useContext(ColorModeContext);

  useEffect(() => {
    if (theme.palette.mode === "dark") colorMode.toggleColorMode();
  }, []);

  const { t } = useTranslation();

  const { key, status } = useParams();
  const { apiData, setApiData } = useFetch(`calendar-events/invite/${key}`);

  const owner = apiData?.data.owner;
  const wholeDay = apiData?.data.wholeDay;
  const location = apiData?.data.location;
  const onlineMeeting = apiData?.data.onlineMeeting;
  const ownStatus = apiData?.data.ownStatus;
  const allowStatusChange =
    !apiData?.data.meetingIsConfirmed && ownStatus !== 1 && ownStatus !== 2;

  const logo = apiData?.data.companyLogo;

  const statusMap = {
    0: {
      label: t("No Response"),
      color: colors.text,
      Icon: <RadioButtonUncheckedIcon className="opacity-75" />,
      message: t("Response expected"),
    },
    1: {
      label: t("Accept"),
      color: colors.success,
      Icon: <ThumbUpIcon className="opacity-75" />,
      message: t("Meeting confirmed"),
    },
    2: {
      label: t("Decline"),
      color: colors.error,
      Icon: <ThumbDownIcon className="opacity-75" />,
      message: t("Meeting canceled"),
    },
    3: {
      label: t("Maybe"),
      color: colors.grey[400],
      Icon: <HelpIcon className="opacity-75" />,
      message: t("Meeting confirmed with reservation"),
    },
  };

  function changeStatus(status) {
    const oldApiData = apiData;
    setApiData(null);
    DBRequest({
      config,
      path: `calendar-events/invite/${key}`,
      method: API_POST_NO_TOKEN({ status }),
      onResponse: setApiData,
      onError: () => setApiData(oldApiData),
    });
  }

  // update status via url
  useEffect(() => {
    if (
      !status ||
      !apiData ||
      apiData.data.ownStatus === 1 ||
      apiData.data.ownStatus === 2
    )
      return;

    // only update if there is no response (0) or the response is maybe (3)
    const statusCodeMap = {
      accept: 1,
      decline: 2,
      maybe: 3,
    };
    const statusCode = statusCodeMap[status];

    DBRequest({
      config,
      path: `calendar-events/invite/${key}`,
      method: API_POST_NO_TOKEN({ status: statusCode }),
      onResponse: setApiData,
    });
    navigate(`/invites/${key}`);
  }, [config, apiData]);

  const integrationMap = {
    googleMeet: {
      logo: "/assets/meeting-provider/google-meet-logo.svg",
      height: "60%",
    },
    teams: {
      logo: "/assets/meeting-provider/teams-logo.png",
      height: "100%",
    },
    zoom: {
      logo: "/assets/meeting-provider/zoom-logo.svg",
      height: "50%",
    },
  };

  const replaceSpaceWithPlus = (str) => str.replace(/ /g, "+");

  return (
    <Div100vh>
      <img
        src={"/assets/uploads/exopaper-meeting.webp"}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: "0",
          left: "0",
          objectPosition: "32%",
          zIndex: "-5",
        }}
      />
      <img
        src={logo?.temporaryUrl}
        className="absolute top-5 right-5 h-[80px]"
      />
      <Box className="w-full h-full flex items-center justify-center">
        <Box
          className="rounded-lg px-5 pb-5 pt-3 backdrop-blur-md flex flex-col overflow-y-auto"
          sx={{
            border: `1px solid rgba(255, 255, 255, 0.2)`,
            background: `linear-gradient(135deg,  rgba(255,255,255, 0.9),rgba(255,255,255, 0.6))`,
            minWidth: "300px",
            maxHeight: "85vh",
          }}
        >
          {apiData ? (
            <>
              <Typography
                variant="h2"
                fontSize={25}
                fontWeight={600}
                className="pb-2"
                sx={{ maxWidth: "500px" }}
              >
                {`${t("Meeting")} - ${apiData?.data.label}`}
              </Typography>
              <Box
                className="px-2 py-[7px] flex flex-col gap-1 rounded-md"
                sx={{
                  border: `1px solid ${colors.text}30`,
                }}
              >
                <Typography variant="h4" fontWeight="500">
                  <CalendarMonthIcon className="mr-2" />
                  <DateLabel date={apiData?.data.startDateTime} />
                </Typography>
                {!wholeDay && (
                  <Typography variant="h4" fontWeight="500" className="">
                    <AccessTimeIcon className="mr-2" />
                    <TimeLabel
                      time={apiData?.data.startDateTime}
                      format="HH:mm"
                    />
                    {` ${t("o'clock")}`}
                  </Typography>
                )}
                {!wholeDay && (
                  <Typography variant="h4" fontWeight="500">
                    <AvTimerIcon className="mr-2" />
                    <DurationLabel
                      startDateTime={apiData?.data.startDateTime}
                      endDateTime={apiData?.data.endDateTime}
                    />
                  </Typography>
                )}
                <Box className="flex pt-2">
                  <Box
                    className="flex gap-1 items-center font-medium text-sm px-3 py-1 rounded-full"
                    sx={{
                      ".MuiSvgIcon-root": {
                        fontSize: "14px",
                      },
                      bgcolor: statusMap[ownStatus].color + "30",
                      border: `1px solid ${statusMap[ownStatus].color}80`,
                    }}
                  >
                    {statusMap[ownStatus].Icon}
                    {statusMap[ownStatus].message}
                  </Box>
                </Box>
                {apiData?.data.agenda && (
                  <Box className="pt-2 max-h-[200px] overflow-y-auto">
                    <Typography fontWeight={600}>{t("Agenda")}</Typography>
                    <StyledTextContainer noWidth backgroundColor="transparent">
                      <RenderHTML HTML={apiData?.data.agenda} />
                    </StyledTextContainer>
                  </Box>
                )}
              </Box>

              <Box className="flex items-center gap-3 pt-4 px-2">
                <ExoAvatar
                  picture={apiData?.data.owner.picture?.temporaryUrl}
                  name={`${owner?.lastName} ${owner?.firstName}`}
                  size="60px"
                />
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight={500}
                  >{`${owner?.salutation} ${owner?.firstName} ${owner?.lastName}`}</Typography>
                  {owner?.position && (
                    <Typography>{owner?.position}</Typography>
                  )}
                  <Link href={`mailto:${owner?.email}`}>
                    <Typography variant="h6">
                      <EmailIcon className="mr-2" fontSize="8px" />
                      {owner?.email}
                    </Typography>
                  </Link>
                  <Link href={`tel:${owner?.phone}`}>
                    <Typography variant="h6">
                      <PhoneIcon className="mr-2" fontSize="8px" />
                      {owner?.phone}
                    </Typography>
                  </Link>
                </Box>
              </Box>
              {(onlineMeeting || location) && (
                <Box className="py-4 px-4">
                  <Box
                    sx={{
                      height: "1px",
                      bgcolor: colors.text + "30",
                    }}
                    className="rounded-full"
                  />
                </Box>
              )}
              {onlineMeeting && (
                <Box className="pb-1">
                  <Typography fontWeight={600} className="pb-1">
                    {t("Online")}
                  </Typography>
                  <Box className="flex gap-2 items-center w-full h-[30px]">
                    <Link href={onlineMeeting.url} target="_blank">
                      <IconButton
                        className="w-full"
                        size="small"
                        disableElevation
                        sx={{
                          bgcolor: colors.info + "30",
                          border: `1px solid ${colors.info}80`,
                          ":hover": {
                            bgcolor: colors.info + "60",
                            border: `1px solid ${colors.info}80`,
                          },
                        }}
                      >
                        <VideocamIcon className="px-[1px]" />
                      </IconButton>
                    </Link>
                    {integrationMap[onlineMeeting.type] ? (
                      <img
                        src={integrationMap[onlineMeeting.type].logo}
                        style={{
                          height: integrationMap[onlineMeeting.type].height,
                        }}
                        className="mr-2"
                      />
                    ) : (
                      <Typography>{t("Online Meeting Link")}</Typography>
                    )}
                  </Box>
                </Box>
              )}

              {location && (
                <Box className="pb-2  py-2">
                  <Typography fontWeight={600}>{t("Location")}</Typography>
                  <Box className="flex items-center gap-1">
                    <Link
                      href={`https://www.google.de/maps/place/${location.street}+${location.houseNumber},+${location.zipCode}+${location.city}`}
                      target="_blank"
                    >
                      <IconButton
                        className="w-full"
                        size="small"
                        disableElevation
                        sx={{
                          bgcolor: colors.info + "30",
                          border: `1px solid ${colors.info}80`,
                          ":hover": {
                            bgcolor: colors.info + "60",
                            border: `1px solid ${colors.info}80`,
                          },
                        }}
                      >
                        <GoogleIcon className="px-[1px]" />
                      </IconButton>
                    </Link>
                    <Link
                      href={`http://maps.apple.com/?address=${replaceSpaceWithPlus(
                        location.houseNumber
                      )},${replaceSpaceWithPlus(
                        location.street
                      )},${replaceSpaceWithPlus(location.city)},${
                        location.zipCode
                      }`}
                      target="_blank"
                    >
                      <IconButton
                        className="w-full"
                        size="small"
                        disableElevation
                        sx={{
                          bgcolor: colors.info + "30",
                          border: `1px solid ${colors.info}80`,
                          ":hover": {
                            bgcolor: colors.info + "60",
                            border: `1px solid ${colors.info}80`,
                          },
                        }}
                      >
                        <AppleIcon className="pb-[2px] pr-[1px]" />
                      </IconButton>
                    </Link>
                    <Box className="block">
                      <Typography className="pb-1 pl-1">{`${location.street} ${location.houseNumber}`}</Typography>
                      <Typography className="pb-1 pl-1">{`${location.zipCode} ${location.city}`}</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {allowStatusChange && (
                <>
                  <Box className="py-4 px-4">
                    <Box
                      sx={{
                        height: "1px",
                        bgcolor: colors.text + "30",
                      }}
                      className="rounded-full"
                    />
                  </Box>
                  <Box
                    className={`grid grid-cols-${
                      ownStatus === 0 ? 3 : 2
                    } gap-2`}
                  >
                    {ownStatus != 1 && (
                      <Button
                        onClick={() => changeStatus(1)}
                        variant="contained"
                        disableElevation
                        endIcon={statusMap[1].Icon}
                        sx={{
                          bgcolor: statusMap[1].color + "30",
                          border: `1px solid ${statusMap[1].color}`,
                          ":hover": {
                            bgcolor: statusMap[1].color + "80",
                            border: `1px solid ${statusMap[1].color}`,
                          },
                        }}
                      >
                        {statusMap[1].label}
                      </Button>
                    )}
                    {ownStatus != 3 && (
                      <Button
                        onClick={() => changeStatus(3)}
                        variant="contained"
                        disableElevation
                        endIcon={statusMap[3].Icon}
                        sx={{
                          bgcolor: statusMap[3].color + "30",
                          border: `1px solid ${statusMap[3].color}`,
                          ":hover": {
                            bgcolor: statusMap[3].color + "80",
                            border: `1px solid ${statusMap[3].color}`,
                          },
                        }}
                      >
                        {statusMap[3].label}
                      </Button>
                    )}
                    {ownStatus != 2 && (
                      <Button
                        onClick={() => changeStatus(2)}
                        variant="contained"
                        disableElevation
                        endIcon={statusMap[2].Icon}
                        sx={{
                          bgcolor: statusMap[2].color + "30",
                          border: `1px solid ${statusMap[2].color}`,
                          ":hover": {
                            bgcolor: statusMap[2].color + "80",
                            border: `1px solid ${statusMap[2].color}`,
                          },
                        }}
                      >
                        {statusMap[2].label}
                      </Button>
                    )}
                  </Box>
                </>
              )}
              {ownStatus === 1 && (
                <Box>
                  <Box className="py-4 px-4">
                    <Box
                      sx={{
                        height: "1px",
                        bgcolor: colors.text + "30",
                      }}
                      className="rounded-full"
                    />
                  </Box>
                  <Typography
                    className="w-full opacity-60 pb-1"
                    textAlign="center"
                  >
                    {t("Has something come up?")}
                  </Typography>
                  <Box className="flex flex-row justify-evenly opacity-60">
                    <Link href={`tel:${owner.phone}`}>
                      <Button startIcon={<PhoneIcon />} size="small">
                        {owner.phone}
                      </Button>
                    </Link>
                    <Link href={`mailto:${owner.email}`}>
                      <Button startIcon={<EmailIcon />} size="small">
                        {owner.email}
                      </Button>
                    </Link>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Box
              className="flex flex-col justify-center items-center h-full"
              sx={{
                height: "300px",
              }}
            >
              <span className="loader"></span>
              <Typography>{t("Loading meeting...")}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Div100vh>
  );
};
