// color design tokens
export const tokens = (mode, customColorTheme, isLowPerformance) => {
  const opacity = isLowPerformance ? 1 : 0.7;

  const fixedColors = {
    text: mode === 'dark' ? '#fafafa' : '#323241',
    glass:
      mode === 'dark' ? 'rgba(60, 60, 75, 0.9)' : 'rgba(215, 215, 220, 0.8)',
    glassSolid: mode === 'dark' ? '#3c3c4b' : '#d7d7dc',
    glassFrom:
      mode === 'dark' ? 'rgba(60, 60, 75, 0.9)' : 'rgba(230,230,235, 0.9)',
    glassTo:
      mode === 'dark' ? 'rgba(60, 60, 75, 0.6)' : 'rgba(230,230,235, 0.6)',
    bgInput:
      mode === 'dark' ? 'rgba(90, 90, 100, 0.6)' : 'rgba(240, 240, 240, 0.6)',
    selected: mode === 'dark' ? '#6e6e78' : '#fafafa',
    card:
      mode === 'dark' ? 'rgba(80, 80, 90, 0.7)' : 'rgba(250, 250, 250, 0.4)',
    backdrop:
      mode === 'dark'
        ? `rgba(60, 60, 75, ${opacity})`
        : `rgba(220, 220, 225, ${opacity})`,
    paper:
      mode === 'dark' ? 'rgba(60, 60, 75, 0.4)' : 'rgba(250, 250, 250, 0.4)',
    success: '#66bb6a',
    info: '#29b6f6',
    warning: '#ffa726',
    error: '#f44336',
    money:
      mode === 'dark'
        ? {
            positive: '#5bc989',
            negative: '#b55555',
            neutral: '#D3D3D3',
          }
        : {
            positive: '#24854c',
            negative: '#852424',
            neutral: '#D3D3D3',
          },
    colorPool: {
      0: '#00A5E3', //blue
      1: '#8DD7BF', //green
      2: '#FF96C5', //pink
      3: '#FF5768', //red
      4: '#FFA23A', //orange
      5: '#FFD872', //yellow
      6: '#CFF800', //lime
      7: '#E7C582', //brown
      8: '#5a5d6e', //gray
    },
  };

  return { ...fixedColors, ...customColorTheme };
};
