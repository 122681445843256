import { Box, Button, Typography } from '@mui/material';
import {
  createElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import FormFields from '../form/FormFields';
import findDifferences from './findDifferencesInDataCollection';
import { useTranslation } from 'react-i18next';
import ExoAlert from './ExoAlert';
import Loader from '../special/Loader';
import ExoDraftManager from './ExoDraftManager';

const ExoForm = forwardRef(
  (
    {
      fields,
      onSubmit = () => {},
      onCancle,
      onChange = () => {},
      debug = false,
      startDataCollection,
      header = '',
      validationSchema,
      submitText,
      noControls = false,
      sx = {},
      className,
      dialog,
      noDifferences,
      alert = {},
      useStartDataOnReset,
      isLoading,
      noEnter,
      draftType,
      outerClassName,
      isNotValid,
      param,
      titleFunction,
      size,
    },
    ref
  ) => {
    const [dataCollection, setDataCollection] = useState({});
    const [alertOpen, setAlertOpen] = useState(false);

    const updateDataCollection = (insertData) => {
      setDataCollection({ ...dataCollection, ...insertData });
    };

    useEffect(() => {
      if (!startDataCollection) return;
      setDataCollection({ ...startDataCollection });
    }, [startDataCollection]);

    function handleSubmit() {
      setAlertOpen(false);
      if (noDifferences) {
        onSubmit(dataCollection);
      } else {
        onSubmit(findDifferences(startDataCollection, dataCollection));
      }
    }

    function requestSubmit() {
      if (alert.message) {
        setAlertOpen(true);
      } else {
        handleSubmit();
      }
    }

    function handleReset() {
      setDataCollection(useStartDataOnReset ? startDataCollection : {});
    }

    // validation --------------------------
    const [valid, setValid] = useState(false);

    const validateDataCollection = async () => {
      if (!validationSchema) {
        setValid(true);
        onChange(dataCollection, true);
        return;
      }
      try {
        await validationSchema.validate(dataCollection);
        if (isNotValid) throw 'error';
        // validation passed for the specific field
        setValid(true);
        onChange(dataCollection, true);
      } catch (err) {
        // validation failed for the specific field
        if (debug) console.log('NOT VALID', err);
        onChange(dataCollection, false);
        setValid(false);
      }
    };

    // enter actions
    const [preventEnter, setPreventEnter] = useState(noEnter);

    useEffect(() => {
      setPreventEnter(noEnter);
    }, [noEnter]);

    useEffect(() => {
      validateDataCollection();

      const handleKeyDown = (event) => {
        if (event.key === 'Enter' && valid && !preventEnter) {
          event.preventDefault(); // Prevent default form submission behavior
          requestSubmit();
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [dataCollection, valid, preventEnter, isNotValid]);

    // exposed functions
    useImperativeHandle(ref, () => ({
      reset: handleReset,
      isValid: () => valid,
      updateDataCollection: updateDataCollection,
      dataCollection,
      setPreventEnter,
    }));

    const classes = dialog
      ? ' flex flex-col gap-4 p-2 relative'
      : 'relative flex flex-col gap-4 pt-2';

    return (
      <Box
        className={
          outerClassName ||
          'flex flex-col gap-4 relative h-full overflow-y-auto'
        }
      >
        {draftType && (
          <ExoDraftManager
            draftType={draftType}
            dataCollection={dataCollection}
            onChange={updateDataCollection}
            startDataCollection={startDataCollection}
            setDataCollection={setDataCollection}
            titleFunction={titleFunction}
          />
        )}
        {isLoading && <Loader active={isLoading} noBlur />}
        {header && (
          <Typography variant="h3" textAlign="center" className="  py-2">
            {header}
          </Typography>
        )}
        <Box className={className ? className : classes} sx={sx}>
          {debug && (
            <Button
              color="warning"
              variant="contained"
              className=" z-40"
              sx={{
                position: 'absolute',
              }}
              onClick={() =>
                console.log(
                  findDifferences(startDataCollection, dataCollection),
                  dataCollection
                )
              }
            >
              LOG
            </Button>
          )}

          {createElement(FormFields, {
            dataCollection,
            startDataCollection: startDataCollection || {},
            updateDataCollection,
            fields,
            validationSchema,
            onPreventEnter: setPreventEnter,
            param,
            size,
          })}
        </Box>
        {!noControls && (
          <FormControls
            handleReset={handleReset}
            onSubmit={requestSubmit}
            isValid={valid}
            onCancle={onCancle}
            submitText={submitText}
          />
        )}
        <ExoAlert
          open={alertOpen}
          onSubmit={handleSubmit}
          onClose={() => setAlertOpen(false)}
          alert={alert}
        />
      </Box>
    );
  }
);

export const FormControls = ({
  onSubmit,
  onCancle,
  submitText,
  isValid,
  handleReset,
}) => {
  const { t } = useTranslation();
  function handleCancle() {
    handleReset();
    if (onCancle) onCancle();
  }
  return (
    <Box
      className={
        'flex flex-row w-full items-center ' +
        (onCancle ? 'justify-between' : 'justify-end')
      }
    >
      {onCancle && (
        <Button onClick={handleCancle} variant="outlined" color="error">
          {t('Cancle')}
        </Button>
      )}
      <Button
        onClick={onSubmit}
        variant="contained"
        color="success"
        disabled={!isValid}
        sx={{ marginBottom: 1 }}
      >
        {submitText ? submitText : t('Save')}
      </Button>
    </Box>
  );
};

export default ExoForm;
