import { Box, Button, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { tokens } from '../../../../global/theme/tokens';
import { useTranslation } from 'react-i18next';
import { renderHours } from '../../../time-tracking/worktime';
import { useEffect, useState } from 'react';
import DateLabel from '../../../../components/label/DateLabel';

export const TimeElement = ({
  time,
  format,
  height,
  maxValue,
  activeTime,
  onMaxValueChange = () => {},
  prefix = '',
  onSelect,
  selected,
  hideDate,
  hideLabel,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const label = prefix
    ? `${prefix} ${moment(time.date).format(format)}`
    : moment(time.date).format(format);
  const { t } = useTranslation();

  const [timeInSeconds, setTimeInSeconds] = useState(
    parseInt(time.totalTimeSeconds * time.percentages.worked)
  );

  // label height
  const labelTopHeight = 16;

  // bar height
  const percent = timeInSeconds ? timeInSeconds / maxValue : 0;

  const barHeight = (height - labelTopHeight) * percent;

  // targetLine height

  const targetPercent = time.targetTimeSeconds / maxValue || 0;
  const targetLineHeight = (height - labelTopHeight) * targetPercent;

  useEffect(() => {
    calcNewTimes(activeTime);
    const tickInterval = setInterval(() => calcNewTimes(activeTime), 1000);
    return () => clearInterval(tickInterval);
  }, [activeTime]);

  function calcNewTimes(timeElement) {
    if (timeElement) {
      const newSecondsIn = moment().diff(
        moment(timeElement.timeStart),
        'seconds'
      );

      const newTotalTimeSeconds =
        newSecondsIn + parseInt(time.totalTimeSeconds);
      setTimeInSeconds(newTotalTimeSeconds);
      if (newTotalTimeSeconds > parseFloat(maxValue)) {
        onMaxValueChange(newTotalTimeSeconds);
      }
    }
  }

  const timeTypeMap = {
    workday: { label: t('Workday'), color: colors.info },
    holiday: { label: t('Holiday'), color: colors.warning },
    vacation: { label: t('Vacation'), color: colors.warning },
    sick: { label: t('Sick'), color: colors.error },
    mixed: { label: t('Mixed'), color: colors.warning },
  };

  const color = activeTime
    ? colors.success // is currently working
    : percent > 0
    ? colors.info // has worked
    : colors.selected; // wasnt working

  // hide target time
  const typesToHide = ['holiday', 'vacation', 'sick', 'mixed'];
  const hideTargetTimeOnSmallDevice = typesToHide.includes(time.type);

  return (
    <Button
      onClick={() => onSelect(selected ? null : time)}
      className="h-full w-full flex-col"
      sx={{ padding: 0, margin: 0, display: 'flex', minWidth: 0 }}
    >
      <Box className="flex h-full w-full flex-col-reverse justify-between @container rounded-md">
        <Box className={`flex flex-col justify-end z-10 @container`}>
          <Box
            className="flex flex-col justify-end  relative"
            sx={{
              height: `${height - labelTopHeight}px`,
            }}
          >
            <Box
              sx={{
                bgcolor: color + '80',
                height: `${barHeight}px`,
                border: `1px  solid ${color}`,
                transition: 'height 0.6s ease',
              }}
              className=" rounded-t-md animate-timebar z-10 mx-1"
            />

            {!isNaN(targetLineHeight) && targetLineHeight > 0 && (
              <>
                <Box
                  sx={{
                    borderTop: `1px dashed ${colors.selected}`,
                    bottom: `0px`,
                    height: `${targetLineHeight}px`,
                    bgcolor: colors.selected + '40',
                  }}
                  className={`w-full absolute z-0 justify-center items-start h-0 ml-0 @lg:ml-[45px] flex monospace text-[8px] md:text-[8px]  lg:text-[10px] `}
                />
                {targetLineHeight > 18 && (
                  <Box
                    sx={{
                      height: `${targetLineHeight}px`,
                    }}
                    className={`w-full absolute z-20 justify-center items-start h-0 ml-0 @lg:ml-[45px] flex monospace text-[8px] md:text-[8px]  lg:text-[10px]`}
                  >
                    <Box className="opacity-60 pt-1 text-center ">
                      {t('target')}{' '}
                      <span style={{ fontWeight: 600 }}>
                        {renderHours(time.targetTimeSeconds / 60)}h
                      </span>
                    </Box>
                  </Box>
                )}
              </>
            )}
            {time.type !== 'workday' && (
              <Box
                className="absolute h-full w-full flex items-center justify-center"
                sx={{
                  backgroundImage: `linear-gradient(0deg, ${
                    timeTypeMap[time.type].color + '60'
                  }, ${timeTypeMap[time.type].color + '00'})`,
                  '@container (min-width: 80px)': {
                    alignItems: 'end',
                    paddingBottom: '5px',
                  },
                }}
              ></Box>
            )}
          </Box>
          <Box
            className="flex flex-col w-full justify-center items-center"
            sx={{
              '@container (min-width: 90px)': {
                flexDirection: 'row',
                gap: '10px',
              },
            }}
          >
            {!hideLabel && (
              <Typography
                variant="h6"
                fontSize={10}
                textAlign="center"
                fontWeight={500}
                className=" whitespace-nowrap"
              >
                {t(label)}
              </Typography>
            )}
            {!hideDate && (
              <Typography
                variant="h6"
                fontSize={10}
                textAlign="center"
                className="opacity-60"
                sx={{
                  '@container (max-width: 80px)': {
                    fontSize: 'clamp(8px, 1.2vw, 10px)',
                  },
                }}
              >
                <DateLabel date={time.date} />
              </Typography>
            )}
          </Box>
        </Box>

        {Boolean(timeInSeconds) && timeInSeconds > 0 && (
          <Box
            textAlign="center"
            variant="h6"
            className="monospace text-[8px] md:text-[8px]  lg:text-[10px]"
            sx={{
              height: `${labelTopHeight}px`,
              '@container (min-width: 25px)': {
                display: 'block',
              },
            }}
          >
            {renderHours(timeInSeconds / 60)}h
          </Box>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: selected ? colors.info : 'transparent',
          height: '4px',
        }}
        className="w-full rounded-t-full"
      ></Box>
    </Button>
  );
};
