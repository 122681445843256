import React, { useEffect, useRef, useState } from 'react';
import ModuleLayout from './ModuleLayout';
import useFetch from '../../api/useFetch';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import { TimeLabel } from '../label/TimeLabel.jsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ExoInfiniteScroll from '../exo/ExoInfiniteScroll.jsx';
import { t } from 'i18next';
import { ExoCalPopper } from '../exo/cal/ExoCalPopper.jsx';
import { updateOrCreate } from '../special/updateOrCreate.js';
import Feedback from '../special/Feedback.jsx';

export const DashboardUpcomingEvents = ({ rowSpan, colSpan }) => {
  const { apiData, setApiData } = useFetch('modules/upcoming-events');
  const [groupedEvents, setGroupedEvents] = useState(null);
  const scrollContainerRef = useRef(null);
  const [allEvents, setAllEvents] = useState([]);
  const [calendars, setCalendars] = useState(null);

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: 'info',
    alertText: 'test',
  });
  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  // click actions
  const [editMode, setEditMode] = useState(false);
  const [selectionData, setSelectionData] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);

  // popper
  const [popperAnchor, setPopperAnchor] = useState(null);
  function handleOpenPopper(e, event) {
    setPopperOpen(true);
    setEditMode(false);
    setSelectionData(event);
  }

  function handleClosePopper() {
    setPopperOpen(false);
    setSelectionData(null);
  }

  function handleUpdate(res) {
    const newEvents = updateOrCreate(allEvents, res);

    setAllEvents(newEvents);
    setGroupedEvents(groupEventsByDay(newEvents, apiData.data.calendars));
  }

  useEffect(() => {
    if (scrollContainerRef.current) {
      setPopperAnchor(scrollContainerRef.current);
    }
  }, []);

  // prepair data
  function groupEventsByDay(events, calendars) {
    //prepair calendarMap
    var calendarIdMap = {};

    calendars.forEach((calendar) => {
      calendarIdMap[calendar.id] = calendar;
    });

    //create event arrays
    const daysMap = new Map();

    events.forEach((event) => {
      const start = moment(event.startDateTime);
      const end = moment(event.endDateTime);

      let currentDay = start.clone();

      while (currentDay.isSameOrBefore(end, 'day')) {
        const day = currentDay.format('DD');
        const month = t(currentDay.format('MMMM'));
        const weekDay = t(currentDay.format('dddd'));
        const dayKey = `${weekDay}, ${day}. ${month}`;

        // today or tomorrow
        const todayDiff = currentDay.diff(moment().startOf('day'), 'days');
        const label =
          todayDiff === 0
            ? t('today')
            : todayDiff === 1
            ? t('tomorrow')
            : dayKey;

        if (!daysMap.has(dayKey)) {
          daysMap.set(dayKey, {
            day: currentDay.format('YYYY-MM-DD'),
            label,
            wholeDayEvents: [],
            normalEvents: [],
          });
        }

        const dayEntry = daysMap.get(dayKey);

        dayEntry[event.wholeDay ? 'wholeDayEvents' : 'normalEvents'].push({
          ...event,
          calendar: calendarIdMap[event.calendarId],
          color: calendarIdMap[event.calendarId]?.color,
        });

        currentDay.add(1, 'day');
      }
    });

    // Filter out empty days
    const filteredDays = Array.from(daysMap.values()).filter(
      (entry) =>
        entry.wholeDayEvents.length > 0 || entry.normalEvents.length > 0
    );

    // Sort normal events by startDateTime for each day
    filteredDays.forEach((entry) => {
      entry.normalEvents.sort(
        (a, b) =>
          moment(a.startDateTime).valueOf() - moment(b.startDateTime).valueOf()
      );
    });

    return filteredDays;
  }

  useEffect(() => {
    if (apiData && !groupedEvents) {
      setAllEvents(apiData.data.events);
      setGroupedEvents(
        groupEventsByDay(apiData.data.events, apiData.data.calendars)
      );
      setCalendars(apiData.data.calendars);
    }
  }, [apiData]);

  function handleNextPage(res) {
    // calculate new gruped events
    const newGroupedEvents = groupEventsByDay(
      [...allEvents, ...res.data.events],
      res.data.calendars
    );
    setGroupedEvents(newGroupedEvents);
    // update all events array
    setAllEvents((prev) => [...prev, ...res.data.events]);
    // update apiData for pagination
    setApiData(res);
  }

  function handleDelete(eventId) {
    const newEvents = allEvents.filter((obj) => obj.id !== eventId);
    setAllEvents(newEvents);
    setApiData({ ...apiData, events: newEvents });
  }

  // event interaction
  function handleEventSelect(e, event) {
    setPopperOpen(true);
    setSelectionData(event);
  }
  function handleEventEdit(e, event) {
    //if (!event.writePermissions) return;
    setPopperOpen(true);
    setSelectionData(event);
    setEditMode(true);
  }

  return (
    <ModuleLayout
      colSpan={colSpan}
      rowSpan={rowSpan}
      label={t('Upcoming Events')}
    >
      <Box
        ref={scrollContainerRef}
        className="flex flex-col  h-full overflow-y-auto"
      >
        {apiData && (
          <ExoInfiniteScroll
            scrollContainerRef={scrollContainerRef}
            apiData={apiData}
            onResponse={handleNextPage}
            className="flex flex-col gap-4"
            triggerDist={200}
          >
            {groupedEvents?.map((group) => (
              <Box key={group.day}>
                <Typography
                  fontSize={11}
                  fontWeight={500}
                  className="opacity-70"
                >
                  {group.label}
                </Typography>
                <Box className="flex flex-col gap-1 pr-1">
                  {group.wholeDayEvents?.map((event) => (
                    <ShowEventWholeDay
                      key={event.id}
                      event={event}
                      onClick={(e) => handleEventSelect(e, event)}
                      onDoubleClick={(e) => handleEventEdit(e, event)}
                    />
                  ))}
                  {group.normalEvents?.map((event) => (
                    <ShowEventTimespan
                      key={event.id}
                      event={event}
                      day={group.day}
                      onClick={(e) => handleOpenPopper(e, event)}
                      onDoubleClick={(e) => handleEventEdit(e, event)}
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </ExoInfiniteScroll>
        )}
      </Box>
      {apiData && (
        <ExoCalPopper
          anchorEl={popperAnchor}
          open={popperOpen}
          onClose={handleClosePopper}
          data={selectionData}
          calendars={calendars}
          onFeedback={handleFeedback}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      )}
      <Feedback state={alertState} setState={setAlertState} />
    </ModuleLayout>
  );
};

export const ShowEventWholeDay = ({
  event,
  onClick,
  selectedData,
  onDoubleClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const { label, location } = event;

  const isSelected = selectedData?.id === event.id;

  return (
    <Box
      onClick={onClick}
      onDoubleClick={event.calendar?.id ? onDoubleClick : null}
      className=" cursor-pointer rounded-lg w-full h-[34px] relative flex items-center pl-2"
      sx={{
        bgcolor: isSelected ? colors.info + '20' : event.calendar?.color + '20',
        border: `1px solid ${isSelected ? colors.info : 'transparent'}`,
        ':hover': {
          border: `1px solid ${colors.info}`,
          bgcolor: colors.info + '20',
        },
      }}
    >
      <Box className="w-[5px] h-full flex items-center justify-center">
        <Box
          sx={{
            bgcolor: event.calendar?.color,
          }}
          className="rounded-full w-[4px] h-[60%]"
        />
      </Box>
      <Box className="h-full w-full pl-2 pr-3 flex justify-between items-center py-1 gap-1">
        <Typography
          fontSize={14}
          fontWeight={500}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          className=" whitespace-nowrapflex flex-col whitespace-nowrap"
        >
          <span>{label}</span>
          {location && (
            <span className="opacity-50 " style={{ fontSize: 9 }}>
              {`${location.street} ${location.houseNumber} - ${location.zipCode} ${location.city}`}
            </span>
          )}
        </Typography>
        <Typography fontSize={11} fontWeight={500}>
          {t('All day')}
        </Typography>
      </Box>
    </Box>
  );
};

export const ShowEventTimespan = ({
  event,
  day,
  onClick,
  selectedData,
  onDoubleClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const isStartDay =
    moment(day)
      .startOf('day')
      .diff(moment(event.startDateTime).startOf('day'), 'days') === 0;
  const isEndDay =
    moment(day)
      .startOf('day')
      .diff(moment(event.endDateTime).startOf('day'), 'days') === 0;

  const isWholeDay = !isStartDay && !isEndDay;

  const { label, location } = event;
  const isSelected = selectedData?.id === event.id;

  return (
    <Box
      onClick={onClick}
      onDoubleClick={event.calendar?.id ? onDoubleClick : null}
      className=" rounded-lg w-full h-[34px] relative flex items-center pl-2 cursor-pointer"
      sx={{
        bgcolor: isSelected ? colors.info + '20' : event.calendar?.color + '20',
        border: `1px solid ${isSelected ? colors.info : 'transparent'}`,
        ':hover': {
          border: `1px solid ${colors.info}`,
          bgcolor: colors.info + '20',
        },
      }}
    >
      <Box className="w-[5px] h-full flex items-center justify-center">
        <Box
          sx={{
            bgcolor: event.calendar?.color,
          }}
          className="rounded-full w-[4px] h-[60%]"
        />
      </Box>
      <Box className="h-full w-full pl-2 pr-3 flex justify-between items-center py-1 gap-1">
        <Typography
          fontSize={14}
          fontWeight={500}
          className=" whitespace-nowrap overflow-x-auto flex flex-col"
        >
          <span>{label}</span>
          {location && (
            <span className="opacity-50 " style={{ fontSize: 9 }}>
              {`${location.street} ${location.houseNumber} - ${location.zipCode} ${location.city}`}
            </span>
          )}
        </Typography>

        {isWholeDay ? (
          <Typography fontSize={11} fontWeight={500}>
            {t('All day')}
          </Typography>
        ) : (
          <Typography
            fontSize={11}
            className="flex flex-col h-full justify-center monospace"
            sx={{
              span: {
                height: '12px',
                lineHeight: '12px',
              },
            }}
            textAlign="right"
          >
            <span style={{ fontWeight: 500 }}>
              {isStartDay && (
                <TimeLabel time={event.startDateTime} format="HH:mm" />
              )}
              {!isStartDay && isEndDay && t('End')}
            </span>

            <span
              style={{
                opacity: isEndDay ? 0.5 : 0,
              }}
            >
              <TimeLabel time={event.endDateTime} format="HH:mm" />
            </span>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
