import { Box, Typography, useTheme } from '@mui/material';
import { SearchResponseHit } from './SearchResponseHit';
import Cookies from 'universal-cookie';
import { searchResultMap } from './searchResultsMap';
import { tokens } from '../../../global/theme/tokens';
import { useTranslation } from 'react-i18next';

export const SearchResponseGroup = ({ group, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const cookies = new Cookies();
  const { t } = useTranslation();

  const prefixLength = cookies.get('searchPrefix').length;

  const type = group.indexUid.substring(prefixLength);
  if (!searchResultMap[type] || group.estimatedTotalHits === 0) return;
  return (
    <Box className="flex flex-col gap-2 rounded-lg">
      <Typography
        className="sticky top-0 backdrop-blur-lg px-3 py-2 rounded-lg"
        variant="h4"
        sx={{ fontWeight: 500 }}
      >
        {t(searchResultMap[type].label)}
      </Typography>
      {group.hits.map((hit) => (
        <SearchResponseHit hit={hit} type={type} onClick={onClose} />
      ))}
    </Box>
  );
};
