import { Box, Button, Dialog, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';

const ExoColorPicker = ({
  color = '',
  open,
  onClose = () => {},
  onSelect = () => {},
}) => {
  const { t } = useTranslation();

  const [selectedColor, setSelectedColor] = useState(color);

  function handleColorChange(hex) {
    setSelectedColor(hex);
  }

  function handleSelect() {
    onSelect(selectedColor);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Paper className="flex flex-col justify-center items-center gap-4 p-4">
        <Typography variant="h3">{t('Pick a Color')}</Typography>
        <HexColorPicker color={selectedColor} onChange={handleColorChange} />
        <Box className="flex flex-row justify-between w-full gap-4">
          <Button onClick={onClose} variant="outlined" color="error">
            {t('Cancle')}
          </Button>
          <Button variant="contained" onClick={handleSelect} color="success">
            {t('Select')}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default ExoColorPicker;
