import { useTheme } from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import Header from '../../components/special/Header';
import { ExoCalGroup } from '../../components/exo/ExoCalGroup';
import { useTranslation } from 'react-i18next';

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  return (
    <>
      <Header title={t('Calendar')} subtitle="Full Calendar Interactive Page" />
      <ExoCalGroup />
    </>
  );
};

export default Calendar;
