import { Box, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { numberToString, stringToNumber } from "../../special/numberConverter";
import TypeCustomSelect from "./TypeCustomSelect";
import { useTranslation } from "react-i18next";

const TypeNumberSelect = ({
  field,
  dataCollection,
  errors,
  updateValidationOnChange,
  startDataCollection,
  size,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(dataCollection[field.key] || "");
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!startDataCollection || !startDataCollection[field.key]) return;
    setValue(
      numberToString(startDataCollection[field.key] || "", field.decimalPlaces)
    );
  }, [startDataCollection]);

  useEffect(() => {
    if (focused) return;
    if (dataCollection[field.key] !== undefined)
      setValue(numberToString(dataCollection[field.key]), field.decimalPlaces);
  }, [dataCollection[field.key]]);

  function handleFocus() {
    setFocused(true);
    if (!dataCollection[field.key] || dataCollection[field.key] === 0)
      setValue("");
  }

  function handleTextChange(value) {
    if (stringToNumber(value.target.value, field.decimalPlaces) !== 0) {
      updateValidationOnChange(
        [field.key],
        stringToNumber(value.target.value, field.decimalPlaces)
      );
    }
    setValue(value.target.value);
  }

  function handleBlur(value) {
    setFocused(false);
    if (stringToNumber(value.target.value, field.decimalPlaces) !== 0) {
      updateValidationOnChange(
        [field.key],
        stringToNumber(value.target.value, field.decimalPlaces)
      );
      setValue(
        numberToString(
          stringToNumber(value.target.value, field.decimalPlaces),
          field.decimalPlaces
        )
      );
    }
  }

  function handleUpdate(key, value) {
    dataCollection[key] = value;
    updateValidationOnChange(key, value);
  }

  return (
    <Box className="flex gap-1 items-center">
      <TypeCustomSelect
        dataCollection={dataCollection}
        field={{
          ...field,
          options: field.options,
        }}
        errors={errors}
        updateValidationOnChange={handleUpdate}
        fallbackLabel={t("Custom")}
      />
      <TextField
        //label={field.label}
        value={value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleTextChange}
        multiline={Boolean(field.multiline)}
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
        size={size}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{field.unit}</InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default TypeNumberSelect;
