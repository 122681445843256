import { Box, Button, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const ProjectAddElementButton = ({ label, onClick, size }) => {
  return (
    <Tooltip title={label}>
      <Box className="backdrop-blur-md flex rounded-lg">
        <Button
          onClick={onClick}
          className=" opacity-40 w-full flex "
          variant="contained"
          color="secondary"
        >
          <AddIcon
            className=" opacity-30"
            sx={{ fontSize: size === 'small' ? '30px' : '60px' }}
          />
        </Button>
      </Box>
    </Tooltip>
  );
};
