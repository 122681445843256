import { Box } from '@mui/material';
import NewDescription from '../../new/NewDescription';
import FormHelperText from '../FormHelperText';
import { useTranslation } from 'react-i18next';

const TypeRichText = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors = {},
  onPreventEnter,
  sx,
  size,
}) => {
  const { t } = useTranslation();

  function handleChange(description) {
    if (countTextCharacters(description) <= 0) {
      updateValidationOnChange(field.key, '');
    } else {
      updateValidationOnChange(field.key, description);
    }
  }

  function countTextCharacters(htmlString) {
    // Create a temporary div element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    // Extract the text content from the div element
    const textContent = tempDiv.textContent || tempDiv.innerText || '';

    // Remove extra whitespaces and line breaks, then count the characters
    const plainText = textContent.trim().replace(/\s+/g, ' ');
    const charCount = plainText.length;

    return charCount;
  }

  return (
    <Box className="col-span-2">
      <NewDescription
        onPreventEnter={onPreventEnter}
        label={field.label}
        onSave={handleChange}
        startHTML={dataCollection[field.key]}
        size={size}
      />
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </Box>
  );
};
export default TypeRichText;
