import { Box, Typography, useTheme } from "@mui/material";
import TypeNumber from "../../form/form-fields/TypeNumber";
import MoneyLabel from "../../label/MoneyLabel";
import { useEffect, useState } from "react";
import { tokens } from "../../../global/theme/tokens";

const HOAIPhase = ({
  updateValidationOnChange,
  togglePhase,
  phase,
  phaseNumber,
  baseHonorarium,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  //const field = { label: "test", key: "phaseCheckbox" };
  const [isCrossed, setIsCrossed] = useState(false);

  const key = `${phaseNumber}_phase%`;

  useEffect(() => {
    setDataCollection({
      [key]: phase.value ?? phase.maxPercent,
    });
  }, [phase]);

  const [dataCollection, setDataCollection] = useState({
    [key]: phase.value ?? phase.maxPercent,
  });

  const value = phase.value ?? phase.maxPercent;
  const phaseHonorarium =
    Math.round((100 * baseHonorarium * Number(value)) / 100) / 100;

  function handleChange(key, value) {
    /*if (value < 0) {
      setDataCollection({ [key]: 0 });
      updateValidationOnChange(key, 0);
    } else if (value > 100) {
      setDataCollection({ [key]: 100 });
      updateValidationOnChange(key, 100);
    } else {
      updateValidationOnChange(key, value);
      setDataCollection({ [key]: value });
    }*/
    updateValidationOnChange(key, value);
    setDataCollection({ [key]: value });
  }

  return (
    <Box className="flex items-center gap-3 px-2 py-1 justify-between">
      <Typography
        className="w-full overflow-hidden"
        onClick={() => {
          togglePhase(`${phaseNumber}_phase%`, !isCrossed);
          setIsCrossed(!isCrossed);
        }}
        sx={{
          cursor: "pointer",
          textDecoration: isCrossed ? "line-through" : "none",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          paddingY: "8px",
          paddingX: "8px",
          borderRadius: "10px",
          border: `1px solid transparent`,
          ":hover": {
            bgcolor: colors.info + "20",
            border: `1px solid ${colors.info}80`,
          },
        }}
      >
        {phaseNumber + 1 + ". " + phase.title}
      </Typography>

      <Box className="flex items-center justify-between gap-5">
        <Typography
          color={colors.grey[600]}
          fontSize={12}
          sx={{ width: "60px", minWidth: "60px" }}
          className="hidden md:block"
        >
          {"max: " + phase.maxPercent + "%"}
        </Typography>
        <Typography
          className="flex items-center"
          sx={{ width: "100px", minWidth: "100px" }}
        >
          <TypeNumber
            dataCollection={dataCollection}
            updateValidationOnChange={handleChange}
            errors={{}}
            field={{
              label: "",
              key: [key],
              unit: "%",
            }}
            size="small"
          />
        </Typography>
        <Typography
          className="text-right pr-1"
          sx={{ width: "125px", minWidth: "125px" }}
        >
          <MoneyLabel money={isCrossed ? 0 : phaseHonorarium || 0} />
        </Typography>
      </Box>
    </Box>
  );
};

export default HOAIPhase;
