import { useTheme, Box, Typography, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Feedback from '../../../special/Feedback';
import { tokens } from '../../../../global/theme/tokens';
import { useTranslation } from 'react-i18next';
import { ExoCalEvent } from '../../cal/ExoCalEvent.tsx';
import ExoAvatar from '../../ExoAvatar';
import { TimeLabel } from '../../../label/TimeLabel.jsx';
import moment from 'moment';
import { ExoCalPopper } from '../../cal/ExoCalPopper.jsx';
import { updateOrCreate } from '../../../special/updateOrCreate.js';

export const ProjectEventList = ({ events }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [allEvents, setAllEvents] = useState(null);
  const [groupedEvents, setGroupedEvents] = useState(null);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (events) {
      setGroupedEvents(groupEventsByDay(events));
      setAllEvents(events);
    }
  }, [events]);

  const { t } = useTranslation();

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: 'info',
    alertText: 'test',
  });
  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  // click actions
  const [editMode, setEditMode] = useState(false);
  const [selectionData, setSelectionData] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);

  // popper
  const [popperAnchor, setPopperAnchor] = useState(null);

  function handleClosePopper() {
    setPopperOpen(false);
    setSelectionData(null);
  }

  function handleUpdate(res) {
    const newEvents = updateOrCreate(allEvents, res);

    setAllEvents(newEvents);
    setGroupedEvents(groupEventsByDay(newEvents));
  }

  useEffect(() => {
    if (scrollContainerRef.current) {
      setPopperAnchor(scrollContainerRef.current);
    }
  }, []);

  // prepair data
  function groupEventsByDay(events) {
    //create event arrays
    const daysMap = new Map();

    events.forEach((event) => {
      const start = moment(event.startDateTime);
      const end = moment(event.endDateTime);

      let currentDay = start.clone();

      while (currentDay.isSameOrBefore(end, 'day')) {
        const day = currentDay.format('DD');
        const month = t(currentDay.format('MMMM'));
        const weekDay = t(currentDay.format('dddd'));
        const dayKey = `${weekDay}, ${day}. ${month}`;

        // today or tomorrow
        const todayDiff = currentDay.diff(moment().startOf('day'), 'days');
        const label =
          todayDiff === 0
            ? t('today')
            : todayDiff === 1
            ? t('tomorrow')
            : dayKey;

        if (!daysMap.has(dayKey)) {
          daysMap.set(dayKey, {
            day: currentDay.format('YYYY-MM-DD'),
            label,
            wholeDayEvents: [],
            normalEvents: [],
          });
        }

        const dayEntry = daysMap.get(dayKey);

        dayEntry[event.wholeDay ? 'wholeDayEvents' : 'normalEvents'].push({
          ...event,
          calender: event.userCalendar,
        });

        currentDay.add(1, 'day');
      }
    });

    // Filter out empty days
    const filteredDays = Array.from(daysMap.values()).filter(
      (entry) =>
        entry.wholeDayEvents.length > 0 || entry.normalEvents.length > 0
    );

    // Sort normal events by startDateTime for each day
    filteredDays.forEach((entry) => {
      entry.normalEvents.sort(
        (a, b) =>
          moment(a.startDateTime).valueOf() - moment(b.startDateTime).valueOf()
      );
    });

    return filteredDays;
  }

  function handleDelete(eventId) {
    const newEvents = allEvents.filter((obj) => obj.id !== eventId);
    setAllEvents(newEvents);
  }
  // event interaction
  function handleEventSelect(event) {
    setPopperOpen(true);
    setSelectionData(event);
  }
  return (
    <Box
      ref={scrollContainerRef}
      className=" p-4 pt-2 rounded-lg mt-4 backdrop-blur-lg "
      sx={{ bgcolor: colors.glass }}
    >
      <Box className="flex flex-col gap-2 h-[100px] max-h-[100px] overflow-y-auto">
        {groupedEvents?.map((group) => (
          <Box key={group.day}>
            <Typography fontSize={11} fontWeight={500} className="opacity-70">
              {group.label}
            </Typography>
            <Box className="flex flex-col gap-1 pr-1">
              {group.wholeDayEvents?.map((event) => (
                <Event
                  key={event.id}
                  event={event}
                  onSelect={handleEventSelect}
                  isSelected={selectionData?.id === event.id}
                />
              ))}
              {group.normalEvents?.map((event) => (
                <Event
                  key={event.id}
                  event={event}
                  onSelect={handleEventSelect}
                  isSelected={selectionData?.id === event.id}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      <Feedback setState={setAlertState} state={alertState} />
      <ExoCalPopper
        anchorEl={popperAnchor}
        open={popperOpen}
        onClose={handleClosePopper}
        data={selectionData}
        calendars={null}
        onFeedback={handleFeedback}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </Box>
  );
};

const Event = ({ event, onSelect, isSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const { startDateTime, endDateTime, owner, label, userCalendar, wholeDay } =
    event;

  const isMultiDayEvent = !moment(startDateTime).isSame(endDateTime, 'day');

  const ownerName = `${owner.firstName} ${owner.lastName}`;

  return (
    <Box
      className="flex gap-2 w-full items-center cursor-pointer"
      onClick={() => onSelect(event)}
    >
      <Tooltip title={ownerName}>
        <Box>
          <ExoAvatar
            picture={owner.picture?.temporaryUrl}
            size={'30px'}
            name={ownerName}
          />
        </Box>
      </Tooltip>
      <Box
        className="h-[30px] w-full px-1 flex items-center"
        sx={{
          border: `1px solid ${isSelected ? colors.info : 'transparent'}`,
          bgcolor: userCalendar.color + '40',
          borderRadius: wholeDay ? '8px' : '3px',
          ':hover': {
            border: `1px solid ${colors.info}`,
            bgcolor: colors.info + '20',
          },
        }}
      >
        <Box
          sx={{
            bgcolor: userCalendar.color + 'AA',
          }}
          className="w-[3px] h-[80%] mr-1 rounded-sm hidden lg:block"
        ></Box>
        <Box className="flex justify-between w-full h-full items-center">
          <Typography fontWeight={500}>{label}</Typography>
          <Box>
            <Typography
              textAlign="right"
              className={wholeDay ? '' : 'monospace'}
              fontSize={11}
              fontWeight={500}
            >
              {wholeDay ? (
                t('All day')
              ) : (
                <TimeLabel time={startDateTime} format={'HH:mm'} />
              )}
            </Typography>
            {!wholeDay && (
              <Typography
                textAlign="right"
                className="monospace opacity-50"
                fontSize={11}
                fontWeight={500}
              >
                <TimeLabel
                  time={endDateTime}
                  format={isMultiDayEvent ? 'DD-MM-YYYY HH:mm' : 'HH:mm'}
                />
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
