import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import ExoAvatar from '../../../ExoAvatar';
import { tokens } from '../../../../../global/theme/tokens';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DurationLabel from '../../../../label/DurationLabel';
import { TimeLabel } from '../../../../label/TimeLabel';
import DateLabel from '../../../../label/DateLabel';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { Link, NavLink } from 'react-router-dom';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import HelpIcon from '@mui/icons-material/Help';
import { API_PATCH, DBRequest, useConfig } from '../../../../../api/api';
import { useEffect, useState } from 'react';
import CalendarSelect from '../../types/CalendarSelect';
import Loader from '../../../../special/Loader';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

export const InviteMeeting = ({ invite, onChange, calendars }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const config = useConfig();

  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // calendar
  useEffect(() => {
    if (calendars) {
      var newFirstCal = null;
      calendars.forEach((cal) => {
        if (!newFirstCal && cal.id)
          newFirstCal = { name: cal.name, id: cal.id, color: cal.color };
      });
      setSelectedCalendar(newFirstCal);
    }
  }, [calendars]);

  const { sender, event, status } = invite;
  const senderName = `${sender.firstName} ${sender.lastName}`;

  const {
    label,
    startDateTime,
    endDateTime,
    wholeDay,
    onlineMeeting,
    location,
    project,
  } = event;
  const daysDiff = moment(event.endDateTime).diff(
    moment(event.startDateTime),
    'days'
  );

  const replaceSpaceWithPlus = (str) => str.replace(/ /g, '+');

  function handleStatusChange(newStatus) {
    DBRequest({
      config,
      path: `invites/${invite.id}/meeting`,
      method: API_PATCH({ status: newStatus, calendar: selectedCalendar }),
      onResponse: onChange,
      onLoading: setIsLoading,
    });
  }

  const statusMap = {
    0: {
      label: t('no response'),
      border: `1px solid ${colors.selected}`,
      bgcolor: colors.selected + '30',
      icon: <PanoramaFishEyeIcon />,
    },
    1: {
      label: t('Accepted'),
      border: `1px solid ${colors.success}`,
      bgcolor: colors.success + '30',
      icon: <ThumbUpIcon />,
    },
    2: {
      label: t('Declined'),
      border: `1px solid ${colors.error}`,
      bgcolor: colors.error + '30',
      icon: <ThumbDownIcon />,
    },
    3: {
      label: t('Maybe'),
      border: `1px solid ${colors.warning}`,
      bgcolor: colors.warning + '30',
      icon: <HelpIcon />,
    },
  };

  return (
    <Box
      className="px-4 py-1 rounded-md"
      sx={{
        border: statusMap[status]?.border,
        bgcolor: colors.card,
      }}
    >
      <Loader active={isLoading} />
      <Box className=" pb-2 flex justify-between">
        <Typography fontSize={20} fontWeight={500}>
          {label}
        </Typography>
        <Box
          className="px-3 py-1 rounded-full flex"
          sx={{
            bgcolor: statusMap[status]?.bgcolor,
            border: statusMap[status]?.border,
          }}
        >
          {statusMap[status]?.icon}
          <Typography className="pl-2"> {statusMap[status]?.label}</Typography>
        </Box>
      </Box>
      <CalendarSelect
        calendars={calendars}
        selectedCal={selectedCalendar}
        onSelect={setSelectedCalendar}
      />
      <Box className="flex gap-1 pt-2 pb-2 flex-wrap">
        <Typography className="pr-4" fontWeight="500">
          <CalendarMonthIcon className="mr-2" />
          <DateLabel date={startDateTime} />
          {daysDiff > 0 && (
            <>
              {' - '}
              <DateLabel date={endDateTime} />
            </>
          )}
        </Typography>
        {!wholeDay && (
          <Typography className="pr-4" fontWeight="500">
            <AccessTimeIcon className="mr-2" />
            <TimeLabel time={startDateTime} format="HH:mm" />
            {` ${t("o'clock")}`}
          </Typography>
        )}
        {!wholeDay && (
          <Typography className="pr-4" fontWeight="500">
            <AvTimerIcon className="mr-2" />
            <DurationLabel
              startDateTime={startDateTime}
              endDateTime={endDateTime}
            />
          </Typography>
        )}
      </Box>
      {project && (
        <Box>
          <Typography fontWeight={600}>{t('Project')}</Typography>
          <NavLink to={`/projects/management/${project.id}`}>
            <Button className="flex items-center gap-3">
              <img
                className="w-[40px] h-[40px] object-contain rounded-md"
                src={
                  project.image
                    ? project.image.temporaryUrl
                    : project.client?.logo?.temporaryUrl
                }
              />
              <Typography>{project.name}</Typography>
            </Button>
          </NavLink>
        </Box>
      )}
      {location && (
        <Box className="pb-2  py-2">
          <Typography fontWeight={600}>{t('Location')}</Typography>
          <Box className="flex items-center gap-1">
            <Link
              href={`https://www.google.de/maps/place/${location.street}+${location.houseNumber},+${location.zipCode}+${location.city}`}
              target="_blank"
            >
              <IconButton
                className="w-full"
                size="small"
                disableElevation
                sx={{
                  bgcolor: colors.info + '30',
                  border: `1px solid ${colors.info}80`,
                  ':hover': {
                    bgcolor: colors.info + '60',
                    border: `1px solid ${colors.info}80`,
                  },
                }}
              >
                <GoogleIcon className="px-[1px]" />
              </IconButton>
            </Link>
            <Link
              href={`http://maps.apple.com/?address=${replaceSpaceWithPlus(
                location.houseNumber
              )},${replaceSpaceWithPlus(
                location.street
              )},${replaceSpaceWithPlus(location.city)},${location.zipCode}`}
              target="_blank"
            >
              <IconButton
                className="w-full"
                size="small"
                disableElevation
                sx={{
                  bgcolor: colors.info + '30',
                  border: `1px solid ${colors.info}80`,
                  ':hover': {
                    bgcolor: colors.info + '60',
                    border: `1px solid ${colors.info}80`,
                  },
                }}
              >
                <AppleIcon className="pb-[2px] pr-[1px]" />
              </IconButton>
            </Link>
            <Typography className="pb-1 pl-1">{`${location.street} ${location.houseNumber} - ${location.zipCode} ${location.city}`}</Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          height: '1px',
          bgcolor: colors.text + '30',
        }}
        className="rounded-full"
      />
      <Box className="flex gap-4 pb-3 pt-3">
        {status !== 1 && (
          <Button
            variant="contained"
            color="success"
            disableElevation
            size="small"
            onClick={() => handleStatusChange(1)}
          >
            <ThumbUpIcon sx={{ fontSize: 14 }} className="mr-[5px]" />
            {t('Accept')}
          </Button>
        )}
        {status === 0 && (
          <Button
            variant="contained"
            color="info"
            disableElevation
            size="small"
            onClick={() => handleStatusChange(3)}
          >
            <HelpIcon sx={{ fontSize: 14 }} className="mr-[5px]" />
            {t('Maybe')}
          </Button>
        )}
        {status !== 2 && (
          <Button
            variant="contained"
            color="error"
            disableElevation
            size="small"
            onClick={() => handleStatusChange(2)}
          >
            <ThumbDownIcon sx={{ fontSize: 14 }} className="mr-[5px]" />
            {t('Decline')}
          </Button>
        )}
      </Box>
      <Box className="flex items-center gap-2 w-full justify-end">
        <Typography className="opacity-50" fontSize={11}>
          {t('invited by')}
        </Typography>
        <ExoAvatar
          size="20px"
          name={senderName}
          picture={sender.picture?.temporaryUrl}
        />
        <Typography className="opacity-50" fontSize={12}>
          {senderName}
        </Typography>
      </Box>
    </Box>
  );
};
