import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { WPEContainer } from "../WPEContainer";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { WPEToDoElement } from "../../../../../components/exo/project/work-package-elements/WPEToDoElement";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { updateOrCreate } from "../../../../../components/special/updateOrCreate";
import { useTranslation } from "react-i18next";
import NewWPEToDoElement from "../../../../../components/exo/project/work-package-elements/NewWPEToDoElement";
import { tokens } from "../../../../../global/theme/tokens";
import DeleteIcon from "@mui/icons-material/Delete";
import { Droppable } from "react-beautiful-dnd";
import {
  API_DELETE,
  API_GET,
  DBRequest,
  useConfig,
} from "../../../../../api/api";

export const WPETypeToDoList = forwardRef(
  (
    {
      data,
      element,
      onChange,
      onDelete,
      isDragging,
      draggingStyle,
      pid,
      disableDragging = false,
      isInDashboard = false,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const config = useConfig();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

    const [apiData, setApiData] = useState(null);

    const [deleteMode, setDeleteMode] = useState(false);

    useEffect(() => {
      if (data) setApiData({ data: data });
    }, [data]);

    useEffect(() => {
      if (data) return;
      DBRequest({
        config,
        path: `work-packages/elements/list/${element.id}`,
        method: API_GET,
        onResponse: handleResponse,
      });
    }, [config]);

    function handleResponse(res) {
      setApiData(res);
    }

    useImperativeHandle(ref, () => ({
      apiData: apiData,
      setApiData: setApiData,
    }));

    const [isCheckedVisible, setisCheckedVisible] = useState(false);
    const [hoverText, setHoverText] = useState("Hide completed tasks");

    const [completedCount, setCompletedCount] = useState(0);
    const [openNewToDo, setOpenNewToDo] = useState(false);

    function updateCompletedCount(tasks) {
      tasks = tasks.filter((element) => element.status != null);
      setCompletedCount(
        tasks.reduce((sum, element) => sum + element.status, 0)
      );
    }

    useEffect(() => {
      if (apiData) updateCompletedCount(apiData.data.tasks);
    }, [apiData]);

    function handleToggleVisibility() {
      setisCheckedVisible(!isCheckedVisible);
      if (isCheckedVisible) {
        setHoverText("Show completed tasks");
      } else setHoverText("Hide completed tasks");
    }

    function handleAddToDo() {
      setOpenNewToDo(true);
    }

    function handleNewToDo(newTask) {
      const updatedTasks = apiData.data.tasks.map((el) => ({
        ...el,
        sortIndex: el.sortIndex + 1,
      }));

      setApiData({
        ...apiData,
        data: { ...apiData.data, tasks: [newTask.data, ...updatedTasks] },
      });

      setOpenNewToDo(false);
    }

    function handleChange(updatedTask) {
      const updatedTasks = updateOrCreate(apiData.data.tasks, updatedTask);
      const dbTasks = updatedTasks.filter((obj) => obj.id);

      setApiData({ ...apiData, data: { ...apiData.data, tasks: dbTasks } });
    }

    function handleDelete(element) {
      const dbTasks = apiData.data.tasks.filter((obj) => obj.id !== element.id);

      DBRequest({
        config,
        path: `work-packages/elements/list/tasks/${element.id}`,
        method: API_DELETE,
        onResponse: () => onDelete(element),
      });
      setApiData({ ...apiData, data: { ...apiData.data, tasks: dbTasks } });
    }

    return (
      <Box className="relative">
        <Box
          className="top-1 right-1 z-10 flex gap-1 items-center"
          sx={{ position: "absolute" }}
        >
          {!isInDashboard && (
            <IconButton onClick={() => setDeleteMode(!deleteMode)}>
              <DeleteIcon
                sx={{
                  color: deleteMode ? colors.error : null,
                }}
              />
            </IconButton>
          )}

          {/*
          <Droppable
            isDropDisabled={disableDragging}
            droppableId={`trash ${element.id}`}
            key={`trash ${element.id}`}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {(isDragging || true) && (
                  <Box
                    sx={{
                      color: isDragging
                        ? snapshot.isDraggingOver
                          ? colors.error
                          : null
                        : 'transparent',
                    }}
                  >
                    <DeleteIcon />
                  </Box>
                )}
              </div>
            )}
          </Droppable> */}

          {!isInDashboard && (
            <IconButton onClick={handleAddToDo}>
              <AddCircleIcon />
            </IconButton>
          )}
        </Box>
        <WPEContainer
          title={element.name}
          className="h-full w-full flex flex-col gap-2"
          sx={{ minHeight: "100px" }}
          isInDashboard={isInDashboard}
        >
          {isInDashboard && apiData ? (
            <Typography fontSize={14} fontWeight={500} className="h-[14px]">
              {apiData.data.name}
            </Typography>
          ) : (
            <Box className="flex items-center">
              <Tooltip title={t(hoverText)}>
                <Button onClick={handleToggleVisibility}>
                  <Typography className="flex items-center gap-1" fontSize={12}>
                    {isCheckedVisible ? (
                      <VisibilityOffIcon fontSize="small" />
                    ) : (
                      <VisibilityIcon fontSize="small" />
                    )}
                    {` ${completedCount} ${t("completed Tasks")}`}
                  </Typography>
                </Button>
              </Tooltip>
            </Box>
          )}
          {!isInDashboard && openNewToDo && (
            <NewWPEToDoElement
              onSubmit={handleNewToDo}
              onCancle={() => setOpenNewToDo(false)}
              wpeId={element.id}
              deleteMode={deleteMode}
            />
          )}

          {
            <Droppable
              isDropDisabled={disableDragging}
              droppableId={`tasks ${element.id}`}
              key={`tasks ${element.id}`}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    position: snapshot.isDraggingOver ? "relative" : "static", // Avoid shifting layout on drag over
                    transition: "height 0.2s ease-in-out", // Smooth transition for height
                    minHeight: "50px",
                    overflow: "visible",
                    WebkitOverflowScrolling: "touch",
                  }}
                >
                  {apiData?.data.tasks.map((task, index) => (
                    <WPEToDoElement
                      disableDragging={disableDragging}
                      key={`${task.id}`}
                      draggingStyle={draggingStyle}
                      element={task}
                      onChange={handleChange}
                      onDelete={handleDelete}
                      className={
                        !isCheckedVisible ? (!task.status ? "" : "hidden") : ""
                      }
                      index={index}
                      wpeId={element.id}
                      pid={pid}
                      isDragging={isDragging}
                      deleteMode={deleteMode}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          }
        </WPEContainer>
      </Box>
    );
  }
);
