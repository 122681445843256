import * as React from 'react';
import PropTypes from 'prop-types';
import { Global, useTheme } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { tokens } from '../../global/theme/tokens';

const drawerBleeding = 56;
function ExoDrawer(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { window, children, open, onClose } = props;

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(90%)`,
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={onClose}
        swipeAreaWidth={drawerBleeding}
        sx={{
          '& .MuiPaper-root': {
            bgcolor: 'transparent',
          },
        }}
      >
        <Box className="w-full absolute top-2 flex justify-center">
          <Box
            className="h-[6px] w-[85px] rounded-full z-50 relative"
            sx={{ bgcolor: colors.selected, zIndex: 10 }}
          />
        </Box>
        <Box
          sx={{
            px: 2,
            pb: 2,
            pt: 2,
            height: '100%',
            overflow: 'auto',
            bgcolor: colors.glass,
          }}
          className="backdrop-blur-lg"
        >
          {children}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

ExoDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ExoDrawer;
