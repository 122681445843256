import { Box, Button, Dialog } from "@mui/material";
import BankAccountsSlider from "./BankAccounsSlider";
import { useEffect, useRef, useState } from "react";
import Transactions from "./Transactions";
import Header from "../../components/special/Header";
import NewBankAccount from "../../components/new/NewBankAccount";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import AddCardIcon from "@mui/icons-material/AddCard";
import { useTranslation } from "react-i18next";
import ExoDialog from "../../components/exo/ExoDialog";
import useFetch from "../../api/useFetch";
import ExoBankAccounts from "../../components/exo/ExoBankAccounts";
import ExoAlert from "../../components/exo/ExoAlert";
import { updateOrCreate } from "../../components/special/updateOrCreate";
import BankAccountSelector from "./BankAccountSelector";

const BankAccounts = () => {
  const { t } = useTranslation();
  const scrollContainerRef = useRef(null);
  const config = useConfig();
  const { apiData, setApiData } = useFetch("bankaccounts");

  const handleBankUpdate = (bank) => {
    setActiveBankAccount(bank);
  };

  const [activeBankAccount, setActiveBankAccount] = useState(null);

  // first Account
  function handleCreatedAccount(newBankAccount) {
    if (Object.keys(newBankAccount).length == 0) return;
    setApiData({
      ...apiData,
      data: [...apiData.data, newBankAccount],
    });
  }
  const [open, setOpen] = useState(false);

  return (
    <Box className="justify-start">
      <Header title={t("Bank Accounts")} />
      <BankAccountSelector
        handleCreatedAccount={handleCreatedAccount}
        handleBankUpdate={handleBankUpdate}
        accounts={apiData?.data}
      />
      <ExoDialog open={open} limitWidth>
        <NewBankAccount
          onClose={() => setOpen(false)}
          onCreate={handleCreatedAccount}
        />
      </ExoDialog>
    </Box>
  );
};

export default BankAccounts;
