import { Box, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import Header from '../../components/special/Header';
import { useTranslation } from 'react-i18next';
import { ProjectsGrid } from './ProjectsGrid';
import { useState } from 'react';

const Projects = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const [projectStatus, setProjectStatus] = useState('own');

  return (
    <>
      <Box className="flex gap-0 md:gap-8 md:items-center justify-between md:justify-start pr-2 md:flex-row flex-col pb-4 md:pb-0">
        <Header title={t('Projects')} />
        <ToggleButtonGroup
          size="small"
          value={projectStatus}
          exclusive
          onChange={(e) => setProjectStatus(e.target.value)}
          aria-label="Project Status"
          className="backdrop-blur-lg"
        >
          <ToggleButton value="own">{t('Own')}</ToggleButton>
          <ToggleButton value="active">{t('Active')}</ToggleButton>
          <ToggleButton value="archive">{t('Archive')}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <ProjectsGrid projectStatus={projectStatus} />
    </>
  );
};

export default Projects;
