const findDifferences = (oldObj, newObj, useExeptions = true) => {
  if (!oldObj) return newObj;
  const differences = {};
  if (Object.keys(oldObj).length === 0) return newObj;
  for (let key in newObj) {
    // always add these keys

    const keepKeys = [
      'id',
      'delivery',
      'positions',
      'color',
      'attachments',
      'telephoneNumbers',
      'participants',
      'templateId',
      'location',
    ];

    if (useExeptions && keepKeys.includes(key)) differences[key] = newObj[key];
    // check if value exists and is differernt
    if (oldObj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
      if (oldObj[key] !== newObj[key]) {
        differences[key] = newObj[key];
      }
    }
    if (!oldObj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
      differences[key] = newObj[key];
    }
  }

  return differences;
};

export default findDifferences;
