import { useState, useEffect } from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  useTheme,
  Typography,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { API_GET, DBRequest, useConfig } from '../../../api/api';
import ExoAvatar from '../../exo/ExoAvatar';
import TypeSkeleton from './TypeSkeleton';
import FormHelperText from '../FormHelperText';
import { useTranslation } from 'react-i18next';
import LabeledBox from '../../special/LabeledBox';
import ExoDialog from '../../exo/ExoDialog';
import { ProjectGridItem } from '../../../scenes/projects/project-grid/ProjectGridItem';
import { tokens } from '../../../global/theme/tokens';
import CardGrid from '../../cards/layout/CardGrid';
import Pagination from '../../special/Pagination';

const TypeProject = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  size,
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const [projects, setProjects] = useState(null);
  const [open, setOpen] = useState(false);
  const [projectStatus, setProjectStatus] = useState('active');

  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: field.apiUrl
        ? `${field.apiUrl}?${typeMap[projectStatus]}`
        : 'projects/own',
      method: API_GET,
      onResponse: setProjects,
    });
  }, [config, projectStatus]);
  function handleChangeSelect(project) {
    setOpen(false);
    updateValidationOnChange(field.key, project);
  }

  const typeMap = {
    active: 'status[eq]=0',
    archive: 'status[ne]=0',
  };

  const selectedProject = dataCollection[field.key];
  const projectPicture =
    dataCollection[field.key] && dataCollection[field.key]?.image?.temporaryUrl;

  return (
    <FormControl fullWidth size={size}>
      {!projects ? (
        <TypeSkeleton />
      ) : (
        <>
          <LabeledBox
            label={field.label}
            onClick={() => setOpen(true)}
            size={size}
          >
            {selectedProject && (
              <Box className="flex items-center gap-2 w-full">
                <ExoAvatar
                  size="26px"
                  name={
                    selectedProject.clientType === 'company'
                      ? selectedProject.client?.name
                      : `${selectedProject.client?.firstName} ${selectedProject.client?.lastName}`
                  }
                  picture={
                    projectPicture ||
                    (selectedProject.client?.logo &&
                      selectedProject.client?.logo.temporaryUrl)
                  }
                  type={selectedProject.clientType}
                />
                <Typography>{selectedProject.name}</Typography>
              </Box>
            )}
          </LabeledBox>
          <FormHelperText
            error={Boolean(errors[field.key])}
            helperText={errors[field.key]}
          />
          <ExoDialog open={open} onClose={() => setOpen(false)}>
            <Box className="h-full flex flex-col gap-2 overflow-hidden justify-between">
              <Box className="flex flex-col gap-2 h-full overflow-auto">
                <ToggleButtonGroup
                  value={projectStatus}
                  exclusive
                  onChange={(e) => setProjectStatus(e.target.value)}
                  aria-label="Project Status"
                >
                  <ToggleButton value="active">{t('Active')}</ToggleButton>
                  <ToggleButton value="archive">{t('Archive')}</ToggleButton>
                </ToggleButtonGroup>
                <CardGrid className="overflow-y-auto px-2 py-1">
                  {projects.data.map((item) => (
                    <ProjectItem
                      key={item.id}
                      project={item}
                      onSelect={handleChangeSelect}
                    />
                  ))}
                </CardGrid>
              </Box>

              <Pagination
                data={projects}
                setData={setProjects}
                filter={{ name: 'name', filter: typeMap[projectStatus] }}
                setLoading={() => {}}
              />
            </Box>
          </ExoDialog>
        </>
      )}
    </FormControl>
  );
};

const ProjectItem = ({ project, onSelect }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const projectPicture = project.image?.temporaryUrl;

  return (
    <Button
      onClick={() => onSelect(project)}
      variant="contained"
      color="secondary"
      sx={{
        transition: 'scale 0.3s ease',
        ':hover': {
          scale: '1.02',
        },
      }}
      className=" cursor-pointer  flex flex-col backdrop-blur-lg items-center p-4 rounded-lg"
    >
      <ExoAvatar
        picture={
          projectPicture ||
          (project.client.logo && project.client.logo.temporaryUrl)
        }
        type={project.clientType}
        name={
          project.clientType === 'company'
            ? project.client.nameShort
            : `${project.client.lastName} ${project.client.firstName}`
        }
        size="90px"
      />
      <Typography variant="h3" className="pt-4">
        <b>{project.nr}</b>
      </Typography>
      <Typography variant="h6">{project.name}</Typography>
    </Button>
  );
};

export default TypeProject;
