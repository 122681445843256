import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ContactsIcon from "@mui/icons-material/Contacts";
import MailIcon from "@mui/icons-material/Mail";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CloudIcon from "@mui/icons-material/Cloud";
import SelectContact from "./SelectContact";
import { useTranslation } from "react-i18next";
import { tokens } from "../../global/theme/tokens";
import ExoForm from "../exo/ExoForm";
import { SettingsPopover } from "../exo/project/SettingsPopover";
import TypeDivider from "../form/form-fields/TypeDivider";
import ExoDialog from "../exo/ExoDialog";
import { SelectFromNameValueArray } from "./SelectFromNameValueArray";
import Feedback from "../special/Feedback";
import uuid from "react-uuid";

const SelectDelivery = ({
  onSelect,
  recipient,
  useInvoiceMail,
  defaultType = "recipientMail",
  options = ["recipientMail", "contactMail", "customMail", "saveOnly"],
  allowCC,
  allowMailContent,
  defaultRecipientMailKey,
  deliveryObj,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const [type, setType] = useState("");

  const [nameValueData, setNameValueData] = useState({});
  const [deliveryObject, setDeliveryObject] = useState(deliveryObj || {});

  useEffect(() => {
    setDeliveryObject(deliveryObj || {});
  }, [deliveryObj]);

  useEffect(() => {
    if (type === "")
      setType(deliveryObj ? deliveryObj.defaultType : defaultType);
  }, [deliveryObj]);

  function handleDelivertype(event, newType) {
    if (newType) setType(newType);
    if (newType === "contactMail") handleContactMail({});
    if (newType === "customMail") handleCustomMail({});
    if (newType === "recipientMail") {
      handleRecipientMail();
    }
    if (newType === "saveOnly") {
      handleSaveOnly();
    }
  }

  const optionMap = {
    recipientMail: {
      icon: <ContactMailIcon />,
      label: t("Default Mail"),
      value: "recipientMail",
    },
    contactMail: {
      icon: <ContactsIcon />,
      label: t("Contact Mail"),
      value: "contactMail",
    },
    customMail: {
      icon: <AlternateEmailIcon />,
      label: t("Custom Mail"),
      value: "customMail",
    },
    saveOnly: {
      icon: <CloudIcon />,
      label: t("Save Only"),
      value: "saveOnly",
    },
  };

  // handle callback
  function handleRecipientMail(deliveryMail) {
    onSelect({
      ...deliveryObject,
      saveOnly: false,
      id: null,
      deliveryMail,
      defaultType: "recipientMail",
      deliveryName:
        recipient &&
        (recipient.name
          ? recipient.name
          : `${recipient.salutation} ${recipient.firstName} ${recipient.lastName}`),
    });
  }
  function handleCustomMail(newCustom) {
    onSelect({
      ...deliveryObject,
      id: null,
      saveOnly: false,
      defaultType: "customMail",
      deliveryMail: newCustom.email,
      deliveryName: newCustom.name,
    });
  }
  function handleContactMail(contact) {
    if (contact && contact.emails && contact.emails.length === 0) {
      onSelect({ saveOnly: false, defaultType: "contactMail" });
      if (!deliveryObj)
        setDeliveryObject({ saveOnly: false, defaultType: "contactMail" });
      return handleFeedback(t("No E-Mail address found"), "error");
    }
    if (contact.id)
      setNameValueData({
        array: contact.emails,
        nameKey: "emailType",
        valueKey: "emailAddress",
      });

    onSelect({
      ...deliveryObject,
      id: contact.id,
      saveOnly: false,
      defaultType: "contactMail",
      deliveryMail: contact.mail,
      deliveryName: `${contact.salutation} ${contact.firstName} ${contact.lastName}`,
    });
  }
  function handleSaveOnly() {
    onSelect({
      ...deliveryObject,
      saveOnly: true,
      defaultType: "saveOnly",
    });
  }
  function handleCCChange(data) {
    if (!deliveryObject) return;
    onSelect({ ...deliveryObject, cc: data });
  }

  function handleContentChange(data) {
    if (!deliveryObject) return;
    onSelect({ ...deliveryObject, mailContent: data });
  }

  function handleEmailSelect(email) {
    onSelect({ ...deliveryObject, deliveryMail: email });
    setNameValueData({});
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <Box className="flex flex-col gap-2 h-full overflow-auto">
      <ToggleButtonGroup
        value={type}
        exclusive
        onChange={handleDelivertype}
        className="flex flex-col md:flex-row  gap-1 mt-4"
      >
        {options.map((option) => (
          <ToggleButton
            key={option}
            value={optionMap[option].value}
            sx={{
              border: "none",
              borderRadius: "5px!important",
              bgcolor: colors.bgInput,
            }}
          >
            <Box className="flex flex-row gap-2">
              {optionMap[option].icon}
              {optionMap[option].label}
            </Box>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {allowCC && type !== "saveOnly" && (
        <MailCC
          onCCChange={handleCCChange}
          preSelected={deliveryObject || null}
        />
      )}
      {allowMailContent && type !== "saveOnly" && (
        <MailContent
          onContentChange={handleContentChange}
          preSelected={deliveryObject || null}
        />
      )}
      <Box className="flex flex-col h-full w-full">
        {type === "recipientMail" && (
          <RecipientMail
            onChange={handleRecipientMail}
            recipient={recipient}
            defaultKey={defaultRecipientMailKey}
            preSelected={deliveryObject}
          />
        )}
        {type === "contactMail" && (
          <Box>
            <SelectContact
              onSelect={handleContactMail}
              name="deliveryContact"
              preSelected={deliveryObject}
            />
          </Box>
        )}
        {type === "customMail" && (
          <CustomMail
            onChange={handleCustomMail}
            preSelected={deliveryObject}
          />
        )}
        {type === "saveOnly" && <SaveOnly />}
      </Box>
      <ExoDialog open={Boolean(nameValueData.array)} limitWidth>
        <SelectFromNameValueArray
          label="Select email"
          array={nameValueData.array}
          nameKey={nameValueData.nameKey}
          valueKey={nameValueData.valueKey}
          onSelect={handleEmailSelect}
        />
      </ExoDialog>
      <Feedback state={alertState} setState={setAlertState} />
    </Box>
  );
};

const MailCC = ({ onCCChange, preSelected }) => {
  const { t } = useTranslation();

  const [startData, setStartData] = useState(preSelected);
  const fields = [
    {
      label: t("CC Mails"),
      key: "cc",
      type: "mailSelect",
    },
  ];

  function handleCCChange(data) {
    onCCChange(data.cc);
  }

  return (
    <ExoForm
      outerClassName="flex flex-col gap-4 w-full"
      sx={{ marginTop: "10px" }}
      fields={fields}
      noControls
      onChange={handleCCChange}
      startDataCollection={startData}
    />
  );
};

const MailContent = ({ onContentChange, preSelected }) => {
  const { t } = useTranslation();

  const [startData, setStartData] = useState(preSelected);
  const fields = [
    {
      label: `${t("Additional Mail Content")} (${t("optional")})`,
      key: "mailContent",
      type: "richText",
    },
  ];

  function handleContentChange(data) {
    onContentChange(data.mailContent);
  }

  return (
    <ExoForm
      outerClassName="flex flex-col gap-4 w-full"
      sx={{ marginTop: "10px" }}
      fields={fields}
      noControls
      onChange={handleContentChange}
      startDataCollection={startData}
    />
  );
};

const RecipientMail = ({ recipient, defaultKey, onChange, preSelected }) => {
  const { t } = useTranslation();

  const [deliveryMail, setDeliveryMail] = useState(null);

  const [mailSettings, setMailSettings] = useState(null);

  function handleChange(value) {
    setDeliveryMail(value);
    onChange(value);
  }

  useEffect(() => {
    if (!recipient || !recipient.emails || !recipient.emails.length)
      return onChange(null);

    const newSettings = [];

    recipient.emails.forEach((element) => {
      newSettings.push({
        onClick: () => handleChange(element.emailAddress),
        label: `${element.emailType} <${element.emailAddress}>`,
      });
    });

    // start data
    var defaultMail = recipient.emails[0].emailAddress;
    recipient.emails.forEach((element) => {
      if (preSelected && preSelected.deliveryMail) {
        defaultMail = preSelected.deliveryMail;
      } else {
        if (element[defaultKey || "isPrimary"])
          defaultMail = element.emailAddress;
      }
    });

    setDeliveryMail(defaultMail);
    onChange(defaultMail);
    setMailSettings(newSettings);
  }, [recipient]);

  if (!recipient || !recipient.emails || !recipient.emails.length)
    return (
      <Box className="mt-4 flex items-center justify-center flex-col gap-4">
        <MailIcon color="error" sx={{ fontSize: "80px" }} />
        <Typography variant="h5">{t("No E-Mail address found")}</Typography>
      </Box>
    );
  return (
    <Box>
      <Box className="mt-4 flex items-center justify-center flex-col gap-4">
        <MailIcon sx={{ fontSize: "80px" }} />
        <Box className="flex flex-col justify-center items-center gap-1">
          <Typography variant="h3" className="pb-4">
            {recipient.name
              ? recipient.name
              : recipient.firstName + " " + recipient.lastName}
          </Typography>

          <Box className="flex items-center gap-2">
            <Typography variant="h5">{deliveryMail}</Typography>
            {mailSettings && (
              <SettingsPopover label={t("E-Mails")} settings={mailSettings} />
            )}
          </Box>
        </Box>
        <Alert
          severity="info"
          className="w-full"
          sx={{ flexDirection: "row!important" }}
        >
          {t(
            "The document will be automatically sent by e-mail to the official e-mail address of the recipient."
          )}
        </Alert>
      </Box>
    </Box>
  );
};

const CustomMail = ({ onChange, preSelected }) => {
  const { t } = useTranslation();
  const [custom, setCustom] = useState({ name: "", email: "" });

  function handleCustomMailChange(data) {
    setCustom(data);
    onChange(data);
  }

  useEffect(() => {
    if (
      !custom.email &&
      !custom.name &&
      preSelected &&
      preSelected.deliveryMail
    ) {
      handleCustomMailChange({
        email: preSelected.deliveryMail,
        name: preSelected.deliveryName,
      });
    }
  }, []);

  return (
    <Box className="mt-4 flex items-center justify-center flex-col gap-4">
      <TextField
        className="w-full"
        label={t("Recipient Name")}
        variant="outlined"
        onChange={(e) => {
          handleCustomMailChange({ ...custom, name: e.target.value });
        }}
        value={custom.name}
      />
      <TextField
        className="w-full"
        label={t("Mail")}
        variant="outlined"
        onChange={(e) => {
          handleCustomMailChange({ ...custom, email: e.target.value });
        }}
        value={custom.email}
      />
      <Alert
        severity="info"
        className="w-full"
        sx={{ flexDirection: "row!important" }}
      >
        {t(
          "The document will be sent automatically by mail to the email address entered above."
        )}
      </Alert>
    </Box>
  );
};

const SaveOnly = () => {
  const { t } = useTranslation();
  return (
    <Box className="mt-4 flex items-center justify-center flex-col gap-4">
      <CloudIcon sx={{ fontSize: "80px" }} />
      <Box className="flex flex-col justify-center items-center gap-1">
        <Typography variant="h3">{t("Save to the cloud only")}</Typography>
      </Box>
      <Alert
        severity="warning"
        className="w-full"
        sx={{ flexDirection: "row!important" }}
      >
        {t(
          "The Document is exclusively created and stored in the cloud. An automatic delivery does not take place."
        )}
      </Alert>
    </Box>
  );
};

export default SelectDelivery;
