import { Box, Typography, useTheme } from '@mui/material';
import { event } from './interfaces';
import { tokens } from '../../../global/theme/tokens';
import AddIcon from '@mui/icons-material/Add';
import { TimeLabel } from '../../label/TimeLabel';
import moment from 'moment';
import { useEffect, useState } from 'react';

export const ExoCalEvent = ({
  event,
  isFirst,
  isLast,
  isFirstInWeek,
  date,
  onClick,
  isSelected,
  onDoubleClick,
}: {
  event: event;
  isFirst: Boolean;
  isLast: Boolean;
  isFirstInWeek: Boolean;
  date: string;
  onClick: Function;
  isSelected: Boolean;
  onDoubleClick: Function;
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const typeMap = {
    new: { color: colors.info, Icon: AddIcon },
  };
  const Icon = typeMap[event.type]?.Icon;

  const isWholeDay = event.wholeDay;

  const isMultiDayEvent = !moment(event.startDateTime).isSame(
    event.endDateTime,
    'day'
  );

  // mobile view
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      onDoubleClick={event.calendar?.id ? onDoubleClick : null}
      onClick={event.calendar?.id ? onClick : null}
      style={{
        paddingLeft: isFirst ? (isSmallScreen ? '1px' : '4px') : '0px',
        paddingRight: isLast ? (isSmallScreen ? '2px' : '4px') : '0px',
        cursor: event.calendar?.id ? 'pointer' : 'auto',
      }}
      data-date={date}
      className="flex justify-start items-center w-full cursor-pointer h-[45px] lg:h-[16px]"
    >
      <Box
        className="w-full flex justify-start items-center h-full px-[2px] rounded-sm "
        sx={{
          borderRadius: isWholeDay
            ? `${isFirst ? '10px' : '0px'} ${isLast ? '10px' : '0px'} ${
                isLast ? '10px' : '0px'
              } ${isFirst ? '10px' : '0px'}`
            : `${isFirst ? '2px' : '0px'} ${isLast ? '2px' : '0px'} ${
                isLast ? '2px' : '0px'
              } ${isFirst ? '2px' : '0px'}`,

          borderWidth: '1px',
          borderTopColor: isSelected ? colors.info + '80' : `transparent`,
          borderBottomColor: isSelected ? colors.info + '80' : `transparent`,
          borderLeftColor:
            isSelected && (isFirst || isFirstInWeek)
              ? colors.info + '80'
              : `transparent`,
          borderRightColor:
            isSelected && isLast ? colors.info + '80' : `transparent`,
          backgroundColor: isSelected
            ? colors.info + '40'
            : isWholeDay
            ? (event.color || typeMap[event.type]?.color) + 'AA'
            : (event.color || typeMap[event.type]?.color) + '40',
        }}
      >
        {!isWholeDay && isFirst && (
          <Box
            sx={{
              bgcolor: (event.color || typeMap[event.type]?.color) + 'AA',
            }}
            className="w-[3px] h-[80%] mr-1 rounded-sm hidden lg:block"
          ></Box>
        )}
        <Box
          className="flex flex-col lg:flex-row justify-between w-full items-center overflow-hidden"
          sx={{
            justifyContent: isFirst ? 'space-between' : 'end',
          }}
        >
          {(isFirstInWeek || isFirst) && (
            <Typography
              fontWeight={500}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                paddingLeft:
                  isWholeDay || (!isFirst && isFirstInWeek) ? '5px' : '0px',
              }}
              fontSize="11px"
              className="w-full text-left line-clamp-2 lg:line-clamp-1"
            >
              {Icon && <Icon fontSize="10px" sx={{ marginLeft: '3px' }} />}
              <span>{event.label}</span>
            </Typography>
          )}
          {!isWholeDay && isFirst && (
            <Typography
              fontSize={10}
              fontWeight={500}
              textAlign="start"
              sx={{
                opacity: 0.6,
              }}
            >
              <TimeLabel time={event.startDateTime} format="HH:mm" />
            </Typography>
          )}
          {!isWholeDay && isLast && !isFirst && (
            <Typography
              fontSize={10}
              fontWeight={500}
              textAlign={isLast ? 'end' : 'start'}
              sx={{
                opacity: 0.6,
              }}
            >
              <TimeLabel time={event.endDateTime} format="HH:mm" />
            </Typography>
          )}
        </Box>
      </Box>
    </div>
  );
};
