import React, { useState } from "react";
import ExoForm from "../../ExoForm";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../../../api/api";

export const NewCalendar = ({
  onClose = () => {},
  onCreate = () => {},
  open,
  startData,
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const [isLoading, setIsLoading] = useState(false);

  const fields = [
    {
      label: t("Color"),
      key: "color",
      type: "color",
    },
    {
      label: t("Name"),
      key: "name",
      type: "text",
    },
    {
      label: t("Every employee should have this calendar"),
      key: "isPublic",
      type: "checkbox",
    },
    {
      label: t("Sync to Google Account"),
      key: "shouldSync",
      type: "checkbox",
    },
  ];
  const validationSchema = Yup.object().shape({
    color: Yup.string().required("Is required"),
    name: Yup.string().required("Is required"),
  });

  function handleSubmit(data) {
    const isUpdate = data.id ? true : false;

    data.isPublic = data.isPublic ?? false;
    data.shouldSync = data.shouldSync ?? false;

    DBRequest({
      config,
      path: isUpdate ? `calendars/${data.id}` : "calendars",
      method: isUpdate ? API_PATCH(data) : API_POST(data),
      onResponse: (res) => onCreate(res.data),
      onLoading: setIsLoading,
    });
  }

  return (
    <ExoForm
      isLoading={isLoading}
      fields={fields}
      onSubmit={handleSubmit}
      onCancle={onClose}
      startDataCollection={startData}
      header={startData?.id ? t("Edit Calendar") : t("New Calendar")}
      validationSchema={validationSchema}
    />
  );
};
