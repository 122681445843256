import React, { useEffect, useState } from 'react';
import ExoForm from '../../ExoForm';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { API_POST, DBRequest, useConfig } from '../../../../api/api';

export const ShareCalendar = ({ calendar, onChange, onClose = () => {} }) => {
  const { t } = useTranslation();
  const config = useConfig();

  const [isLoading, setIsLoading] = useState(false);
  const [startDataCollection, setStartDataCollection] = useState(null);

  useEffect(() => {
    if (!calendar.invitations) return;

    const participants = calendar?.invitations.map((invite) => {
      return {
        id: invite.participant.id,
        participant: invite.participant,
        type: 'user',
        writePermissions: invite.writePermissions,
      };
    });

    setStartDataCollection({ users: participants });
  }, []);

  const fields = [
    {
      label: t('Recipient'),
      key: 'users',
      type: 'usersCheckbox',
      checkbox: {
        label: t('Write Permissions'),
        key: 'writePermissions',
        default: false,
      },
    },
  ];
  const validationSchema = Yup.object().shape({
    users: Yup.array().required('Users are required'),
  });

  function handleSubmit(data) {
    if (Object.keys(data).length == 0) {
      onClose();
      return;
    }

    DBRequest({
      config,
      path: `calendars/${calendar.id}/share`,
      method: API_POST(data),
      onResponse: handleResponse,
      onLoading: setIsLoading,
    });
  }

  function handleResponse(res) {
    const newCal = { ...calendar, invitations: res.data };
    onChange(newCal);
    onClose();
  }

  return (
    <ExoForm
      isLoading={isLoading}
      fields={fields}
      onSubmit={handleSubmit}
      onCancle={onClose}
      header={t('Share Calendar')}
      validationSchema={validationSchema}
      startDataCollection={startDataCollection}
    />
  );
};
