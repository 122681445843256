import React, { useEffect, useState } from 'react';
import { ExoPDFViewer } from './ExoPDFViewer';
import ExoDialog from './ExoDialog';
import Loader from '../special/Loader';

export const ExoFilePreview = ({ open, src, onClose, mimeType }) => {
  useEffect(() => {
    checkImageValidity();
  }, [src, open]);

  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const checkImageValidity = () => {
    if (!open) return;
    const img = new Image();

    img.onload = () => {
      setIsValidUrl(true);
      setIsChecking(false);
    };

    img.onerror = () => {
      setIsValidUrl(false);
      setIsChecking(false);
    };

    img.src = src;
  };

  function handleClose() {
    src = null;
    setIsValidUrl(false);
    setIsChecking(true);
    onClose();
  }

  return (
    <>
      {open &&
        (mimeType === 'application/pdf' ? (
          <ExoPDFViewer open={open} onClose={handleClose} src={src} />
        ) : (
          <ExoDialog open={open} onClose={handleClose}>
            {isChecking && <Loader />}
            {mimeType.split('/')[0] == 'video' ||
            mimeType == 'application/octet-stream' ? (
              <video
                autoplay={'autoplay'}
                src={src}
                alt="preview"
                className={`w-full h-full object-contain ${
                  isValidUrl && isChecking ? 'hidden' : 'block'
                }`}
              />
            ) : (
              (isChecking && <Loader />,
              (
                <img
                  src={src}
                  alt="preview"
                  className={`w-full h-full object-contain ${
                    isValidUrl && isChecking ? 'hidden' : 'block'
                  }`}
                />
              ))
            )}
          </ExoDialog>
        ))}
    </>
  );
};
