import Backdrop from '@mui/material/Backdrop';
import { Typography, Box, useTheme } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../global/theme/tokens';

const Loader = ({ type = 'modern', active = true, message, color, noBlur }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  var loadingAnimation = '';
  switch (type) {
    default:
      loadingAnimation = (
        <CircularProgress
          size="3rem"
          style={{ color: colors.blueAccent[500] }}
        />
      );
      break;
    case 'modern':
      loadingAnimation = <span className="loader"></span>;
  }

  return (
    <Backdrop
      sx={{
        backgroundColor: colors.glass,
        backdropFilter: noBlur ? 'unset' : 'blur(10px)',
        flexDirection: 'column',
        gap: '15px',
        zIndex: '999',
        '& :after, & :before': {
          color: color ? color : colors.primary[700],
        },
      }}
      open={active}
    >
      {loadingAnimation}
      <Typography
        variant="h5"
        sx={{
          color: color ? color : colors.primary[700],
        }}
      >
        {message || t('Loading...')}
      </Typography>
    </Backdrop>
  );
};

export default Loader;
