import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import CardGrid from "../../../components/cards/layout/CardGrid";
import { API_DELETE, DBRequest, useConfig } from "../../../api/api";
import SpeedDialMenu from "../../../components/menu/SpeedDialMenu";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import FileCard from "../../../components/cards/FilesCard";
import InfoCard from "../../../components/cards/InfoCard";

export const ExpandAccounting = ({ row, onCreate, onDelete, onEdit }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  function handleDelete() {
    DBRequest({
      config,
      path: `accounting-exports/${row.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(row),
    });
  }

  function handleDownload() {
    if (row.file) window.open(row.file.temporaryUrl, "_blank");
  }

  const actions = [
    {
      icon: <DownloadIcon />,
      name: t("Download"),
      onClick: handleDownload,
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  const defaultFiles = [
    {
      label: "ZIP File",
      icon: <DownloadIcon />,
      url: row.file ? row.file.temporaryUrl : "",
    },
  ];

  const infos = row.metaData
    ? [
        {
          label: t("Invoices"),
          value: row.metaData.invoicesCount,
        },
        {
          label: t("Payrolls"),
          value: row.metaData.payslipsCount,
        },
        {
          label: t("Receipts"),
          value: row.metaData.receiptsCount,
        },
        {
          label: t("Account Statements"),
          value: row.metaData.accountStatementsCount,
        },
      ]
    : null;

  return (
    <Box
      className="flex flex-col gap-4 relative overflow-y-auto"
      sx={{ minHeight: "400px" }}
    >
      <CardGrid>
        <SpeedDialMenu actions={actions} />
        {row.metaData && <InfoCard infos={infos} />}
        <FileCard files={defaultFiles} />
      </CardGrid>
    </Box>
  );
};
