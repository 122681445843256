import AbcIcon from '@mui/icons-material/Abc';
import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { createElement, useEffect, useState } from 'react';
import FormFields from '../FormFields';

const TypeToggelField = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
  size,
  validationSchema,
}) => {
  const [type, setType] = useState(null);
  const [fields, setFields] = useState();

  useEffect(() => {
    var newFields = {};
    var newType = type;
    field.toggelFields?.forEach((element) => {
      if (!newType) newType = element.key;
      newFields[element.key] = element;
    });
    setFields(newFields);
    setType(newType);
  }, [field]);

  return (
    <Box className="flex gap-2 w-full">
      <ToggleButtonGroup
        value={type}
        exclusive
        aria-label="ToggelTypeField"
        sx={{}}
      >
        {field.toggelFields?.map((toggelField) => (
          <ToggleButton
            value={toggelField.key}
            key={toggelField.key}
            onClick={() => setType(toggelField.key)}
            sx={{
              width: size == 'small' ? '40px' : '53px',
              height: size == 'small' ? '40px' : '53px',
              borderRadius: '10px',
            }}
            size={size}
          >
            <Tooltip title={toggelField.label}>
              {toggelField.Icon && createElement(toggelField.Icon)}
            </Tooltip>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {fields && fields[type] && (
        <FormFields
          fields={[fields[type]]}
          dataCollection={dataCollection}
          size={size}
          updateDataCollection={(data) =>
            updateValidationOnChange('', data, true)
          }
          validationSchema={validationSchema}
        />
      )}
    </Box>
  );
};

export default TypeToggelField;
