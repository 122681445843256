import { Box, Button, Collapse, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useState } from "react";
import { tokens } from "../../../global/theme/tokens";
import { t } from "i18next";
import FormFields from "../FormFields";

const TypeExpandFields = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
  startDataCollection,
  size,
  onPreventEnter,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [expand, setExpand] = useState(false);

  function parsedValidationOnChange(value) {
    updateValidationOnChange("", value, true);
  }

  return (
    <Box
      expand={expand}
      sx={{
        borderRadius: "10px",
        backgroundColor: colors.bgInput,
      }}
    >
      <Button
        className="flex justify-between px-2 py-1 w-full"
        onClick={() => setExpand(!expand)}
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Typography className="px-1 py-2">
          {t(field.label ? field.label : "")}
        </Typography>
        {!expand ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </Button>
      <Collapse in={expand}>
        <Box className="p-3 pt-0">
          <Box
            sx={{
              bgcolor: colors.selected,
              height: "1px",
            }}
            className="w-full mb-3"
          ></Box>
          {field.fields && (
            <Box className="flex flex-col gap-3">
              <FormFields
                updateDataCollection={parsedValidationOnChange}
                fields={field.fields}
                dataCollection={dataCollection}
                errors={errors}
                startDataCollection={startDataCollection}
                size={size}
                onPreventEnter={onPreventEnter}
              />
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default TypeExpandFields;
