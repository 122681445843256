import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useRef } from 'react';

const TypeText = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
  size,
  onFocus,
  onBlur,
}) => {
  function handleTextChange(value) {
    updateValidationOnChange([field.key], value.target.value);
  }
  const inputRef = useRef(null);

  useEffect(() => {
    if (field.focusOnMount) {
      inputRef.current?.focus();
    }
  }, [field.focusOnMount]);

  return (
    <TextField
      sx={{
        gridColumn: field.colspan
          ? `span ${field.colspan} / span ${field.colspan}`
          : 'auto',
      }}
      size={size}
      label={field.label}
      value={dataCollection[field.key] || ''}
      onChange={handleTextChange}
      multiline={field.multiline ? true : false}
      error={Boolean(errors[field.key])}
      helperText={errors[field.key]}
      InputProps={{
        endAdornment: field.unit ? (
          <InputAdornment position="end">{field.unit}</InputAdornment>
        ) : null,
        inputRef,
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      autoComplete={field.autoComplete ?? 'on'}
      disabled={field.disabled}
    />
  );
};

export default TypeText;
