import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import useFetch from '../../api/useFetch';
import ExtendRequest from './ExtendRequest';
export const ShowNewRequest = () => {
  const { id } = useParams();

  const { apiData, setApiData } = useFetch(`employeerequests/${id}`);

  function handleUpdate(newData) {
    setApiData({ ...apiData, data: newData });
  }

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      {apiData && (
        <ExtendRequest request={apiData?.data} onUpdate={handleUpdate} />
      )}
    </Box>
  );
};
