import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Popper,
  Typography,
} from '@mui/material';
import StatusDot from '../../special/StatusDot';
import DateTimeLabel from '../../label/DateTimeLabel';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../global/theme/tokens';
import InfoGridCard from '../PictureNameCards/InfoGridCard';

const MailLogElement = ({ log, size, noSubject }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const id = open ? 'setting' : undefined;

  const variantMap = {
    0: 'open',
    1: 'accepted',
    2: 'rejected',
  };

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  }

  const infos = [];

  log.cc.forEach((cc, index) => {
    infos.push({ label: cc.name, value: cc.address });
  });

  return (
    <Box
      className="w-full flex justify-between px-2 relative my-1"
      sx={{
        flexDirection: size === 'small' ? 'column' : 'row',
      }}
    >
      <Box className="flex items-center">
        <Box className="flex items-center pr-2">
          <StatusDot variant={variantMap[log.status] || 'base'} size={size} />
        </Box>
        <Box className="flex flex-col justify-around w-full">
          <Typography>{log.receiver}</Typography>
          {!noSubject && <Typography>{log.subject}</Typography>}
        </Box>
      </Box>
      <Typography
        variant="h6"
        textAlign="end"
        sx={{
          fontSize: size === 'small' ? '11px' : '12px',
        }}
      >
        <DateTimeLabel dateTime={log.updatedAt} />
      </Typography>
      {log.cc.length > 0 && (
        <Box className="absolute right-1 top-0">
          <Badge
            badgeContent={log.cc.length}
            color={'info'}
            overlap="circular"
            sx={{
              '& .MuiBadge-badge': {
                color: '#FFFFFF',
                minWidth: '13px',
                width: '13px',
                height: '13px',
              },
            }}
          >
            <Button
              onClick={handleClick}
              variant="outlined"
              sx={{
                borderRadius: '50%',
                height: '32px',
                width: '32px',
                minWidth: '32px',
              }}
            >
              <Typography>CC</Typography>
            </Button>
          </Badge>
        </Box>
      )}
      <Popper id={id} open={open} anchorEl={anchorEl} placement="top">
        <ClickAwayListener onClickAway={handleClick}>
          <Box
            className="p-2 rounded-lg"
            sx={{ backgroundColor: colors.glassSolid }}
          >
            <InfoGridCard infos={infos} />
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default MailLogElement;
