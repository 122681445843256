import { Box, Skeleton } from '@mui/material';
import React from 'react';

export const ExoSkeleton = ({ className, size, width = '100%' }) => {
  const sizeMap = {
    small: '40px',
  };

  return (
    <Box className={className}>
      <Skeleton
        sx={{
          padding: 0,
          margin: 0,
          width,
          height: size ? sizeMap[size] || size : '53px',
          transform: 'unset',
          borderRadius: '10px',
        }}
      />
    </Box>
  );
};
