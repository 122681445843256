import { Box } from '@mui/material';
import { API_PATCH, API_POST, DBRequest, useConfig } from '../../api/api';
import ExoForm from '../exo/ExoForm';
import * as Yup from 'yup';
import ExoStepper from '../exo/ExoStepper';
import { useTranslation } from 'react-i18next';

const NewEmptyProject = ({ onClose, onCreate, startDataCollection }) => {
  const { t } = useTranslation();
  const fields = [
    {
      label: t('Project Image'),
      type: 'image',
      key: 'image',
    },
    {
      label: t('Project Name'),
      type: 'text',
      key: 'name',
    },
    {
      label: `${t('Prefix')} (${t('optional')})`,
      type: 'text',
      key: 'prefix',
    },
    {
      label: t('Network Storage'),
      type: 'networkStorage',
      key: 'networkStorage',
    },
    {
      label: t('Supervisor'),
      type: 'user',
      key: 'supervisor',
    },
    {
      label: t('Client'),
      type: 'client',
      key: 'client',
    },
    {
      label: t('Start Date'),
      type: 'date',
      value: 'today',
      key: 'startDate',
    },
    {
      label: `${t('Deadline')} (${t('optional')})`,
      type: 'date',
      key: 'deadline',
    },
  ];
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Project Name is required')),
    supervisor: Yup.object().shape({
      id: Yup.string().required(t('Supervisor is required')),
    }),
    startDate: Yup.string().required(t('Start Date is required')),
    clientType: Yup.string().required(t('Client Type is required')),
    client: Yup.mixed().when('clientType', {
      is: (value) => value === 'contact' || value === 'company',
      then: (schema) =>
        Yup.object().shape({
          id: Yup.string().required(t('Supervisor is required')),
        }),
      otherwise: (schema) => Yup.object().nullable(),
    }),
    networkStorage: Yup.string().required(t('Is required')),
  });
  const config = useConfig();
  function handleSubmit(data) {
    const isUpdate = Boolean(startDataCollection && startDataCollection.id);

    DBRequest({
      config,
      path: isUpdate ? `projects/${startDataCollection.id}` : `projects`,
      method: isUpdate ? API_PATCH(data) : API_POST({ ...data, status: 0 }),
      onResponse: handleCreate,
    });
  }

  function handleCreate(data) {
    onClose();
    onCreate(data.data);
  }

  return (
    <Box
      className="p-2 mx-auto"
      sx={{
        width: '600px',
        maxWidth: '100%',
      }}
    >
      <ExoForm
        header={t('New Empty Project')}
        fields={fields}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onCancle={onClose}
        startDataCollection={
          startDataCollection || { status: 0, clientType: 'internal' }
        }
      />
    </Box>
  );
};

export default NewEmptyProject;
