import { BorderStyle } from '@mui/icons-material';
import { useConfig } from '../../api/api';
import { tokens } from './tokens';

//mui theme settings
export const themeSettings = (mode, customColorTheme, isLowPerformance) => {
  const colors = tokens(mode, customColorTheme, isLowPerformance);

  return {
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& label, & label.Mui-focused': {
              color: colors.grey[800],
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '& input': {
              fontSize: '16px',
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
            '&.MuiOutlinedInput-root': {
              backgroundColor: colors.bgInput,
            },
            '& label.Mui-focused': {
              color: colors.primary[800],
            },
            '& input': {
              fontSize: '16px',
            },
            '& input::-webkit-inner-spin-button': {
              appearance: 'none',
              margin: '0',
            },
            '& p.Mui-error': {
              height: '0',
              margin: '0',
            },
            '& fieldset': {
              borderWidth: '0px',
              borderColor: colors.primary[400],
              borderRadius: '10px',
            },
            '& .Mui-focused>fieldset': {
              borderWidth: '0px!important',
              borderColor: colors.primary[500] + '!important',
              borderRadius: '10px',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: colors.glassSolid,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            '& .Mui-selected': {
              backgroundColor: `${colors.selected}!important`,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
            '& label.Mui-focused': {
              color: colors.primary[800],
            },
            '& input': {
              fontSize: '16px',
            },
            '& input::-webkit-inner-spin-button': {
              appearance: 'none',
              margin: '0',
            },
            '& p.Mui-error': {
              height: '0',
              margin: '0',
            },
            '& fieldset': {
              borderWidth: '1px',
              borderColor: colors.primary[300],
              borderRadius: '10px',
            },
            '& .Mui-focused>fieldset': {
              borderWidth: '1px!important',
              borderColor: colors.primary[800] + '!important',
              borderRadius: '10px',
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            '& li': {
              border: `1px solid ${colors.selected}80!important`,
            },
            '& li:hover': {
              backgroundColor: colors.selected + '!important',
            },
            '& li.Mui-selected': {
              backgroundColor: colors.info + '30!important',
              borderColor: colors.info + '80!important',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
            backgroundColor: colors.glassSolid,
            '& label.Mui-focused': {
              color: colors.grey[800] + '!important',
            },
            '& input::-webkit-inner-spin-button': {
              appearance: 'none',
              margin: '0',
            },
            '& p.Mui-error': {
              height: '0',
              margin: '0',
            },
            '& fieldset': {
              borderWidth: '0px',
              borderColor: colors.primary[300],
              borderRadius: '10px',
            },
            '& .Mui-focused>fieldset': {
              borderWidth: '1px!important',
              borderColor: colors.primary[500] + '!important',
              borderRadius: '10px',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: colors.card + '!important',
            },
            '&.Mui-selected': {
              backgroundColor: colors.bgInput + '!important',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiDialog-paper': {
              maxWidth: '100vw',
              margin: 0,
              backgroundImage: 'unset',
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backdropFilter: isLowPerformance ? 'unset' : 'blur(10px)',
            backgroundColor: colors.backdrop,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            '& .Mui-checked + .MuiSwitch-track': {
              backgroundColor: colors.primary[500] + '!important',
            },
            '& .Mui-checked .MuiSwitch-thumb': {
              backgroundColor: colors.primary[500] + '!important',
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.MuiRadio-root.Mui-checked > span': {
              color: colors.primary[500] + '!important',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
            color: colors.grey[900],
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& .MuiTab-root.Mui-selected': {
              color: colors.primary[700] + '!important',
              backgroundColor: colors.bgInput,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '&.Mui-expanded .MuiAccordionSummary-root  ': {
              backgroundColor: 'transparent!important',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: colors.text,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: colors.text,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            svg: {
              fill: colors.text,
            },
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            '& .Mui-active': {
              fill: colors.info + 'AB',
            },
            svg: {
              fill: colors.card,
              path: {
                fill: colors.success,
              },
            },
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            '>div': { backdropFilter: 'blur(10px)' },
          },
        },
      },
    },
    palette: {
      colorTheme: customColorTheme,
      mode: mode,
      ...{
        primary: {
          main: colors.primary[300],
        },
        secondary: {
          main: colors.card,
        },
        neutral: {
          main: colors.grey[700],
        },
        background: {
          paper: colors.paper,
          default: colors.glassSolid,
        },
        table: {
          default: colors.primary[200],
        },
        warning: {
          main: colors.warning,
        },
        info: {
          main: colors.info,
        },
        success: {
          main: colors.success,
        },
        error: {
          main: colors.error,
        },
        paper: {
          main: colors.glass,
        },
        text: {
          primary: colors.text,
        },
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
      fontFamily: 'Montserrat',
      fontSize: 12,
      h1: {
        fontFamily: 'Montserrat',
        fontSize: 40,
      },
      h2: {
        fontFamily: 'Montserrat',
        fontSize: 32,
      },
      h3: {
        fontFamily: 'Montserrat',
        fontSize: 24,
      },
      h4: {
        fontFamily: 'Montserrat',
        fontSize: 20,
      },
      h5: {
        fontFamily: 'Montserrat',
        fontSize: 16,
      },
      h6: {
        fontFamily: 'Montserrat',
        fontSize: 14,
        color: colors.grey[600],
        fontWeight: 400,
      },
      p: {
        fontFamily: 'Montserrat',
        fontSize: 14,
      },
    },
  };
};
