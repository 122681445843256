import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ExoAvatar from "../exo/ExoAvatar";
import { tokens } from "../../global/theme/tokens";
import { API_POST, DBRequest, useConfig } from "../../api/api";
import Feedback from "./Feedback";
import DateLabel from "../label/DateLabel";

export const RestoreUser = ({ employeeInfo, onClose, onUpdateUser }) => {
  const config = useConfig();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  function handleRestore() {
    DBRequest({
      config,
      path: `users/${employeeInfo.id}/restore`,
      method: API_POST(),
      onResponse: handleResponse,
      onFeedback: handleAlertOpen,
    });
  }

  function handleResponse(updatedUser) {
    onUpdateUser(updatedUser.data);
    onClose();
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <Box className="flex flex-col justify-center items-center mt-10 gap-[85px]">
      <Box className="flex flex-col justify-center items-center gap-3">
        <ExoAvatar
          size="100px"
          picture={employeeInfo.picture?.temporaryUrl}
          name={`${employeeInfo.firstName} ${employeeInfo.lastName}`}
        />
        <Typography fontSize={"20px"}>
          {`${employeeInfo.salutation} ${employeeInfo.firstName} ${employeeInfo.lastName}`}
        </Typography>
        <Typography fontSize={"20px"}>
          {t(`Archived at: `)} <DateLabel date={employeeInfo.deletedAt} />
        </Typography>
      </Box>
      <Typography variant="h3" className="text-center">
        {t("Are you sure you want to restore the following user?")}
      </Typography>
      <Button variant="contained" color="success" onClick={handleRestore}>
        {t("Restore")}
      </Button>
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};
