import { useState } from "react";
import { tokens } from "./theme/tokens";
import { NavLink } from "react-router-dom";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import Cookies from "universal-cookie";
import Zoom from "@mui/material/Zoom";
import Logout from "../auth/Logout";
import ExoAvatar from "../components/exo/ExoAvatar";
import menuItemList from "./menuItemList";

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const cookies = new Cookies();
  const firstName = cookies.get("firstName");
  const lastName = cookies.get("lastName");
  const profilePicture = cookies.get("profilePicture");
  const color = cookies.get("color");
  const position = cookies.get("position");
  const [isOpen, setIsOpen] = useState(true);
  function toggle() {
    setIsOpen(!isOpen);
  }

  const [userStatusColor, setUserStatusColor] = useState(
    colors.greenAccent[500]
  );

  return (
    <nav
      className="hidden sm:flex flex-col h-full relative transition backdrop-blur-lg overflow-hidden pt-4"
      style={{
        backgroundColor: colors.glass,
        width: isOpen ? "240px" : "60px",
        minWidth: isOpen ? "240px" : "60px",
        transition: "0.3s ease",
      }}
    >
      <Box className="w-full relative flex items-center justify-center px-4 gap-4 overflow-hidden h-[62px] py-4">
        {isOpen && (
          <img
            src={
              theme.palette.mode === "dark"
                ? "/assets/svg/exopaper-text-darkmode.svg"
                : "/assets/svg/exopaper-text.svg"
            }
            alt="ExoPaper"
            className=" object-contain object-left w-full h-[40px]"
          />
        )}
        <button
          className={isOpen ? "hamburger is-active" : "hamburger"}
          onClick={toggle}
        >
          <span className="hamburger-box">
            <span
              className="hamburger-inner b1"
              style={{
                backgroundColor: colors.grey[800],
              }}
            ></span>
            <span
              className="hamburger-inner b2"
              style={{
                backgroundColor: colors.grey[800],
              }}
            ></span>
            <span
              className="hamburger-inner b3"
              style={{
                backgroundColor: colors.grey[800],
              }}
            ></span>
          </span>
        </button>
      </Box>
      <NavLink to="/profile">
        <ExoAvatar
          className="rounded-full mb-1 mx-auto mt-3"
          size={isOpen ? "85px" : "45px"}
          picture={profilePicture}
          name={firstName + " " + lastName}
          color={color}
          sx={{ borderColor: userStatusColor }}
        />

        {isOpen && (
          <Box
            id="nav-user-info"
            className="flex flex-col justify-center items-center min-w-max gap-2"
          >
            <Box className="flex flex-col justify-center items-center">
              <Typography className="h-6 nav-text" variant="h4">
                {firstName + " " + lastName}
              </Typography>
              <Typography
                className="h-6 nav-text"
                variant="h5"
                color={colors.grey[600]}
              >
                {position}
              </Typography>
            </Box>
          </Box>
        )}
      </NavLink>
      <Box
        id="nav-menu"
        className="flex justify-start flex-col overflow-y-visible overflow-x-hidden w-full"
      >
        {menuItemList().map((group, index) => (
          <MenuGroup group={group} key={group.title} isOpen={isOpen} />
        ))}
        <Logout isOpen={isOpen} />
      </Box>
    </nav>
  );
};

const MenuGroup = ({ group, isOpen }) => {
  const hasHiddenFalse = group.items.some((item) => item.hidden === false);

  if (!hasHiddenFalse) return;

  return (
    <>
      <MenuTitle isOpen={isOpen}>{group.title}</MenuTitle>
      <Box className="flex flex-col gap-[3px] h-full px-2">
        {group.items.map((item, index) => (
          <MenuItem item={item} key={group.title + index} isOpen={isOpen} />
        ))}
      </Box>
    </>
  );
};

const MenuItem = ({ item, isOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  if (item.hidden) return;

  return (
    <NavLink to={item.to} activeclassname="active">
      <Box
        className="flex flex-row justify-start items-center gap-2 menu-item"
        sx={{
          "& p": {
            color: colors.grey[700] + "!important",
          },
          "& svg": {
            color: colors.grey[700],
          },
          ":hover": {
            cursor: "pointer",
            "& p": {
              color: colors.grey[900] + "!important",
            },
            "& svg": {
              color: colors.grey[900],
            },
          },
          ".active & svg": {
            color: colors.grey[900],
          },
          ".active & p": {
            color: colors.grey[900] + "!important",
          },
        }}
      >
        <Tooltip
          title={item.title}
          placement="right"
          arrow
          TransitionComponent={Zoom}
        >
          <Box
            className="border-[1px] flex flex-row gap-2 px-1.5 py-1.5 nav-item rounded-md w-full overflow-hidden"
            sx={{
              borderColor: colors.grey[900] + "10",
              width: isOpen ? "100%" : "35px",
              ":hover": {
                borderColor: colors.info + "80",
              },
              ".active & ": {
                backgroundColor: colors.card,
                borderColor: colors.info,
              },
            }}
          >
            {item.icon}
            <Typography className=" whitespace-nowrap">{item.title}</Typography>
          </Box>
        </Tooltip>
      </Box>
    </NavLink>
  );
};

const MenuTitle = ({ children, isOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className="relative">
      {isOpen ? (
        <Typography
          className="py-2 pl-2 nav-title relative"
          variant="h6"
          color={colors.grey[700]}
        >
          {children}
        </Typography>
      ) : (
        <Box
          className="h-[2px] rounded-full my-2"
          style={{
            backgroundColor: colors.grey[500],
            marginLeft: "6px",
            width: "42px",
          }}
        />
      )}
    </Box>
  );
};

export default Sidebar;
