import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Dialog,
  Drawer,
  IconButton,
  SwipeableDrawer,
  Typography,
  useTheme,
} from '@mui/material';
import ExoCal from './ExoCal.jsx';
import { useEffect, useRef, useState } from 'react';
import { API_DELETE, DBRequest, useConfig } from '../../api/api';
import { ExoCalList } from './cal/cal-list/ExoCalList.jsx';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../global/theme/tokens.js';
import moment from 'moment';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ExoCalInbox } from './cal/inbox/ExoCalInbox.jsx';
import { updateOrCreate } from '../special/updateOrCreate.js';

export const ExoCalGroup = ({ apiUrl = 'calendar/own' }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const config = useConfig();

  const [events, setEvents] = useState(null);
  const [calendars, setCalendars] = useState(null);
  const [expandCalendars, setExpandCalendars] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month'));

  const calRef = useRef();

  useEffect(() => {
    reqData(startDate.format('YYYY-MM-DD'));
  }, [config, apiUrl]);

  function handleResponse(res) {
    setCalendars(res.data);
    generateEvents(res.data);
  }

  function generateEvents(cals) {
    var newEvents = [];

    cals.forEach((cal) => {
      if (cal.isInactive) return;
      const color = cal.color;
      cal.events?.forEach((event) => {
        newEvents.push({
          ...event,
          color,
          calendar: {
            id: cal.id,
            name: cal.name,
          },
        });
      });
    });
    setEvents(newEvents);
  }

  function handleChange(newCalendars) {
    setCalendars(newCalendars);
    generateEvents(newCalendars);
  }

  // period change
  function addPeriod() {
    calRef.current.setPopperOpen(false);
    const newStartDate = moment(startDate.format('YYYY-MM-DD')).add(
      periodMap['month'].value,
      periodMap['month'].key
    );
    setStartDate(newStartDate);
    reqData(newStartDate.format('YYYY-MM-DD'));
  }

  function subtractPeriod() {
    calRef.current.setPopperOpen(false);
    const newStartDate = moment(startDate.format('YYYY-MM-DD')).subtract(
      periodMap['month'].value,
      periodMap['month'].key
    );
    setStartDate(newStartDate);
    reqData(newStartDate.format('YYYY-MM-DD'));
  }

  const periodMap = {
    day: {
      key: 'days',
      value: 7,
      format: 'ddd',
      prefix: '',
      endOf: 'day',
      startOf: 'day',
    },
    week: {
      key: 'weeks',
      value: 6,
      format: 'w',
      prefix: t('week'),
      endOf: 'week',
      startOf: 'week',
    },
    month: {
      key: 'months',
      value: 1,
      format: 'MMM',
      prefix: '',
      endOf: 'month',
      startOf: 'month',
    },
  };

  function reqData(startDate) {
    const endDate = moment(startDate, 'YYYY-MM-DD').endOf(
      periodMap['month'].endOf
    );
    const reqEndDate = endDate
      .endOf('week')
      .add(1, 'month')
      .format('YYYY-MM-DD');
    const reqStartDate = moment(startDate)
      .startOf('week')
      .subtract(1, 'month')
      .format('YYYY-MM-DD');
    DBRequest({
      config,
      path: `${apiUrl}?startDate=${reqStartDate}&endDate=${reqEndDate}`,
      onResponse: handleResponse,
    });
  }

  function handleDelete(cal, cals) {
    DBRequest({
      config,
      path: `calendars/${cal.id}`,
      method: API_DELETE,
      onResponse: () => {
        handleDeletedCal(cal, cals);
      },
    });
  }
  function handleDeletedCal(cal, cals) {
    var newCalendars = cals.filter((obj) => obj.id !== cal.id);
    setCalendars(newCalendars);
    generateEvents(newCalendars);
  }

  function handleCalendarChange(res) {
    if (res.status == 2) {
      if (calendars.find((cal) => res.calendar.id == cal.id)) {
        handleDelete(res.calendar, calendars);
      }
    } else {
      const newCals = updateOrCreate(calendars, res.calendar);

      handleChange(newCals);
    }
  }

  return (
    <Box className="flex h-full w-full overflow-hidden">
      <Dialog
        open={expandCalendars}
        onClose={() => setExpandCalendars(false)}
        anchor="right"
        sx={{
          '.MuiPaper-root': {
            backgroundImage: 'unset!important',
          },
          '.MuiBackdrop-root': {
            backdropFilter: 'blur(1px)',
            backgroundColor: 'rgba(0, 0, 0, 0.3 )',
          },
        }}
      >
        <ExoCalList
          calendars={calendars}
          onChange={handleChange}
          onDelete={handleDelete}
        />
      </Dialog>

      <Box className="flex flex-col h-full w-full gap-2">
        <Box className="flex gap-2">
          <ExoCalInbox
            calendars={calendars}
            onEventChange={() => reqData(startDate.format('YYYY-MM-DD'))}
            onCalendarChange={handleCalendarChange}
          />

          <Button
            onClick={() => setExpandCalendars(!expandCalendars)}
            sx={{
              margin: '0px',
              padding: '0px',
              bgcolor: colors.glass,
              ':hover': {
                bgcolor: colors.selected,
              },
              borderRadius: '8px',
              minWidth: '45px',
            }}
            className=" backdrop-blur-md"
          >
            <CalendarMonthIcon />
          </Button>

          <Box
            className="flex justify-between items-center px-2 py-1 rounded-lg backdrop-blur-lg z-20 w-full"
            sx={{ bgcolor: colors.glass }}
          >
            <IconButton onClick={subtractPeriod}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography fontWeight={600} fontSize={18}>
              {t(startDate.format('MMMM YYYY'))}
            </Typography>
            <IconButton onClick={addPeriod}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Box>
        {calendars && (
          <ExoCal
            ref={calRef}
            events={events}
            calendars={calendars}
            onEventsChange={setEvents}
            startDate={startDate}
          />
        )}
      </Box>
    </Box>
  );
};
