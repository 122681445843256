import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExoTableToolbar, ExoTableToolbarItem } from "./ExoTableToolbar";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useState } from "react";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";
import ExoTable from "./ExoTable";
import ExoDialog from "./ExoDialog";
import EditIcon from "@mui/icons-material/Edit";
import PresetEditor from "../editor/PresetEditor";
import useFetch from "../../api/useFetch";
import { updateOrCreate } from "../special/updateOrCreate";
import Feedback from "../special/Feedback";

const ExoContractPreset = ({ type = "contract" }) => {
  const { t } = useTranslation();

  const { isLoading, apiData, setApiData } = useFetch(
    `contractpresets?category[eq]=${type}`,
    true
  );

  const [editTemplate, setEditTemplate] = useState(null);
  const [open, setOpen] = useState(false);

  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });

  const config = useConfig();
  // table
  const Columns = [
    {
      header: t("Name"),
      key: "title",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
    },
    {
      header: t("Edit"),
      key: "id",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      cell: ({ row }) => (
        <IconButton
          onClick={() => {
            setEditTemplate(row);
            setOpen(true);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  function handleTemplateSubmit(data) {
    DBRequest({
      config,
      path: data.id ? `contractpresets/${data.id}` : "contractpresets",
      method: data.id ? API_PATCH(data) : API_POST(data),
      onResponse: handleResponse,
      onFeedback: handleFeedback,
    });
  }

  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  function handleResponse(newPreset) {
    setApiData(updateOrCreate(apiData, newPreset.data));
    setOpen(false);
  }

  return (
    <>
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Template")}
          icon={<NoteAddIcon />}
          onClick={() => {
            setEditTemplate(null);
            setOpen(true);
          }}
        />
      </ExoTableToolbar>
      <ExoTable data={apiData} isLoading={isLoading} columns={Columns} />
      <ExoDialog open={open}>
        <PresetEditor
          type={type}
          onClose={() => setOpen(false)}
          onSubmit={handleTemplateSubmit}
          startDataCollection={editTemplate}
        />
      </ExoDialog>
      <Feedback state={alertState} setState={setAlertState} />
    </>
  );
};

export default ExoContractPreset;
