import { ColorModeContext, useMode } from './global/theme/theme';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './scenes/dashboard';
import Invoices from './scenes/invoices';
import Offers from './scenes/offers';
import Companies from './scenes/companies';
import Contacts from './scenes/contacts';
import Requests from './scenes/requests';
import NewRequests from './scenes/new-requests';
import Calendar from './scenes/calendar';
import Help from './scenes/help';
import Projects from './scenes/projects';
import ServerMails from './scenes/servermails';
import Explorer from './scenes/explorer';
import Employees from './scenes/employees';
import TimeTracking from './scenes/time-tracking';
import Login from './open-scenes/login';
import Recruting from './scenes/recruting';
import Contracts from './scenes/contracts';
import Teams from './scenes/teams';
import Settings from './scenes/settings';
import RequireAuth from './auth/RequireAuth';
import BankAccounts from './scenes/bank-accounts';
import SerialMails from './scenes/serial-mail';
import Products from './scenes/products';
import Orders from './scenes/orders';
import Services from './scenes/services';
import DigitalSignaturePortal from './open-scenes/digital-signature';
import { AppConfigProvider } from './AppConfig';
import Categories from './scenes/categories';
import EmploymentContractPresets from './scenes/employee-contract-presets';
import TimeManager from './scenes/time-manager';
import UserTimeDetails from './scenes/user-time-details';
import { SetPassword } from './open-scenes/set-password';
import ContractPresets from './scenes/contract-presets';
import PaymentReminder from './scenes/payment-reminder';
import TaxConsultant from './scenes/tax-consultant';
import { Profile } from './scenes/profile';
import Install from './open-scenes/install';
import { GetStarted } from './open-scenes/get-started';
import { ShowInvoice } from './scenes/invoices/ShowInvoice';
import { ShowOffer } from './scenes/offers/ShowOffer';
import { ShowOrder } from './scenes/orders/ShowOrder';
import { ShowProject } from './scenes/projects/ShowProject';
import { ShowProduct } from './scenes/products/ShowProduct';
import { ExoShowLayout } from './components/exo/ExoShowLayout';
import { ShowCompany } from './scenes/companies/ShowCompany';
import { ShowContact } from './scenes/contacts/ShowContact';
import { ShowService } from './scenes/services/ShowService';
import { ShowEmployee } from './scenes/employees/ShowEmployee';
import FinanceDashboard from './scenes/finance-dashboard';
import { ProjectManagement } from './scenes/project-management/ProjectManagement';
import { Letters } from './scenes/letter';
import TermsOfService from './open-scenes/terms-of-service';
import DeliveryNotes from './scenes/delivery-note';
import { ShowDeliveryNote } from './scenes/delivery-note/ShowDeliveryNote';
import { ProjectManagementWorkPackage } from './scenes/project-management/project-work-package';
import Expenses from './scenes/expenses';
import { PendingFinancials } from './scenes/pending-financials';
import { ShowLetter } from './scenes/letter/ShowLetter';
import { ShowContract } from './scenes/contracts/ShowContract';
import { ShowPaymentReminder } from './scenes/payment-reminder/ShowPaymentReminder';
import { ShowMail } from './scenes/servermails/ShowMail';
import { ShowPayroll } from './scenes/expenses/payrolles/ShowPayroll';
import { ShowReceipt } from './scenes/expenses/receipts/ShowReceipt';
import { ShowTimeSheet } from './scenes/tax-consultant/timesheets/ShowTimeSheet';
import { ShowAccounting } from './scenes/tax-consultant/accounting/ShowAccounting';
import { ShowRequest } from './scenes/requests/ShowRequest';
import { ShowNewRequest } from './scenes/new-requests/ShowNewRequest';
import ShowBankAccount from './scenes/bank-accounts/ShowBankAccount';
import { Invites } from './open-scenes/invites';

function App() {
  const [theme, colorMode] = useMode();

  return (
    <AppConfigProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="invites/:key" element={<Invites />} />
            <Route path="invites/:key/:status" element={<Invites />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route
              path="digital-signature/:token"
              element={<DigitalSignaturePortal />}
            />
            <Route path="get-started/:token" element={<GetStarted />} />
            <Route
              path="set-password/:email/:token"
              element={<SetPassword />}
            />
            <Route path="*" element={<Login />} />
            <Route path="login" element={<Login />} />

            <Route element={<RequireAuth noLayout />}>
              <Route path="install" element={<Install />} />
            </Route>

            <Route element={<RequireAuth />}>
              <Route index element={<Dashboard />} />
              <Route path="finance" element={<FinanceDashboard />} />
              <Route
                path="pending-financials"
                element={<PendingFinancials />}
              />
              <Route path="employees" element={<Employees />} />
              <Route path="profile" element={<Profile />} />
              <Route path="contacts" element={<Contacts />} />
              <Route path="companies" element={<Companies />} />
              <Route path="projects" element={<Projects />} />
              <Route path="products" element={<Products />} />
              <Route path="services" element={<Services />} />
              <Route path="servermails" element={<ServerMails />} />
              <Route path="explorer" element={<Explorer />} />
              <Route path="offers" element={<Offers />} />

              <Route path="time-tracking" element={<TimeTracking />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="letters" element={<Letters />} />
              <Route path="requests" element={<Requests />} />
              <Route path="new-requests" element={<NewRequests />} />
              <Route path="help" element={<Help />} />
              <Route path="settings" element={<Settings />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="contracts" element={<Contracts />} />
              <Route path="recruting" element={<Recruting />} />
              <Route path="teams" element={<Teams />} />
              <Route path="expenses" element={<Expenses />} />
              <Route path="bank-accounts" element={<BankAccounts />} />
              <Route path="serial-mails" element={<SerialMails />} />
              <Route path="tax-consultant" element={<TaxConsultant />} />
              <Route path="time-manager" element={<TimeManager />} />
              <Route
                path="time-manager/:userId"
                element={<UserTimeDetails />}
              />
              <Route path="orders" element={<Orders />} />
              <Route path="delivery-notes" element={<DeliveryNotes />} />
              <Route
                path="work-contract-presets"
                element={<EmploymentContractPresets />}
              />
              <Route path="contract-presets" element={<ContractPresets />} />
              <Route path="orders/new/:offerId" element={<Orders />} />
              <Route path="categories/:type" element={<Categories />} />
              <Route path="payment-reminder" element={<PaymentReminder />} />
              <Route element={<ExoShowLayout />}>
                <Route
                  path="projects/management/:pid"
                  element={<ProjectManagement />}
                />
                <Route
                  path="projects/management/:pid/work-package/:wid"
                  element={<ProjectManagementWorkPackage />}
                />
                <Route path="offers/:id" element={<ShowOffer />} />
                <Route path="invoices/:id" element={<ShowInvoice />} />
                <Route path="orders/:id" element={<ShowOrder />} />
                <Route path="projects/:id" element={<ShowProject />} />
                <Route path="products/:id" element={<ShowProduct />} />
                <Route path="services/:id" element={<ShowService />} />
                <Route path="companies/:id" element={<ShowCompany />} />
                <Route path="contacts/:id" element={<ShowContact />} />
                <Route path="employees/:id" element={<ShowEmployee />} />
                <Route
                  path="delivery-notes/:id"
                  element={<ShowDeliveryNote />}
                />
                <Route path="letters/:id" element={<ShowLetter />} />
                <Route path="contracts/:id" element={<ShowContract />} />
                <Route
                  path="payment-reminders/:id"
                  element={<ShowPaymentReminder />}
                />
                <Route path="servermails/:id" element={<ShowMail />} />
                <Route path="expenses/payrolls/:id" element={<ShowPayroll />} />
                <Route path="expenses/receipts/:id" element={<ShowReceipt />} />
                <Route
                  path="tax-consultant/timesheets/:id"
                  element={<ShowTimeSheet />}
                />
                <Route
                  path="tax-consultant/accounting-exports/:id"
                  element={<ShowAccounting />}
                />
              </Route>
              <Route path="requests/:id" element={<ShowRequest />} />
              <Route path="new-requests/:id" element={<ShowNewRequest />} />
              <Route path="bank-accounts/:id" element={<ShowBankAccount />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AppConfigProvider>
  );
}

export default App;
