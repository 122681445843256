import { createElement, useEffect, useState } from 'react';
import TypeAccessRoleId from './form-fields/TypeAccessRoleId';
import TypeProductCategoryIds from './form-fields/TypeProductCategoryIds';
import TypeDescription from './form-fields/TypeDescription';
import TypeServiceCategoryIds from './form-fields/TypeServiceCategoryIds';
import TypeLogo from './form-fields/TypeLogo';
import TypeReceipt from './form-fields/TypeReceipt';
import TypeImage from './form-fields/TypeImage';
import TypeCompanyId from './form-fields/TypeCompanyId';
import TypeDate from './form-fields/TypeDate';
import TypeText from './form-fields/TypeText';
import TypeColor from './form-fields/TypeColor';
import TypeAddress from './form-fields/TypeAddress';
import TypeIndustryId from './form-fields/TypeIndustryId';
import * as Yup from 'yup';
import TypeTransaction from './form-fields/TypeTransaction';
import TypeHidden from './form-fields/TypeHidden';
import TypeCheckbox from './form-fields/TypeCheckbox';
import TypePresetVarType from './form-fields/TypePresetVarType';
import TypeProjectId from './form-fields/TypeProjectId';
import TypeDateRange from './form-fields/TypeDateRange';
import TypeDateTimeRange from './form-fields/TypeDateTimeRange';
import TypeProfilePicture from './form-fields/TypeProfilePicture';
import TypeClient from './form-fields/TypeClient';
import TypeContactId from './form-fields/TypeContactId';
import TypeMoney from './form-fields/TypeMoney';
import TypeRhythm from './form-fields/TypeRhythm';
import TypeDivider from './form-fields/TypeDivider';
import TypeCustomSelect from './form-fields/TypeCustomSelect';
import TypeTaxAssignment from './form-fields/TypeTaxAssignment';
import TypeRichText from './form-fields/TypeRichText';
import TypeTaxRate from './form-fields/TypeTaxRate';
import TypeImages from './form-fields/TypeImages';
import TypeTime from './form-fields/TypeTime';
import TypeTaskGroup from './form-fields/TypeTaskGroup';
import TypeProject from './form-fields/TypeProject';
import TypeProjectSearch from './form-fields/TypeProjectSearch';
import TypeInvoice from './form-fields/TypeInvoice';
import TypeDelivery from './form-fields/TypeDelivery';
import TypeCostCenter from './form-fields/TypeCostCenter';
import TypeMailSelect from './form-fields/TypeMailSelect';
import TypePassword from './form-fields/TypePassword';
import TypeCustomMultiSelect from './form-fields/TypeCustomMultiSelect';
import TypeAccountingPeriod from './form-fields/TypeAccountingPeriod';
import TypeUser from './form-fields/TypeUser';
import TypeIban from './form-fields/TypeIban';
import { TypeHours } from './form-fields/TypeHours';
import TypeIcon from './form-fields/TypeIcon';
import { TypeCheckConnection } from './form-fields/TypeCheckConnection';
import { TypeNetworkStorage } from './form-fields/TypeNetworkStorage';
import TypeWPEType from './form-fields/TypeWPEType';
import { TypeFolderStatus } from './form-fields/TypeFolderStatus';
import { TypeFileTypeId } from './form-fields/TypeFileTypeId';
import TypePhase from './form-fields/TypePhase';
import TypeWorkPackage from './form-fields/TypeWorkPackage';
import TypeNameValueArray from './form-fields/TypeNameValueArray';
import { TypeTemplate } from './form-fields/TypeTemplate';
import TypeNumber from './form-fields/TypeNumber';
import TypeAccessFeatures from './form-fields/TypeAccessFeatures';
import TypeTransactionArray from './form-fields/TypeTransactionArray';
import TypeCompany from './form-fields/TypeCompany';
import TypeContact from './form-fields/TypeContact';
import TypeReceiptPositions from './form-fields/TypeReceiptPositions';
import { TypeBankAccount } from './form-fields/TypeBankAccount';
import { TypeAutoComplete } from './form-fields/TypeAutoComplete';
import { TypeCountry } from './form-fields/TypeCountry';
import { FormFieldsHighlightContainer } from './FormFieldsHighlightContainer';
import { useTranslation } from 'react-i18next';
import TypeConfigCustomMultiSelect from './form-fields/TypeConfigCustomMutliselect';
import TypeParticipants from './form-fields/TypeParticipants';
import TypeToggelField from './form-fields/TypeToggelField';
import TypeMeeting from './form-fields/TypeMeeting';
import TypeExpandFields from './form-fields/TypeExpandFields';
import TypeHOAIPhases from './form-fields/TypeHOAIPhases';
import TypeNumberSelect from './form-fields/TypeNumberSelect';
import TypeUsersCheckbox from './form-fields/TypeUsersCheckbox.jsx';

const typeMap = {
  bankAccount: TypeBankAccount,
  meeting: TypeMeeting,
  country: TypeCountry,
  autoComplete: TypeAutoComplete,
  accessFeatures: TypeAccessFeatures,
  template: TypeTemplate,
  networkStorage: TypeNetworkStorage,
  date: TypeDate,
  text: TypeText,
  money: TypeMoney,
  color: TypeColor,
  address: TypeAddress,
  logo: TypeLogo,
  accessId: TypeAccessRoleId,
  productCategoryIds: TypeProductCategoryIds,
  serviceCategoryIds: TypeServiceCategoryIds,
  description: TypeDescription,
  companyId: TypeCompanyId,
  contactId: TypeContactId,
  company: TypeCompany,
  contact: TypeContact,
  projectId: TypeProjectId,
  project: TypeProject,
  projectSearch: TypeProjectSearch,
  receipt: TypeReceipt,
  image: TypeImage,
  images: TypeImages,
  industryId: TypeIndustryId,
  transaction: TypeTransaction,
  checkbox: TypeCheckbox,
  hidden: TypeHidden,
  presetVarType: TypePresetVarType,
  dateRange: TypeDateRange,
  dateTimeRange: TypeDateTimeRange,
  user: TypeUser,
  profilePicture: TypeProfilePicture,
  client: TypeClient,
  rhythm: TypeRhythm,
  divider: TypeDivider,
  customSelect: TypeCustomSelect,
  receiptPositions: TypeReceiptPositions,
  taxAssignment: TypeTaxAssignment,
  richText: TypeRichText,
  taxRate: TypeTaxRate,
  time: TypeTime,
  taskGroup: TypeTaskGroup,
  invoice: TypeInvoice,
  delivery: TypeDelivery,
  costCenter: TypeCostCenter,
  mailSelect: TypeMailSelect,
  password: TypePassword,
  customMultiSelect: TypeCustomMultiSelect,
  customConfigMultiSelect: TypeConfigCustomMultiSelect,
  accountingPeriod: TypeAccountingPeriod,
  iban: TypeIban,
  hours: TypeHours,
  icon: TypeIcon,
  checkConnection: TypeCheckConnection,
  seType: TypeWPEType,
  folderStatus: TypeFolderStatus,
  fileTypeId: TypeFileTypeId,
  phase: TypePhase,
  workPackage: TypeWorkPackage,
  nameValueArray: TypeNameValueArray,
  number: TypeNumber,
  transactionArray: TypeTransactionArray,
  participants: TypeParticipants,
  toggelField: TypeToggelField,
  expandFields: TypeExpandFields,
  hoaiPhases: TypeHOAIPhases,
  numberSelect: TypeNumberSelect,
  usersCheckbox: TypeUsersCheckbox,
};

const hideHighlightValidationTypes = [
  'divider',
  'participants',
  'toggelField',
  'expandFields',
  'hoaiPhases',
  'delivery',
];

const FormFields = ({
  fields,
  updateDataCollection,
  dataCollection,
  validationSchema,
  startDataCollection,
  onPreventEnter,
  param,
  size,
  onValidationChange = () => {},
}) => {
  const { t } = useTranslation();
  const [fieldElements, setFieldElements] = useState([]);

  useEffect(() => {
    if (!fields) {
      const newFields = [];
      const keys = Object.keys(typeMap);
      keys.forEach((key) => {
        newFields.push({
          label: key,
          key: key,
          type: key,

          options:
            key === 'delivery'
              ? []
              : [
                  { label: 'TEST-1', value: 1 },
                  { label: 'TEST-2', value: 2 },
                ],
          projectKey: 'project',
          searchParam: 'sellers',
        });
      });
      setFieldElements(newFields);
    } else {
      setFieldElements(fields);
    }
  }, [fields]);

  const [errors, setErrors] = useState({});

  async function updateValidationOnChange(
    key,
    fieldValue,
    destructObject = false
  ) {
    if (destructObject) {
      updateDataCollection({ ...fieldValue });
    } else {
      updateDataCollection({ [key]: fieldValue });
    }
  }

  const validateDataCollection = async (data) => {
    if (
      !validationSchema ||
      Object.keys(validationSchema).length == 0 ||
      !fields
    ) {
      onValidationChange(true);
      return setErrors({});
    }

    var newErrors = {};

    // Get keys from the validation schema
    const schemaKeys = Object.keys(validationSchema.fields);

    // Use Promise.all to wait for all validation checks
    await Promise.all(
      schemaKeys.map(async (key) => {
        const schema = Yup.object().shape({
          [key]: validationSchema.fields[key],
        });
        if (!schema) return;

        await schema
          .validate(data)
          .catch((err) => (newErrors[key] = err.message));
      })
    );

    const isValid = Object.keys(newErrors).length === 0;

    onValidationChange(isValid);
    setErrors(newErrors); // Set errors after all validations are done
  };

  // check validation
  useEffect(() => {
    validateDataCollection(dataCollection);
  }, [dataCollection, fieldElements, validationSchema]);

  return (
    <>
      {fieldElements.map((field, index) => {
        if (!typeMap[field.type])
          return console.error(`INVALIDE Form Field Type: ${field.type}`);
        return (
          <>
            <FormFieldsHighlightContainer
              error={errors[field.key]}
              field={field}
              hasValidation={Boolean(validationSchema?.fields[field.key])}
              hidden={hideHighlightValidationTypes.includes(field.type)}
            >
              {createElement(typeMap[field.type], {
                dataCollection: dataCollection || {},
                startDataCollection,
                field,
                errors: {},
                updateValidationOnChange,
                key: index,
                onPreventEnter: onPreventEnter,
                param,
                size,
                validationSchema,
              })}
            </FormFieldsHighlightContainer>
            {field.after &&
              createElement(field.after, {
                dataCollection: dataCollection || {},
                startDataCollection,
                field,
                errors,
                updateValidationOnChange,
                key: index,
                onPreventEnter: onPreventEnter,
                param,
                validationSchema,
              })}
          </>
        );
      })}
    </>
  );
};

export default FormFields;
