import InventoryIcon from '@mui/icons-material/Inventory';
import BallotIcon from '@mui/icons-material/Ballot';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HistoryIcon from '@mui/icons-material/History';
import { hasPermissions } from '../auth/hasPermissions';
import { useTranslation } from 'react-i18next';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeIcon from '@mui/icons-material/Home';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FolderIcon from '@mui/icons-material/Folder';
import SendIcon from '@mui/icons-material/Send';
import GavelIcon from '@mui/icons-material/Gavel';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EmailIcon from '@mui/icons-material/Email';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleIcon from '@mui/icons-material/People';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ContactsIcon from '@mui/icons-material/Contacts';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const Trans = (string) => {
  const { t } = useTranslation();
  try {
    const ret = t(string);
    return ret;
  } catch (e) {
    return '';
  }
};

const menuItemList = () => [
  {
    title: Trans('Private'),
    description: 'Your own area, where you can find your most important data.',
    items: [
      {
        title: Trans('Dashboard'),
        to: '/',
        icon: <HomeIcon />,
        hidden: !hasPermissions('dashboard', 'read'),
      },
      {
        title: Trans('Time Tracking'),
        to: '/time-tracking',
        icon: <AccessTimeFilledIcon />,
        hidden: !hasPermissions('time-tracking', 'read'),
      },
      {
        title: Trans('Projects'),
        to: '/projects',
        icon: <AccountTreeIcon />,
        hidden: !hasPermissions('project', 'read'),
      },
      {
        title: Trans('Profile'),
        to: '/profile',
        icon: <AccountBoxIcon />,
      },
      {
        title: Trans('Calendar'),
        to: '/calendar',
        icon: <CalendarMonthIcon />,
      },
      {
        title: Trans('Requests'),
        to: '/new-requests',
        icon: <SendIcon />,
        hidden: !hasPermissions('employee-request', 'read'),
      },
      {
        title: Trans('Letters'),
        to: '/letters',
        icon: <EmailIcon />,
        hidden: !hasPermissions('letter', 'read'),
      },
    ],
  },

  {
    title: Trans('Invoicing & Sales'),
    description:
      'The Sales department is responsible for generating revenue through promoting and selling products or services to customers.',
    items: [
      {
        title: Trans('Subscriptions'),
        to: '/contracts',
        icon: <GavelIcon />,
        hidden: !hasPermissions('contract', 'read'),
      },

      {
        title: Trans('Offers'),
        to: '/offers',
        icon: <DescriptionIcon />,
        hidden: !hasPermissions('offer', 'read'),
      },
      {
        title: Trans('Invoices'),
        to: '/invoices',
        icon: <ReceiptIcon />,
        hidden: !hasPermissions('invoice', 'read'),
      },
      {
        title: Trans('Payment Reminder'),
        to: '/payment-reminder',
        icon: <NotificationsActiveIcon />,
        hidden: !hasPermissions('dunning-process', 'read'),
      },
      {
        title: Trans('Serial Mail'),
        to: '/serial-mails',
        icon: <EmailIcon />,
        hidden: true,
      },
      {
        title: Trans('Server Mails'),
        to: '/servermails',
        icon: <ForwardToInboxIcon />,
        hidden: !hasPermissions('mail-log', 'read'),
      },
    ],
  },

  {
    title: Trans('Management'),
    description:
      'The Management department oversees operations and ensures strategic goals are met.',
    items: [
      {
        title: Trans('Orders'),
        to: '/orders',
        icon: <BallotIcon />,
        hidden: !hasPermissions('order', 'read'),
      },
      {
        title: Trans('Delivery Notes'),
        to: '/delivery-notes',
        icon: <LocalShippingIcon />,
        hidden: !hasPermissions('delivery-note', 'read'),
      },
      {
        title: Trans('Products'),
        to: '/products',
        icon: <InventoryIcon />,
        hidden: !hasPermissions('product', 'read'),
      },
      {
        title: Trans('Services'),
        to: '/services',
        icon: <SupervisedUserCircleIcon />,
        hidden: !hasPermissions('service', 'read'),
      },
      {
        title: Trans('Explorer'),
        to: '/explorer',
        icon: <FolderIcon />,
        hidden: !hasPermissions('explorer', 'read'),
      },
      {
        title: Trans('Teams'),
        to: '/teams',
        icon: <Diversity3Icon />,
        hidden: true,
      },
    ],
  },

  {
    title: Trans('Accounting'),
    description:
      'The Accounting department is responsible for managing financial transactions, recording and analyzing financial data, preparing financial statements, and ensuring compliance with relevant regulations and standards.',
    items: [
      {
        title: Trans('Finance'),
        to: '/finance',
        icon: <AssessmentIcon />,
        hidden: !hasPermissions('finance-dashboard', 'read'),
      },
      {
        title: Trans('Expenses'),
        to: '/expenses',
        icon: <RequestQuoteIcon />,
        hidden: !hasPermissions('receipt', 'read'),
      },
      {
        title: Trans('Pending Financials'),
        to: '/pending-financials',
        icon: <MoneyOffCsredIcon />,
        hidden: !hasPermissions('pending-financials-dashboard', 'read'),
      },
      {
        title: Trans('Bank Accounts'),
        to: '/bank-accounts',
        icon: <AccountBalanceIcon />,
        hidden: !hasPermissions('bank-account', 'read'),
      },
      {
        title: Trans('Tax Consultant'),
        to: '/tax-consultant',
        icon: <ImportExportIcon />,
        hidden: !hasPermissions('tax-consultant', 'read'),
      },
    ],
  },

  {
    title: Trans('Human Resources'),
    description:
      'The HR department manages human capital, including recruitment, training, benefits, and compliance.',
    items: [
      {
        title: Trans('Employees'),
        to: '/employees',
        icon: <PeopleIcon />,
        hidden: !hasPermissions('employee-info', 'read'),
      },
      {
        title: Trans('Time Manager'),
        to: '/time-manager',
        icon: <HistoryIcon />,
        hidden: !hasPermissions('time-manager', 'read'),
      },
      {
        title: Trans('Work Contract Templates'),
        to: '/work-contract-presets',
        icon: <HistoryEduIcon />,
        hidden: !hasPermissions('employee-contract-preset', 'read'),
      },
      {
        title: Trans('Recruiting'),
        to: '/recruting',
        icon: <SensorOccupiedIcon />,
        hidden: true,
      },
      {
        title: Trans('Employee Requests'),
        to: '/requests',
        icon: <AllInboxIcon />,
        hidden: !hasPermissions('employee-request-management', 'read'),
      },
    ],
  },

  {
    title: Trans('Customer'),
    description:
      'The Customer department provides information, data, and knowledge to support decision-making, improve organizational efficiency, and facilitate effective communication within the company.',
    items: [
      {
        title: Trans('Companies'),
        to: '/companies',
        icon: <ApartmentIcon />,
        hidden: !hasPermissions('company', 'read'),
      },
      {
        title: Trans('Contacts'),
        to: '/contacts',
        icon: <ContactsIcon />,
        hidden: !hasPermissions('contact', 'read'),
      },
    ],
  },
];

export default menuItemList;
