import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../global/theme/tokens';

export const ExoCalCol = ({
  label,
  noBlur,
}: {
  label: string;
  noBlur?: Boolean;
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className="rounded-sm py-1">
      <Typography
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        fontWeight={500}
        textAlign="center"
      >
        {label}
      </Typography>
    </Box>
  );
};
