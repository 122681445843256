import { useParams } from 'react-router-dom';
import ProjectInfo from './ProjectInfo';
import { Box } from '@mui/material';
import useFetch from '../../api/useFetch';
import Header from '../../components/special/Header';

export const ShowProject = () => {
  const { id } = useParams();
  const { apiData, setApiData } = useFetch(`projects/${id}/statistics`);

  return (
    <Box className="flex flex-col h-full">
      <Header title={apiData && apiData.data.name} />
      <ProjectInfo
        selectedProject={apiData}
        onDelete={() => {}}
        onChange={setApiData}
      />
    </Box>
  );
};
