import ExoStepper, { FinalPage } from "../exo/ExoStepper";
import { useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { API_POST, API_PATCH, DBRequest, useConfig } from "../../api/api";
import StepRecipient from "../steps/StepRecipient";
import StepPositions from "../steps/StepPositions";
import StepDelivery from "../steps/StepDelivery";
import StepCustomStartUp from "../steps/StepCustomStartUp";
import findDifferences from "../exo/findDifferencesInDataCollection";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ExoDialog from "../exo/ExoDialog";
import StepUser from "../steps/StepUser";
import { useEffect, useState } from "react";
import moment from "moment";
import { StepFiles } from "../steps/StepFiles";
import { StepTemplate } from "../steps/StepTemplate";
import { PositionImage } from "../exo/positions/collapse-modules/PositionImage";
import { PositionDescription } from "../exo/positions/collapse-modules/PositionDescription";
import { PositionNumber } from "../exo/positions/collapse-modules/PositionNumber";
import { PositionText } from "../exo/positions/collapse-modules/PositionText";
import { PositionPreCalculation } from "../exo/positions/collapse-modules/PositionPreCalculation";
import { PositionCheckbox } from "../exo/positions/collapse-modules/PositionCheckbox";
import { PositionCopy } from "../exo/positions/collapse-modules/PositionCopy";

const NewOffer = ({
  offers,
  setOffers,
  onClose,
  open,
  startData,
  onUpdate,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();
  const [defaultData, setDefaultData] = useState(null);

  function getPaymentOptions() {
    let options = [];
    options = config ? config?.invoice.paymentOptions.options : [];
    let selection = config ? config?.invoice.paymentOptions.value : [];

    const filteredOptions = options.filter((option) =>
      selection.includes(option.value)
    );
    return filteredOptions;
  }

  const fields = [
    {
      label: t("Title"),
      type: "text",
      key: "title",
    },
    {
      label: t("Valid Until"),
      type: "date",
      key: "validUntil",
    },
    {
      label: t("Note"),
      type: "description",
      key: "note",
    },
    {
      label: t("Payment Method"),
      type: "customSelect",
      key: "paymentMethod",
      noUnselect: true,
      options: getPaymentOptions(),
    },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    validUntil: Yup.string().required("Date is required"),
    paymentMethod: Yup.number().required(),
  });

  const optionalFields = [
    {
      label: `${t("Discount")} (${t("optional")})`,
      type: "checkbox",
      key: "discount",
    },
    {
      label: `${t("Discount Rate")} (${t("optional")})`,
      type: "number",
      key: "discountRate",
      decimalPlaces: 2,
    },
    {
      label: `${t("Discount Days To Pay")} (${t("optional")})`,
      type: "number",
      key: "discountPaymentDeadlineInDays",
      decimalPlaces: 0,
    },
  ];

  const steps = [
    {
      label: t("Recipient"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepRecipient
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t("Template"),
      content: ({
        dataCollection,
        updateValidation,
        updateDataCollection,
        setSteps,
        activeStep,
      }) => (
        <StepTemplate
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          templateType="offer"
          setSteps={setSteps}
          activeStep={activeStep}
        />
      ),
    },
    {
      label: t("Date & Comment"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={fields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={validationSchema}
        />
      ),
    },
    {
      label: t("Skonto"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={optionalFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t("Dokuments"),
      valid: true,
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepFiles
          type="OFFER_ATTACHMENT"
          dataKey="attachments"
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          filter={
            dataCollection.id
              ? `offerAttachments[has]=${dataCollection.id}`
              : "offerAttachments[doesnothave]=0&offerImages[doesnothave]=0"
          }
        />
      ),
    },
    {
      label: t("Positions"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepPositions
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          imageType={`OFFER_ATTACHMENT`}
          imageApiFilter={
            startData && startData.id
              ? `offerImages[has]=${startData.id}`
              : `offerImages[doesnothave]=0`
          }
          collapseModules={[
            { component: PositionImage, key: "image", label: "Image" },
            {
              component: PositionDescription,
              key: "description",
              label: "Description",
            },
            {
              component: PositionNumber,
              key: "taxRate",
              label: "Tax Rate",
              unit: "%",
              value: 19,
              decimalPlaces: 0,
            },
            {
              component: PositionText,
              key: "unit",
              label: "Unit",
            },
            {
              component: PositionCheckbox,
              key: "isOptional",
              label: "Optional Position",
            },
            {
              component: PositionCopy,
              label: "Duplicate position",
            },
            {
              component: PositionPreCalculation,
              key: "price",
              label: "Calculation of sales price",
            },
          ]}
        />
      ),
    },

    {
      label: t("Contact Person"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepUser
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t("Delivery"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepDelivery
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          allowMailContent={true}
        />
      ),
    },
  ];

  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setIsLoading,
      handleError,
    }) => {
      setIsLoading(true);

      const diff = findDifferences(startData, dataCollection);
      DBRequest({
        config,
        path: startData && startData.id ? `offers/${startData.id}` : `offers`,
        method:
          startData && startData.id
            ? API_PATCH(diff)
            : API_POST(dataCollection),
        onResponse: handleRes,
        onLoading: setIsLoading,
        onError: handleError,
      });

      function handleRes(data) {
        handleResponse(data);
        onUpdate(data);
      }
    }, // your operation with the data
    content: (response) => (
      <FinalPage response={response} data={offers} setData={setOffers} />
    ), // component with final operations and the feedback
  };

  useEffect(() => {
    if (!config) return;
    const validUntil = moment()
      .add(parseInt(config.offer.offerValidUntilDays), "days")
      .format();

    setDefaultData({
      validUntil,
      paymentDeadlineInDays: config.invoice.paymentDeadlineInDays,
      discountRate: config.invoice.discountRate,
      discountPaymentDeadlineInDays:
        config.invoice.discountPaymentDeadlineInDays,
      paymentMethod: getPaymentOptions()[0].value,
    });
  }, [config]);

  return (
    <ExoDialog open={open}>
      <ExoStepper
        draftType="offer"
        label={startData && startData.id ? t("Edit Offer") : t("New Offer")}
        message={t("Create Offer")}
        startDataCollection={startData || defaultData}
        onClose={onClose}
        data={steps}
        finalStep={finalStep}
        previewDocument="offer"
      />
    </ExoDialog>
  );
};
export default NewOffer;
