import { Box, Skeleton } from '@mui/material';
import { ExoSkeleton } from '../../exo/ExoSkeleton';

const TypeSkeleton = ({ n = 1, className, size }) => {
  return (
    <Box className="flex flex-col gap-4">
      {Array(n)
        .fill(null)
        .map((_, index) => (
          <ExoSkeleton key={index} className={className} size={size} />
        ))}
    </Box>
  );
};

export default TypeSkeleton;
