import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import SignOffer from "./SignOffer";
import Loader from "../../components/special/Loader";
import AcceptTerms from "./AcceptTerms";
import Sign from "./Sign";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { useTranslation } from "react-i18next";
import { tokens } from "../../global/theme/tokens";
import Cookies from "universal-cookie";
import Div100vh from "react-div-100vh";
import CardContainer from "../../components/cards/layout/CardContainer";

const DigitalSignature = () => {
  const { token } = useParams();
  const cookies = new Cookies();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const colorMode = cookies.get("colorMode");

  const { t } = useTranslation();
  const config = useConfig();

  const [document, setDocument] = useState(null);
  const [success, setSuccess] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    DBRequest({
      config,
      path: `digital-signature/${token}`,
      method: API_GET,
      onResponse: setDocument,
      onFeedback: handleFeedback,
    });
  }, [config]);

  const [message, setMessage] = useState("");
  function handleFeedback(message, type, error) {
    if (type === "error") {
      setDocument(error);
      setMessage(message);
      setSuccess(false);
    }
  }

  return (
    <Div100vh>
      <img
        src={
          colorMode === "dark"
            ? "/assets/uploads/background-dark.webp"
            : "/assets/uploads/background-bright.webp"
        }
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: "0",
          left: "0",
          objectPosition: "32%",
          zIndex: "-5",
        }}
      />
      <Box className="p-2 sm:py-5 w-full h-full overflow-y-auto flex flex-col items-center gap-4 md:gap-8">
        {!document ? (
          <Loader active={true} />
        ) : (
          <>
            {success ? (
              <>
                <Typography
                  variant="h4"
                  className="flex items-center gap-4 mb-10 mx-auto w-fit px-5 py-3 rounded-lg backdrop-blur-lg"
                  sx={{
                    bgcolor: colors.card,
                  }}
                >
                  <img
                    src={
                      document.data.ownCompanyData.logoDark ||
                      document.data.ownCompanyData.logoLight
                        ? colorMode !== "dark" &&
                          document.data.ownCompanyData.logoLight
                          ? document.data.ownCompanyData.logoLight.temporaryUrl
                          : document.data.ownCompanyData.logoDark.temporaryUrl
                        : colorMode === "dark"
                        ? "/assets/svg/exopaper-text-darkmode.svg"
                        : "/assets/svg/exopaper-text.svg"
                    }
                    className="h-8 sm:h-10"
                  />
                  {t("Digital Sign Center")}
                </Typography>
                <Box
                  sx={{
                    width: "900px",
                    maxWidth: "100%",
                    bgcolor: colors.card,
                  }}
                  className=" p-4 rounded-xl h-full flex flex-col backdrop-blur-lg"
                >
                  {termsAccepted ? (
                    <>
                      {document.data.signableType === "offer" && (
                        <SignOffer
                          document={document.data.signable}
                          ownCompany={document.data.ownCompanyData}
                        />
                      )}
                      <Sign
                        token={token}
                        link={document.data.ownCompanyData.url}
                      />
                    </>
                  ) : (
                    <AcceptTerms
                      onAccept={() => setTermsAccepted(true)}
                      onwCompanyData={document.data.ownCompanyData}
                    />
                  )}
                </Box>
              </>
            ) : (
              <Box className="w-full h-full flex justify-center items-center flex-col gap-8 ">
                <img
                  src={
                    document.data.logoDark
                      ? document.data.logoDark.temporaryUrl
                      : "/assets/svg/exopaper-text.svg"
                  }
                  className="h-14 absolute top-10"
                />
                <CardContainer className="p-4">
                  <Typography
                    variant="h2"
                    fontSize="30px"
                    fontWeight="600"
                    textAlign="center"
                    className=" uppercase pb-5"
                  >
                    {t("Document not found")}
                  </Typography>
                  <Box className="w-full flex flex-col justify-center items-center">
                    <Typography fontSize={"20px"} textAlign="center">
                      {t("Contact us for Help")}
                    </Typography>
                    <Box className="flex flex-row justify-between w-32 items-center pt-2">
                      <a href={"tel:" + document.data.telephoneNumber}>
                        <IconButton>
                          <LocalPhoneIcon sx={{ fontSize: "25px" }} />
                        </IconButton>
                      </a>
                      <a href={"mailto:" + document.data.mailAddress}>
                        <IconButton>
                          <EmailIcon sx={{ fontSize: "25px" }} />
                        </IconButton>
                      </a>
                    </Box>
                  </Box>
                </CardContainer>
              </Box>
            )}
          </>
        )}
      </Box>
    </Div100vh>
  );
};

export default DigitalSignature;
