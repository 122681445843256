import { useState, useEffect } from 'react';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { API_GET, DBRequest, useConfig } from '../../../api/api';
import ExoAvatar from '../../exo/ExoAvatar';
import TypeSkeleton from './TypeSkeleton';
import FormHelperText from '../FormHelperText';
import { useTranslation } from 'react-i18next';

const TypeUser = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  size,
}) => {
  const { t } = useTranslation();

  const [users, setUsers] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: 'users',
      method: API_GET,
      onResponse: setUsers,
      onLoading: setIsLoading,
    });
  }, [config]);
  function handleChangeSelect(e) {
    const selectedUser = users.data.filter(
      (obj) => obj.id === e.target.value
    )[0];
    updateValidationOnChange(field.key, selectedUser);
  }

  return (
    <FormControl fullWidth size={size}>
      {isLoading ? (
        <TypeSkeleton size={size} />
      ) : (
        <>
          <InputLabel id="userId-select">{field.label}</InputLabel>
          <Select
            labelId="userId-select"
            value={
              dataCollection[field.key] ? dataCollection[field.key].id : ''
            }
            label={field.label}
            onChange={handleChangeSelect}
            error={Boolean(errors[field.key])}
            sx={{
              '& .MuiSelect-select': {
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '8px',
                padding: '0px 32px 0px 14px',
                height: size == 'small' ? '40px!important' : '53px!important',
              },
            }}
          >
            <MenuItem
              value={''}
              className="flex flex-row gap-2 justify-start items-center"
            >
              {t('Unselect')}
            </MenuItem>
            {users.data.map((element) => (
              <MenuItem
                key={'company-' + element.id}
                value={element.id}
                className="flex flex-row gap-2 justify-start items-center"
              >
                <ExoAvatar
                  picture={element.picture ? element.picture.thumbnailUrl : ''}
                  name={element.firstName + ' ' + element.lastName}
                  size={size === 'small' ? '30px' : '40px'}
                  type={'contact'}
                />
                {element.salutation +
                  ' ' +
                  element.firstName +
                  ' ' +
                  element.lastName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            error={Boolean(errors[field.key])}
            helperText={errors[field.key]}
          />
        </>
      )}
    </FormControl>
  );
};

export default TypeUser;
