import { useTheme, Box, IconButton, Typography, Button } from "@mui/material";
import {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import { tokens } from "../../global/theme/tokens";
import { useDropzone } from "react-dropzone";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useTranslation } from "react-i18next";
import PDFPreview from "../special/PDFPreview";
import { AiProcessing } from "../special/AiProcessing";
import Tesseract from "tesseract.js";
import TypeCheckbox from "../form/form-fields/TypeCheckbox";
import Cookies from "universal-cookie";

const SingleFileUpload = forwardRef(
  (
    {
      setFile = () => {},
      label,
      debug,
      aiIsRunning,
      ocr = false,
      toggleUseAi = false,
    },
    ref
  ) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
    const { t } = useTranslation();

    const cookies = new Cookies();
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);

    const [dataCollection, setDataCollection] = useState(() => {
      if (
        cookies.get("useAiReceipt") == "0" ||
        cookies.get("useAiReceipt") == "1"
      ) {
        return { useAi: cookies.get("useAiReceipt") };
      } else return { useAi: "1" };
    });

    function handleReset() {
      setFileData({});
    }

    const [fileData, setFileData] = useState({});

    useImperativeHandle(ref, () => ({
      reset: handleReset,
      setFileData: setFileData,
    }));

    const handleDrop = useCallback(
      (newFile) => {
        setFileData(newFile[0]);
        setFile(newFile[0], dataCollection.useAi === "1");
      },
      [fileData, dataCollection?.useAi]
    );

    const removeFile = useCallback(() => {
      setFileData([]);
      setFile({});
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop,
    });

    // OCR
    const [recognizedText, setRecognizedText] = useState(null);
    useEffect(() => {
      if (!ocr || !fileData?.size) return setRecognizedText(null);
      const recognizeText = async () => {
        if (fileData) {
          const result = await Tesseract.recognize(fileData);
          setRecognizedText(result.data.text);
          console.log(result);
        }
      };
      recognizeText();
    }, [fileData]);

    function handleChange(e, value) {
      setDataCollection({ useAi: value });
      cookies.set("useAiReceipt", value, {
        path: "/",
        expires: nextYear,
      });
    }

    return (
      <Box
        className="flex flex-col relative items-center w-full h-full rounded-lg overflow-hidden"
        sx={{
          backgroundColor: colors.bgInput,
          maxHeight: "100%",
          maxWidth: "100%",
        }}
      >
        {debug && (
          <Button
            className="top-1 left-1 z-50"
            sx={{ position: "absolute" }}
            variant="contained"
            color="warning"
            onClick={() => console.log(fileData)}
          >
            LOG
          </Button>
        )}
        {fileData?.size > 0 ? (
          <Box className="w-full h-full flex flex-col relative overflow-hidden">
            <AiProcessing
              active={aiIsRunning}
              className="w-full h-full absolute top-0 left-0 z-30"
            />
            {fileData.type === "application/pdf" ? (
              <PDFPreview
                src={
                  fileData.id ? fileData.path : URL.createObjectURL(fileData)
                }
              />
            ) : (
              <img
                className="w-full h-full object-contain"
                src={
                  fileData.id ? fileData.path : URL.createObjectURL(fileData)
                }
                alt={fileData.title}
              />
            )}
            <IconButton
              onClick={removeFile}
              sx={{ position: "absolute" }}
              className="top-[1px] right-[1px] z-20"
              variant="outlined"
              color="error"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Box>
        ) : (
          <Box
            {...getRootProps()}
            className="w-full border-dashed border-2 flex items-center justify-center cursor-pointer h-full rounded-lg"
            sx={{
              backgroundColor: isDragActive
                ? `${colors.info}30`
                : "transparent",
              borderColor: isDragActive ? colors.info : colors.selected,
              aspectRatio: 1 / 1.4,
            }}
          >
            <input {...getInputProps()} />
            <Box className="flex flex-col justify-center items-center  py-4">
              <UploadFileIcon
                sx={{
                  fontSize: "clamp(50px, 10vw, 200px)",
                }}
              />
              <Typography
                variant="h3"
                textAlign="center"
                sx={{
                  fontSize: "clamp(15px, 1.7vw, 50px)",
                }}
              >
                {label || t("Drop your document here")}
              </Typography>
              {toggleUseAi && (
                <Box
                  className="z-10 pt-2"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <TypeCheckbox
                    field={{ label: t("Prefill data using AI"), key: "useAi" }}
                    dataCollection={dataCollection}
                    errors={{}}
                    updateValidationOnChange={handleChange}
                    size="small"
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
        {recognizedText}
      </Box>
    );
  }
);

export default SingleFileUpload;
