import {
  Box,
  Button,
  colors,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import TheatersIcon from '@mui/icons-material/Theaters';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import NotesIcon from '@mui/icons-material/Notes';
import AppsIcon from '@mui/icons-material/Apps';
import ImageIcon from '@mui/icons-material/Image';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExoFilePreview } from '../ExoFilePreview';
import { tokens } from '../../../global/theme/tokens';

export const MediaVisualisation = {
  image: ImageIcon,
  application: AppsIcon,
  text: NotesIcon,
  audio: AudiotrackIcon,
  video: TheatersIcon,
  font: FormatShapesIcon,
};

const MediaPreview = ({
  size = '40px',
  src,
  mimeType = 'image',
  onlyIcons,
  type,
  allowPreview,
}) => {
  const { t } = useTranslation();
  const PreviewElement = MediaVisualisation[mimeType.split('/')[0]];
  // check validation of the url

  const [imgSrc, setImgSrc] = useState('');
  const [allowPreviewState, setAllowPreviewState] = useState(allowPreview);
  const [imageSize, setImageSize] = useState(size);
  const [isIcon, setIsIcon] = useState(false);

  useEffect(() => {
    checkImageValidity(src, mimeType);
  }, [src]);

  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const checkImageValidity = (src, mimeType) => {
    const imgSize = '60%';
    const img = new Image();

    img.onload = () => {
      setIsValidUrl(true);
      setIsChecking(false);
    };

    img.onerror = () => {
      setIsValidUrl(false);
      setIsChecking(false);
    };

    const mimeTypeArray = mimeType.split('/');
    const mimeTypeFirst = mimeTypeArray[0];
    const mimeTypeLast = mimeTypeArray[mimeTypeArray.length - 1];

    const extension = getFileExtension(src);

    if (
      mimeTypeFirst === 'image' ||
      extension == 'jpg' ||
      extension == 'png' ||
      extension == 'svg' ||
      extension == 'tiff' ||
      extension == 'eps' ||
      extension == 'gif' ||
      extension == 'bmp'
    ) {
      setIsIcon(false);
      img.src = src;
    } else if (
      (mimeTypeFirst === 'application' && mimeTypeLast === 'pdf') ||
      extension == 'pdf'
    ) {
      setIsIcon(false);
      img.src = src;
    } else if (
      mimeTypeFirst === 'video' ||
      extension == 'mov' ||
      extension == 'mp4' ||
      extension == 'wmv' ||
      extension == 'avi' ||
      extension == 'avchd' ||
      extension == 'flv' ||
      extension == 'mkv' ||
      extension == 'webm'
    ) {
      img.src = '/assets/icons/play.webp';
      setImageSize(imgSize);
      setIsIcon(true);
    } else if (
      mimeTypeFirst === 'audio' ||
      extension == 'aac' ||
      extension == 'aiff' ||
      extension == 'cda' ||
      extension == 'flac' ||
      extension == 'mp3' ||
      extension == 'ogg' ||
      extension == 'wav' ||
      extension == 'wma'
    ) {
      img.src = '/assets/icons/music.webp';
      setAllowPreviewState(false);
      setImageSize(imgSize);
      setIsIcon(true);
    } else if (extension == 'zip' || extension == 'rar' || extension == '7z') {
      img.src = '/assets/icons/safe.webp';
      setAllowPreviewState(false);
      setImageSize(imgSize);
      setIsIcon(true);
    } else {
      img.src = '/assets/icons/binary.webp';
      setAllowPreviewState(false);
      setImageSize(imgSize);
      setIsIcon(true);
    }

    setImgSrc(img.src);
  };

  function getFileExtension(url) {
    // Remove query parameters (anything after '?')
    if (!url) return '';
    const cleanUrl = url.split('?')[0];

    // Extract the file extension
    const extension = cleanUrl.split('.').pop();

    // Ensure we actually got a file extension (and not the whole URL)
    return extension.includes('/') ? '' : extension;
  }

  // preview
  const [openPreview, setOpenPreview] = useState(null);
  function handleOpen() {
    setOpenPreview(true);
  }

  function handlePreviewClose() {
    setOpenPreview(false);
  }

  return (
    <>
      {isChecking ? (
        <Skeleton
          sx={{
            transform: 'none',
            minWidth: size,
            height: imageSize,
            aspectRatio: '1',
          }}
        />
      ) : (
        <>
          {!onlyIcons ? (
            <>
              {isValidUrl ? (
                <RenderPreview
                  isIcon={isIcon}
                  previewSrc={imgSrc}
                  src={src}
                  size={size}
                  imageSize={imageSize}
                  type={type}
                  onClick={allowPreview && handleOpen}
                />
              ) : (
                <Box
                  sx={{ minWidth: size, height: imageSize }}
                  className="relative"
                >
                  <Typography
                    textAlign={'center'}
                    fontSize={8}
                    fontWeight={500}
                    className="uppercase absolute w-full h-full flex justify-center items-center opacity-50"
                  >
                    {mimeType.split('/')[1] || t('No Preview')}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <PreviewElement style={{ fontSize: imageSize }} />
          )}
        </>
      )}
      {allowPreviewState && (
        <ExoFilePreview
          open={openPreview}
          previewSrc={imgSrc}
          src={src}
          mimeType={mimeType}
          onClose={handlePreviewClose}
        />
      )}
    </>
  );
};

const RenderPreview = ({
  previewSrc,
  src,
  size,
  imageSize,
  type,
  onClick,
  isIcon,
}) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const filterMap = {
    dark: 'brightness(0) saturate(100%) invert(99%) sepia(66%) saturate(10%) hue-rotate(3deg) brightness(116%) contrast(84%)',
    light:
      'brightness(0) saturate(100%) invert(26%) sepia(1%) saturate(4686%) hue-rotate(2deg) brightness(117%) contrast(64%)',
  };

  if (onClick)
    return (
      <Button
        onClick={onClick}
        sx={{ padding: 0, background: isIcon ? colors.bgInput : 'transparent' }}
      >
        <img
          src={previewSrc}
          className="w-full aspect-square object-contain object-center"
          style={{
            borderRadius: '9.5px',
            width: size,
            height: imageSize,
            filter: 'unset',
            opacity: isIcon ? '0.2' : '1',
          }}
        />
      </Button>
    );

  return (
    <img
      src={previewSrc}
      className="w-full aspect-square object-contain object-center"
      style={{
        borderRadius: '9.5px',
        width: size,
        height: imageSize,
        filter: 'unset',
        opacity: isIcon ? '0.2' : '1',
      }}
    />
  );
};

export default MediaPreview;
