import { Box, Divider, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import HOAIPhase from "../../exo/hoai/HOAIPhase";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../../label/MoneyLabel";
import { useEffect, useState } from "react";

const TypeHOAIPhases = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
  startDataCollection,
  size,
  onPreventEnter,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [phases, setPhases] = useState(field.phases);

  useEffect(() => {
    setPhases([...field.phases]);
  }, [field]);

  function calculatePhasePercent(phases) {
    return phases.reduce(
      (sum, phase) =>
        phase.isCrossed !== true
          ? sum +
            (phase.value !== undefined
              ? Number(phase.value)
              : Number(phase.maxPercent))
          : sum,
      0
    );
  }

  function handleUpdate(key, value) {
    const phaseId = parseInt(key);
    let newPhases = [...phases];
    newPhases[phaseId] = { ...newPhases[phaseId], value: value };
    updateValidationOnChange("phases", newPhases);
    setPhases(newPhases);
  }

  function togglePhase(key, value) {
    const phaseId = parseInt(key);
    phases[phaseId] = { ...phases[phaseId], isCrossed: value };
    updateValidationOnChange("phases", phases);
  }

  return (
    <Box>
      <Box className="flex justify-start mb-3 mt-10">
        <Typography fontSize={22}>{t("Service Phases")}</Typography>
      </Box>
      <Box className="mb-5">
        {phases.map((phase, index) => {
          return (
            <>
              <HOAIPhase
                togglePhase={togglePhase}
                updateValidationOnChange={handleUpdate}
                phase={phase}
                phaseNumber={index}
                baseHonorarium={dataCollection.baseHonorarium}
              />
              {index !== phases.length - 1 && (
                <Box className="w-full flex justify-center rounded-full">
                  <Box
                    sx={{ bgcolor: colors.text + "30" }}
                    className="h-[1px] w-[95%]"
                  />
                </Box>
              )}
            </>
          );
        })}
      </Box>
      <Divider />
      <Box className="flex justify-between my-5">
        <Typography fontWeight={600} className="w-full pl-2">
          {t("Sum of phases")}
        </Typography>
        <Typography fontWeight={600} sx={{ width: "100px", minWidth: "100px" }}>
          {calculatePhasePercent(phases) + "%"}
        </Typography>
        <Typography
          fontWeight={600}
          className="text-right pr-3"
          sx={{ width: "125px", minWidth: "125px" }}
        >
          <MoneyLabel
            money={
              Math.round(
                (100 *
                  ((dataCollection.baseHonorarium ?? 0) *
                    calculatePhasePercent(phases))) /
                  100
              ) / 100
            }
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default TypeHOAIPhases;
