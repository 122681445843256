import { useTheme } from '@emotion/react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { tokens } from '../../../global/theme/tokens';
import { useEffect, useState } from 'react';
import NewAddress from '../../new/NewAddress';
import { useTranslation } from 'react-i18next';
import LabeledBox from '../../special/LabeledBox';

const TypeAddress = ({
  field,
  updateValidationOnChange,
  dataCollection,
  errors,
  size,
  onPreventEnter = () => {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [valid, setValid] = useState(false);
  const [open, setOpen] = useState(false);
  const [addressData, setAddressData] = useState(
    dataCollection[field.key] || {}
  );

  // reset address
  const addressFallBack = { ...dataCollection[field.key] } || {};
  function handleCancle() {
    updateValidationOnChange(field.key, addressFallBack);
    setOpen(false);
    onPreventEnter(false);
  }

  function handleSave() {
    updateValidationOnChange(field.key, addressData);
    setOpen(false);
    onPreventEnter(false);
  }

  function handleUpdate(data) {
    setAddressData(data);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && valid) {
        event.preventDefault(); // Prevent default form submission behavior
        handleSave();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [dataCollection, valid]);

  return (
    <>
      <LabeledBox
        onClick={() => {
          setOpen(true);
          onPreventEnter(true);
        }}
        size={size}
        label={dataCollection[field.key] ? field.label : ''}
      >
        {dataCollection[field.key] && dataCollection[field.key].street
          ? dataCollection[field.key].street +
            ' ' +
            dataCollection[field.key].houseNumber +
            ', ' +
            dataCollection[field.key].zipCode +
            ' ' +
            dataCollection[field.key].city +
            ', ' +
            dataCollection[field.key].country
          : field.label}
      </LabeledBox>
      <Dialog open={open}>
        <Box className=" flex gap-3 flex-col p-2">
          <Typography variant="h3" textAlign="center">
            {t('Address')}
          </Typography>
          <NewAddress
            updateDataCollection={handleUpdate}
            dataCollection={dataCollection}
            updateValidation={setValid}
            fieldKey={field.key}
          />
          <Box className="w-full flex justify-between">
            <Button onClick={handleCancle}>{t('Cancle')}</Button>
            <Button
              onClick={handleSave}
              variant="contained"
              color="success"
              disabled={!valid}
            >
              {t('Save')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default TypeAddress;
