import React, { useEffect, useMemo, useState } from 'react';
import ModuleLayout from './ModuleLayout';
import { WPETypeToDoList } from '../../scenes/project-management/project-work-package/work-package-elements/list/WPETypeToDoList';
import useFetch from '../../api/useFetch';
import { useTranslation } from 'react-i18next';
import { DragDropContext } from 'react-beautiful-dnd';
import { Box, IconButton, Typography } from '@mui/material';
import { API_GET, DBRequest, useConfig } from '../../api/api';
import TypeCustomSelect from '../form/form-fields/TypeCustomSelect';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

export const DashboardTodos = ({ rowSpan, colSpan }) => {
  const { apiData, setApiData, isLoading, setIsLoading } = useFetch(
    'projects/own?status[eq]=0'
  );
  const { t } = useTranslation();
  const config = useConfig();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [selected, setSelected] = useState(null);
  const [data, setData] = useState(null);
  const [preselected, setPreselected] = useState(
    cookies.get('dashboardTaskSelection')
  );
  const [fields, setFields] = useState(null);
  const [wps, setWps] = useState({});
  const [dataCollection, setDataCollection] = useState({ wpKey: preselected });

  useEffect(() => {
    if (!apiData) return;
    setFields(generateFields(apiData));
  }, [apiData]);

  useEffect(() => {
    if (preselected) {
      handleChange(null, preselected);
    }
  }, [preselected]);

  function generateFields(apiData) {
    let newWps = {};
    let options = [];
    apiData.data.forEach((project) => {
      project.phases.forEach((phase) => {
        phase.workPackages.forEach((wp) => {
          const key = `wp${wp.id}p${project.id}`;
          newWps[key] = { wp: { id: wp.id }, pid: project.id };
          let option = {
            label: project.name + '/' + phase.name + '/' + wp.title,
            value: key,
          };
          options.push(option);
        });
      });
    });

    setWps(newWps);

    return {
      label: t('Workpackage'),
      key: 'wpKey',
      type: 'customSelect',
      noUnselect: true,
      options: options,
    };
  }

  function handleChange(key, res) {
    setDataCollection({ wpKey: res });

    setData(null);
    setSelected(res);

    if (preselected !== res) {
      const nextYear = new Date();
      nextYear.setFullYear(nextYear.getFullYear() + 1);
      cookies.set('dashboardTaskSelection', res, {
        path: '/',
        expires: nextYear,
      });
    }
  }

  useEffect(() => {
    if (!selected || Object.keys(wps).length === 0) return;
    if (preselected && !Object.keys(wps).includes(preselected)) {
      setPreselected(null);
      cookies.remove('dashboardTaskSelection');
      return;
    }

    DBRequest({
      config,
      path: `work-packages/${wps[selected].wp.id}/elements/list/own`,
      method: API_GET,
      onResponse: handleResponse,
    });
  }, [selected, wps]);

  function handleResponse(data) {
    setData(data.data);
  }

  return (
    <>
      <ModuleLayout
        colSpan={colSpan}
        rowSpan={rowSpan}
        isLoading={!apiData || !fields}
      >
        <Box className="w-full h-full flex flex-col">
          <Box className="flex items-center my-2 gap-2">
            <TypeCustomSelect
              size="small"
              field={fields}
              dataCollection={dataCollection}
              updateValidationOnChange={handleChange}
              errors={{}}
              startDataCollection={dataCollection}
            />
            <Box>
              <IconButton
                disabled={!selected}
                onClick={() =>
                  selected &&
                  navigate(
                    `/projects/management/${wps[selected].pid}/work-package/${wps[selected].wp.id}`
                  )
                }
              >
                <OpenInNewIcon />
              </IconButton>
            </Box>
          </Box>
          <DragDropContext>
            <Box className="h-full overflow-auto">
              {data && selected && data.length > 0 ? (
                data.map((el, index) => (
                  <Box className="mb-2">
                    <WPETypeToDoList
                      key={wps[selected].wp.id}
                      data={el}
                      element={wps[selected].wp}
                      pid={wps[selected].pid}
                      disableDragging={true}
                      isInDashboard={true}
                    />
                  </Box>
                ))
              ) : (
                <Box className="h-full flex justify-center items-center">
                  <Typography>{t('No Tasks found')}</Typography>
                </Box>
              )}
            </Box>
          </DragDropContext>
        </Box>
      </ModuleLayout>
    </>
  );
};
