import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import useFetch from '../../../api/useFetch';
import { t } from 'i18next';
import { createElement, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import TypeText from './TypeText';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';

const TypeMeeting = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
  size = 'small',
  onFocus,
  onBlur,
}) => {
  const cookies = new Cookies();
  const current = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1);

  const rememberedMeetingType = cookies.get('meetingType');
  const { apiData } = useFetch(`integrations`, true);
  const [url, setUrl] = useState('');

  const autoGeneratedTypes = ['zoom', 'teams', 'googleMeet'];

  function handleChange(e) {
    const newType = e.target.value;

    updateValidationOnChange([field.key], { type: newType });

    cookies.set('meetingType', newType, {
      path: '/',
      expires: nextYear,
    });

    // set URL
    if (autoGeneratedTypes.includes(newType)) {
      setUrl(t('Is generated automatically'));
    } else setUrl('');
  }

  const typeMap = {
    zoom: Zoom,
    googleMeet: GoogleMeet,
    teams: Teams,
    custom: Custom,
  };

  useEffect(() => {
    if (!dataCollection[field.key]?.type) {
      if (autoGeneratedTypes.includes(rememberedMeetingType)) {
        setUrl(t('Is generated automatically'));
      } else setUrl('');
      updateValidationOnChange([field.key], {
        type: rememberedMeetingType || 'custom',
      });
    }
  }, [rememberedMeetingType, dataCollection]);

  return (
    <Box className="flex gap-2">
      <FormControl
        className="w-[160px]"
        sx={{
          height: size === 'small' ? '40px' : '53px',
        }}
      >
        <InputLabel id="meeting-type-label" size={size}>
          {t('Provider')}
        </InputLabel>
        <Select
          labelId="meeting-type-label"
          value={
            dataCollection[field.key]
              ? dataCollection[field.key].type
              : rememberedMeetingType
          }
          onChange={handleChange}
          sx={{
            height: size === 'small' ? '40px' : '53px',
          }}
        >
          <MenuItem value="custom">
            {createElement(typeMap['custom'], { size })}
          </MenuItem>
          {apiData?.data.map(
            (obj) =>
              typeMap[obj.type] && (
                <MenuItem
                  value={obj.type}
                  disabled={obj.actionRequired}
                  className="flex flex-col"
                >
                  {createElement(typeMap[obj.type], {
                    size,
                    actionRequired: obj.actionRequired,
                  })}
                  {obj.actionRequired && (
                    <Typography variant="h6" fontSize={9}>
                      {t('You must link your account in your profile!')}
                    </Typography>
                  )}
                </MenuItem>
              )
          )}
        </Select>
      </FormControl>
      <Box className="w-full flex flex-col">
        <TypeText
          dataCollection={{ url }}
          field={{
            label: t('Url'),
            key: 'url',
            colspan: 1,
            disabled: autoGeneratedTypes.includes(
              dataCollection[field.key]
                ? dataCollection[field.key].type
                : rememberedMeetingType
            ),
          }}
          size={size}
          updateValidationOnChange={(key, value) => {
            updateValidationOnChange([field.key], {
              ...dataCollection[field.key],
              url: value,
            });
            setUrl(value);
          }}
        />
      </Box>
    </Box>
  );
};

export const Zoom = ({ size, actionRequired }) => {
  const iconPath = '/assets/meeting-provider/zoom-logo.svg';

  return (
    <Box
      className="rounded-full flex items-center justify-center w-full "
      sx={{ height: '25px' }}
    >
      <img src={iconPath} style={{ height: '60%' }} />
    </Box>
  );
};

export const GoogleMeet = ({ size, actionRequired }) => {
  const iconPath = '/assets/meeting-provider/meet-logo.svg';

  return (
    <Box
      className="rounded-full flex items-center justify-center w-full"
      sx={{ height: '25px' }}
    >
      <img src={iconPath} style={{ height: '100%' }} />
    </Box>
  );
};

export const Teams = ({ size }) => {
  const iconPath = '/assets/meeting-provider/teams-logo.png';

  return (
    <Box
      className="rounded-full flex items-center justify-center w-full"
      sx={{ height: '25px' }}
    >
      <img
        src={iconPath}
        style={{ height: '90%' }}
        className=" object-contain"
      />
    </Box>
  );
};

export const Custom = ({ size }) => {
  return (
    <Box
      className="rounded-full flex items-center justify-center w-full gap-2"
      sx={{ height: '25px' }}
    >
      <VideoCameraFrontIcon />
      <Typography>{t('Url')}</Typography>
    </Box>
  );
};

export default TypeMeeting;
