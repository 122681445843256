import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import TypeSkeleton from "./TypeSkeleton";
import FormHelperText from "../FormHelperText";
import { useTranslation } from "react-i18next";
import LabeledBox from "../../special/LabeledBox";
import ExoDialog from "../../exo/ExoDialog";
import ExoCostCenter, { costCenterIconMap } from "../../exo/ExoCostCenter";
import ExoAvatar from "../../exo/ExoAvatar";
import DBSearch from "../../special/DBSearch";
import CardGrid from "../../cards/layout/CardGrid";

const TypeCostCenter = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  value,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (
      dataCollection[field.key] &&
      (!selected || dataCollection[field.key].id !== selected.id)
    ) {
      setSelected(dataCollection[field.key]);
    }
    if (!dataCollection[field.key] && selected) setSelected(null);
  }, [dataCollection[field.key]]);

  function handleChangeSelect(value) {
    setSelected(value);
    updateValidationOnChange(field.key, value);
    setOpen(false);
  }

  return (
    <Box
      sx={{
        gridColumn: field.colspan
          ? `span ${field.colspan} / span ${field.colspan}`
          : "auto",
      }}
    >
      <LabeledBox label={field.label} onClick={() => setOpen(true)}>
        {selected ? (
          <Box className="flex items-center gap-4">
            {costCenterIconMap[selected.type]}
            {selected.name}
          </Box>
        ) : (
          t("No Costcenter Selected")
        )}
      </LabeledBox>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
      <ExoDialog open={open} onClose={() => setOpen(false)}>
        <SelectCostCenter
          types={field.types || ["DIRECT_COSTS"]}
          onSelect={handleChangeSelect}
          onClose={() => setOpen(false)}
        />
      </ExoDialog>
    </Box>
  );
};

const SelectCostCenter = ({ onSelect, onClose, types }) => {
  const { t } = useTranslation();
  const [costCenters, setCostCenters] = useState(null);
  const [projects, setProjects] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "cost-centers?type[eq]=0",
      method: API_GET,
      onResponse: setCostCenters,
    });
  }, [config]);

  return (
    <Box className="flex flex-col gap-4 overflow-y-auto h-full">
      <DBSearch param="projects" onSearch={setProjects}>
        <IndirectCostCenters costCenters={costCenters} onSelect={onSelect} />
        <Typography variant="h4">{t("Projects")}</Typography>
        <CardGrid>
          {projects ? (
            projects.map((project) => (
              <Project
                key={project.id}
                project={project}
                types={types}
                onSelect={setSelectedProject}
              />
            ))
          ) : (
            <TypeSkeleton n={25} />
          )}
        </CardGrid>
      </DBSearch>
      <CostCenterList
        onSelect={onSelect}
        selectedProject={selectedProject}
        onClose={() => setSelectedProject(null)}
        types={types}
      />
    </Box>
  );
};
const IndirectCostCenters = ({ costCenters, onSelect }) => {
  const { t } = useTranslation();

  return (
    <Box className="flex flex-col gap-2">
      <Typography variant="h4">{t("Indirect Costs")}</Typography>
      <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
        {costCenters &&
          costCenters.data.map((costCenter) => (
            <ExoCostCenter
              costCenter={costCenter}
              key={costCenter.id}
              onClick={onSelect}
            />
          ))}
      </Box>
    </Box>
  );
};

const Project = ({ project, onSelect, types }) => {
  return (
    <Button
      onClick={() => onSelect(project)}
      variant="contained"
      color="secondary"
      sx={{
        transition: "scale 0.3s ease",
      }}
      className=" cursor-pointer w-full flex flex-col backdrop-blur-lg items-center p-4 rounded-lg"
    >
      <ExoAvatar
        picture={project.image}
        type={"company"}
        name={project.subLabel}
        size="90px"
      />
      <Typography variant="h3" className="pt-4">
        <b>{project.nr}</b>
      </Typography>
      <Typography variant="h6">{project.label}</Typography>
    </Button>
  );
};

const CostCenterList = ({ selectedProject, onClose, onSelect, types }) => {
  const config = useConfig();
  const [project, setProject] = useState(null);
  useEffect(() => {
    if (selectedProject && project && project.id === selectedProject.id) return;
    if (!config || !selectedProject) return;
    DBRequest({
      config,
      path: `projects/${selectedProject.id}/statistics`,
      method: API_GET,
      onResponse: setProject,
    });
  }, [config, selectedProject]);

  return (
    <ExoDialog open={Boolean(selectedProject)} onClose={onClose}>
      <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
        {project &&
          project.data &&
          project.data.directCostCenters &&
          project.data.directCostCenters.map((costCenter) => (
            <ExoCostCenter
              onClick={onSelect}
              costCenter={costCenter}
              key={costCenter.id}
              hidden={!types.find((type) => type === costCenter.type)}
            />
          ))}
      </Box>
    </ExoDialog>
  );
};

export default TypeCostCenter;
