import { Box, useTheme } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { tokens } from '../../global/theme/tokens.js';
import moment from 'moment';
import { ExoCalDay } from './cal/ExoCalDay.tsx';
import { useTranslation } from 'react-i18next';
import { ExoCalCol } from './cal/ExoCalCol.tsx';
import Feedback from '../special/Feedback.jsx';
import { updateOrCreate } from '../special/updateOrCreate.js';
import { ExoCalPopper } from './cal/ExoCalPopper.jsx';

const ExoCal = forwardRef(
  (
    {
      events,
      calendars,
      onDateChange,
      startLayout,
      startDate,
      onEventsChange,
      onSelection,
      noBlur,
      noBackground,
      actions,
    },
    ref
  ) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

    const { t } = useTranslation();

    const [days, setDays] = useState();
    const [layout, setLayout] = useState('month');
    const [editMode, setEditMode] = useState(false);

    function generateDays(
      start, // Only the month of this date is relevant
      period = 'month',
      events = []
    ) {
      const days = [];
      let firstCalendarDay;
      let lastCalendarDay;
      let current;

      if (period === 'month') {
        const startDate = moment(start).startOf('month'); // Always 1st of the month
        const endDate = moment(start).endOf('month'); // Last day of the month

        firstCalendarDay = startDate.clone().startOf('isoWeek'); // Ensures the grid starts on a Monday
        lastCalendarDay = endDate.clone().endOf('isoWeek'); // Ensures the grid ends on a Sunday

        current = firstCalendarDay.clone();
        while (
          current.isBefore(lastCalendarDay, 'day') ||
          current.isSame(lastCalendarDay, 'day')
        ) {
          days.push({
            date: current.clone(),
            events: [],
            wholeDayEvents: [],
            isCurrentInterval:
              current.isSameOrAfter(startDate) &&
              current.isSameOrBefore(endDate),
          });
          current.add(1, 'day');
        }
      } else if (period === 'week') {
        const startDate = moment(start);
        firstCalendarDay = startDate.clone().startOf('isoWeek'); // Ensures the week starts on Monday
        lastCalendarDay = firstCalendarDay.clone().add(6, 'days'); // Ensures it covers 7 days

        current = firstCalendarDay.clone();
        while (
          current.isBefore(lastCalendarDay, 'day') ||
          current.isSame(lastCalendarDay, 'day')
        ) {
          days.push({
            date: current.clone(),
            events: [],
            wholeDayEvents: [],
            isCurrentInterval:
              current.isSameOrAfter(firstCalendarDay) &&
              current.isSameOrBefore(lastCalendarDay),
          });
          current.add(1, 'day');
        }
      }

      // **Assign Events to the Correct Days with Levels**
      if (events) {
        // Sort events by start time (earliest first), then by ID
        const sortedEvents = [...events].sort(
          (a, b) =>
            moment(a.startDateTime).valueOf() -
              moment(b.startDateTime).valueOf() || a.id - b.id
        );

        sortedEvents.forEach((event) => {
          const eventStart = moment(event.startDateTime);
          const eventEnd = moment(event.endDateTime);
          const isWholeDay = event.wholeDay;

          let currentDate = eventStart.clone();
          let assignedLayer = -1;

          while (currentDate.isSameOrBefore(eventEnd, 'day')) {
            const targetDay = days.find((day) =>
              day.date.isSame(currentDate, 'day')
            );

            if (targetDay) {
              // 🔹 **Erstelle eine Liste aller belegten Layer (WholeDay zuerst)**
              const wholeDayLayers = new Set(
                targetDay.events.filter((e) => e.wholeDay).map((e) => e.layer)
              );
              const normalLayers = new Set(
                targetDay.events.filter((e) => !e.wholeDay).map((e) => e.layer)
              );

              if (isWholeDay) {
                // 🟢 **WholeDay Events bekommen die niedrigste verfügbare Layer-Nummer**
                let level = 0;
                while (wholeDayLayers.has(level) || normalLayers.has(level)) {
                  level++;
                }
                assignedLayer = assignedLayer === -1 ? level : assignedLayer;
              } else {
                // 🔵 **Normale Events kommen erst danach**
                let level = 0;
                while (wholeDayLayers.has(level) || normalLayers.has(level)) {
                  level++;
                }
                assignedLayer = assignedLayer === -1 ? level : assignedLayer;
              }

              // 🛠 **Erstelle das Event mit der finalen Layer-Zuweisung**
              const newEvent = {
                ...event,
                isFirst: currentDate.isSame(eventStart, 'day'),
                isLast: currentDate.isSame(eventEnd, 'day'),
                isFirstInWeek: currentDate.isoWeekday() === 1, // Montag in ISO-Woche
                layer: assignedLayer,
              };

              // 🔹 **Alle Events (WholeDay & normale) in dieselbe Liste pushen**
              targetDay.events.push(newEvent);
            }

            currentDate.add(1, 'day'); // Nächster Tag
          }
        });
      }

      return days;
    }

    useEffect(() => {
      setDays(generateDays(startDate, layout, events));
    }, [startDate, events, calendars]);

    // feedback
    const [alertState, setAlertState] = useState({
      alertOpen: false,
      alertType: 'info',
      alertText: 'test',
    });
    function handleFeedback(text, type) {
      setAlertState({ alertOpen: true, alertText: text, alertType: type });
    }
    const columns = [
      t('Monday'),
      t('Tuesday'),
      t('Wednesday'),
      t('Thursday'),
      t('Friday'),
      t('Saturday'),
      t('Sunday'),
    ];

    // Drag & Drop States
    const [dragStart, setDragStart] = useState(null);
    const [dragEnd, setDragEnd] = useState(null);
    const [selectedRange, setSelectedRange] = useState(new Set());
    const [popperOpen, setPopperOpen] = useState(false);
    const [selectionData, setSelectionData] = useState(null);

    useImperativeHandle(ref, () => ({ setPopperOpen }));

    // Handle Mouse Down (Start Selection)
    function handleMouseDown(date) {
      console.log(date, popperOpen);

      if (popperOpen) return; // Prevent setting popperOpen if already open

      setDragStart(date);
      setDragEnd(date);
    }

    // Handle Mouse Move (Update Selection)
    function handleMouseMove(date) {
      if (!dragStart || dragStart?.isSame(date, 'day')) return;

      const range = new Set();

      if (!dragStart.isSame(dragEnd, 'day') && selectedRange.size === 0)
        range.add(dragStart.format('YYYY-MM-DD'));

      setDragEnd(date);

      const start = moment.min(dragStart, date);
      const end = moment.max(dragStart, date);

      let current = start.clone();
      while (current.isSameOrBefore(end, 'day')) {
        range.add(current.format('YYYY-MM-DD'));
        current.add(1, 'day');
      }
      setSelectedRange(range);
    }

    // Handle Mouse Up (Finish Selection)
    function handleMouseUp() {
      if (dragStart?.isSame(dragEnd, 'day')) {
        setDragStart(null);
        setDragEnd(null);
        return;
      }
      setPopperOpen(true); // Ensure Popper stays open after selection
      if (dragStart && dragEnd) {
        if (dragStart > dragEnd) {
          setSelectionData({
            startDateTime: dragEnd.format(),
            endDateTime: dragStart.format(),
          });
        } else {
          setSelectionData({
            startDateTime: dragStart.format(),
            endDateTime: dragEnd.format(),
          });
        }
      }
      setDragStart(null);
      setDragEnd(null);
    }

    // Handle Double-Click for Single-Day Event
    function handleDoubleClick(date) {
      if (popperOpen) return; // Prevent opening popper if it's already open
      setSelectionData({
        startDateTime: date.format(),
        endDateTime: date.format(),
      });
      setSelectedRange(new Set([date.format('YYYY-MM-DD')])); // Only update for the single day
      setPopperOpen(true); // Open popper for single-day event
    }

    // popper
    const [popperAnchor, setPopperAnchor] = useState(null);

    function handleUpdatePopper(event, day) {
      setPopperAnchor(event.currentTarget);
      setPopperOpen(false);
    }

    function handleClosePopper() {
      setPopperOpen(false);
      setSelectedRange(new Set());
      setDragEnd(null);
      setDragStart(null);
      setSelectionData({ ...selectionData, id: null });
    }

    function handleUpdate(res) {
      const newEvents = updateOrCreate(events, res);
      onEventsChange(newEvents);
    }

    // toch stuff
    useEffect(() => {
      return;
      if (selectionData || !dragStart) return;
      function handleTouchMove(e) {
        e.preventDefault(); // Verhindert Scrollen

        const touch = e.touches[0]; // Aktuelle Touch-Position
        const element = document.elementFromPoint(touch.clientX, touch.clientY); // Element unter dem Finger abrufen

        if (element) {
          const dayAttr = element.getAttribute('data-date'); // Datum abrufen
          const date = moment(dayAttr, 'YYYY-MM-DD');

          handleMouseMove(date); // Auswahl aktualisieren
        }
      }

      document.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });

      return () => {
        document.removeEventListener('touchmove', handleTouchMove);
      };
    }, [dragStart, selectionData]);

    // event interaction
    function handleEventSelect(e, event) {
      setPopperAnchor(e.currentTarget);
      setPopperOpen(true);
      setSelectionData(event);
    }
    function handleEventEdit(e, event) {
      if (!event.writePermissions) return;
      setPopperAnchor(e.currentTarget);
      setPopperOpen(true);
      setSelectionData(event);
      setEditMode(true);
    }

    function handleDelete(eventId) {
      const newEvents = events.filter((obj) => obj.id !== eventId);
      onEventsChange(newEvents);
    }

    return (
      <Box
        className="w-full h-full flex flex-col gap-1 overflow-y-auto rounded-lg"
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
        sx={{
          bgcolor: noBackground ? 'transparent' : colors.glass,
          backdropFilter: noBlur ? 'unset' : 'blur(10px)',
        }}
      >
        <Box className="w-full grid grid-cols-7 gap-1 sticky top-0 z-20">
          {columns.map((col) => (
            <ExoCalCol key={col} label={col} noBlur={noBlur} />
          ))}
        </Box>
        <Box
          className="w-full h-full grid grid-cols-7 rounded-md"
          sx={{
            gridAutoRows: '1fr',
          }}
        >
          {days &&
            days.map((day) => {
              const dayString = day.date.format('YYYY-MM-DD');
              const isSelected = selectedRange.has(dayString);

              // Check if day is the first or last in the range
              const isFirst = day.date.isSame(
                dragStart || selectionData?.start,
                'day'
              );
              const isLast = day.date.isSame(
                dragEnd || selectionData?.end,
                'day'
              );

              const dragIsInverted =
                dragStart && dragEnd && moment(dragStart).isAfter(dragEnd);

              return (
                <ExoCalDay
                  key={day.date.format('DD-MM-YYYY')}
                  day={day}
                  noBlur={noBlur}
                  selectedEvent={selectionData}
                  isSelected={isSelected}
                  isFirst={dragIsInverted ? isLast : isFirst} // Pass first entry flag
                  isLast={dragIsInverted ? isFirst : isLast} // Pass last entry flag
                  selectionActive={selectedRange.size > 0}
                  onMouseDown={(e) => {
                    if (popperOpen) return;
                    e.preventDefault();
                    handleMouseDown(day.date);
                    handleUpdatePopper(e, day.date);
                  }}
                  onMouseEnter={(e) => {
                    if (dragStart) {
                      if (day.date.isBefore(dragStart))
                        handleUpdatePopper(e, day.date);
                      handleMouseMove(day.date);
                    }
                  }}
                  onDoubleClick={() => {
                    handleDoubleClick(day.date); // Handle double-click for single day event
                  }}
                  onEventSelect={handleEventSelect}
                  onEventEdit={handleEventEdit}
                />
              );
            })}
        </Box>
        <Feedback state={alertState} setState={setAlertState} />

        <ExoCalPopper
          anchorEl={popperAnchor}
          open={popperOpen}
          onClose={handleClosePopper}
          data={selectionData}
          calendars={calendars}
          onFeedback={handleFeedback}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      </Box>
    );
  }
);

export default ExoCal;
