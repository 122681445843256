import moment from 'moment';
import { useEffect, useState } from 'react';
import { useConfig } from '../../api/api';
import { useTranslation } from 'react-i18next';

const DurationLabel = ({ startDateTime, endDateTime }) => {
  const { t } = useTranslation();
  const daysLabel = t('days');
  const hoursLabel = t('hours');
  const minutesLabel = t('minutes');

  // Calculate the duration
  const duration = moment.duration(
    moment(endDateTime).diff(moment(startDateTime))
  );

  // Extract days, hours, and minutes
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  // Build the formatted duration string
  const formattedDuration = [
    days > 0 ? `${days} ${daysLabel}` : '',
    hours > 0 ? `${hours} ${hoursLabel}` : '',
    minutes > 0 ? `${minutes} ${minutesLabel}` : '',
  ]
    .filter(Boolean) // Remove empty values
    .join(' ');

  return <>{formattedDuration}</>;
};

export default DurationLabel;
