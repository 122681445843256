import { Box, Button, Typography, useTheme } from "@mui/material";
import useFetch from "../../../api/useFetch";
import { createElement, useState } from "react";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import { API_DELETE, API_POST, DBRequest, useConfig } from "../../../api/api";
import ExoProcessing from "../../../components/exo/ExoProcessing";

export const EmployeeIntegrations = ({ employeeInfo, onChange, own }) => {
  const { apiData, setApiData } = useFetch(
    own ? "integrations" : `users/${employeeInfo.id}/integrations`
  );
  const config = useConfig();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const integrationMap = {
    googleCalendar: {
      logo: "/assets/meeting-provider/google-calendar-logo.svg",
      height: "90%",
      description: t(
        "Synchronize your Google Calendar with ExoPaper and keep track of all your appointments. Thanks to the seamless integration, you can automatically include your meetings and events in other programs such as Outlook or the native calendar apps from Android and Apple - for optimal planning without duplicate entries."
      ),
    },
    googleMeet: {
      logo: "/assets/meeting-provider/google-meet-logo.svg",
      height: "83%",
      description: t(
        "Connect ExoPaper with Google Meet to plan your meetings seamlessly. When you book an appointment, a meeting link is automatically created and sent to all participants so that you can start straight away - without any additional effort."
      ),
    },
    teams: {
      logo: "/assets/meeting-provider/teams-logo.png",
      height: "100%",
      description: t(
        "Integrate Microsoft Teams into ExoPaper and optimize your meeting planning. When you book an appointment, a Teams meeting is automatically created and the invitation and link are sent to all participants - for seamless collaboration."
      ),
    },
    zoom: {
      logo: "/assets/meeting-provider/zoom-logo.svg",
      height: "50%",
      description: t(
        "Use the Zoom integration in ExoPaper to organize meetings efficiently. When an appointment is created, a Zoom meeting is automatically generated and the invitation link is sent directly to the participants - simple and time-saving."
      ),
    },
  };

  function handleLogin(type) {
    const currentUrl = window.location.href;

    DBRequest({
      config,
      path: "integrations",
      method: API_POST({ type, redirectUrl: currentUrl }),
      onResponse: (res) => openGoogleAuthPopup(res.data?.url),
      onLoading: setIsLoading,
    });
  }

  const openGoogleAuthPopup = (authUrl) => {
    const width = 500;
    const height = 600;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;

    const popup = window.open(
      authUrl,
      "GoogleAuthPopup",
      `width=${width},height=${height},top=${top},left=${left},toolbar=no,menubar=no,resizable=no,scrollbars=yes,status=no`
    );

    if (popup) {
      popup.focus();
    }
  };

  function handleLogout(type) {
    DBRequest({
      config,
      path: `integrations/${type}`,
      method: API_DELETE,
      onResponse: handleDeleteResponse,
    });
  }

  function handleDeleteResponse(data) {
    let newApiData = { data: [] };
    newApiData.data = apiData.data.filter((obj) => obj.type !== data.data.type);
    newApiData.data.push(data.data);

    setApiData(newApiData);
  }

  return (
    <Box className="flex flex-col gap-2 w-full">
      {apiData?.data.map((obj) => (
        <Integration
          data={obj}
          height={integrationMap[obj.type]?.height}
          logo={integrationMap[obj.type]?.logo}
          description={integrationMap[obj.type]?.description}
          onLogin={handleLogin}
          onLogout={handleLogout}
        />
      ))}
      <ExoProcessing
        open={isLoading}
        label={t("Request form for registration")}
      />
    </Box>
  );
};

export const Integration = ({
  data,
  onLogin,
  onLogout,
  logo,
  height,
  description,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  const { actionRequired, type } = data;

  return (
    <Box
      className=" rounded-md px-4 py-2"
      sx={{
        bgcolor: colors.card,
        border: `1px solid ${actionRequired ? colors.warning : colors.success}`,
      }}
    >
      <Box className="flex justify-between items-center">
        <Box
          className="h-[40px] flex items-center px-4 py-1 rounded-full"
          sx={{
            bgcolor: "white",
          }}
        >
          <img
            src={logo}
            style={{
              height,
            }}
            className=" object-contain"
          />
        </Box>
        {actionRequired ? (
          <Button
            variant="contained"
            color="success"
            disableElevation
            size="small"
            onClick={() => onLogin(type)}
          >
            {t("Login")}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="error"
            disableElevation
            size="small"
            onClick={() => onLogout(type)}
          >
            {t("Logout")}
          </Button>
        )}
      </Box>
      <Typography textAlign="justify" className="pt-2">
        {description}
      </Typography>
    </Box>
  );
};
