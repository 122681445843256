import { Box, Button, Typography, useTheme } from '@mui/material';
import React, {
  createElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import CreateIcon from '@mui/icons-material/Create';
import { tokens } from '../../global/theme/tokens';

const demoVariants = [
  {
    label: 'test',
    Icon: CreateIcon,
    content: <Box>Demo</Box>,
  },
];

export const ExoVariants = forwardRef(
  ({ variants = demoVariants, onLoaded = () => {}, size }, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

    const [selected, setSelected] = useState(null);

    useImperativeHandle(ref, () => ({
      reset: handleReset,
      set: handleSet,
    }));

    function handleReset() {
      setSelected(null);
    }

    function handleSet(key) {
      const newSelected = variants.find((obj) => obj.key === key);
      setSelected(newSelected);
    }

    useEffect(() => {
      onLoaded();
    }, []);

    return (
      <>
        {!selected && (
          <Box className=" overflow-y-auto flex-wrap w-full flex h-full flex-row gap-4 justify-center items-center p-4">
            {variants.map((variant) => (
              <Button onClick={() => setSelected(variant)}>
                <Box
                  className=" w-48 rounded-lg  flex flex-col justify-center items-center hover:scale-105 transition-transform"
                  sx={{
                    backgroundColor: colors.card,
                    gap: size === 'small' ? '5px' : '10px',
                    paddingY: size === 'small' ? '10px' : '30px',
                  }}
                >
                  {createElement(variant.Icon, {
                    sx: { fontSize: size === 'small' ? '30px' : '80px' },
                  })}
                  <Typography fontSize={size === 'small' ? '16px' : '19px'}>
                    {variant.label}
                  </Typography>
                </Box>
              </Button>
            ))}
          </Box>
        )}
        {selected && selected.content}
      </>
    );
  }
);
