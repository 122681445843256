import {
  useTheme,
  Box,
  Button,
  Popper,
  ClickAwayListener,
} from '@mui/material';
import { tokens } from '../../../../global/theme/tokens';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useState } from 'react';
import { Calendar } from '../cal-list/ExoCalList';

const CalendarSelect = ({ calendars, onSelect, selectedCal }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  function handleSelect(cal) {
    onSelect(cal);
    setAnchorEl(null);
  }

  return (
    <Box className="flex gap-2 pb-2">
      <Button
        onClick={handleClick}
        sx={{
          margin: '0px',
          padding: '0px',
          bgcolor: colors.card,
          borderRadius: '8px',
          minWidth: '30px',
          minHeight: '30px',
        }}
      >
        <CalendarMonthIcon />
      </Button>
      {selectedCal && <Calendar cal={selectedCal} noIcon />}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{
          zIndex: 9999,
        }}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Box
            sx={{
              backgroundColor: colors.card,
              border: `1px solid ${colors.selected}`,
            }}
            className="flex flex-col backdrop-blur-md rounded-md"
          >
            {calendars?.map(
              (cal) =>
                cal.writePermissions &&
                Boolean(cal.id) && (
                  <Calendar
                    cal={cal}
                    key={cal.id}
                    noIcon
                    onSelect={handleSelect}
                  />
                )
            )}
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default CalendarSelect;
