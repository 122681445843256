import { useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import ExoDialog from "../exo/ExoDialog";
import NewOrderDeliveryNote from "./NewOrderDeliveryNote";
import NewCustomDeliveryNote from "./NewCustomDeliveryNote";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CreateIcon from "@mui/icons-material/Create";
import { ExoVariants } from "../exo/ExoVariants";
import { useEffect, useRef } from "react";

const NewDeliveryNote = ({
  open,
  onClose,
  deliveryNotes,
  setDeliveryNotes,
  startData,
  onUpdate,
  type,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const variantsRef = useRef(null);

  async function handleClose() {
    await variantsRef.current.reset();
    onClose();
  }

  const variants = [
    {
      label: t("Empty"),
      Icon: CreateIcon,
      key: "custom",
      content: (
        <NewCustomDeliveryNote
          onUpdate={onUpdate}
          deliveryNotes={deliveryNotes}
          setDeliveryNotes={setDeliveryNotes}
          onClose={handleClose}
          startData={startData}
        />
      ),
    },
    {
      label: t("Orderbased"),
      Icon: DocumentScannerIcon,
      key: "order",
      content: (
        <NewOrderDeliveryNote
          onUpdate={onUpdate}
          deliveryNotes={deliveryNotes}
          setDeliveryNotes={setDeliveryNotes}
          onClose={handleClose}
        />
      ),
    },
  ];

  useEffect(() => {
    if (variantsRef.current) variantsRef.current.set(type);
  }, [type, open]);

  function checkDeliveryNoteType() {
    if (variantsRef.current) {
      if (startData) {
        variantsRef.current.set("custom");
      } else {
        variantsRef.current.reset();
      }
    }
  }

  return (
    <ExoDialog open={open}>
      <ExoVariants
        ref={variantsRef}
        variants={variants}
        onLoaded={checkDeliveryNoteType}
      />
    </ExoDialog>
  );
};

export default NewDeliveryNote;
