import { Box } from "@mui/material";
import useFetch from "../../../api/useFetch";
import moment from "moment";
import { ExoTimeOverviewCal } from "../../../components/exo/ExoTimeOverviewCal";
import { DBRequest, useConfig } from "../../../api/api";
import { TimeLabelElement } from "../../time-manager/user-entries/timeline/TimeInfo";
import { useTranslation } from "react-i18next";

export const EmployeeOverview = ({ employeeInfo, noPicture, own }) => {
  const config = useConfig();
  const { t } = useTranslation();

  const startDate = moment().startOf("M").format("YYYY-MM-DD");
  const endDate = moment().endOf("M").format("YYYY-MM-DD");
  const { apiData, setApiData } = useFetch(
    own
      ? `time-tracking/overview/own?period=day&startDate=${startDate}&endDate=${endDate}`
      : `users/${employeeInfo.id}/time-tracking/overview?period=day&startDate=${startDate}&endDate=${endDate}`
  );

  function handleChangePeriod(newStartDate, newEndDate) {
    if (!apiData) return;
    setApiData(null);
    DBRequest({
      config,
      path: own
        ? `time-tracking/overview/own?period=day&startDate=${newStartDate}&endDate=${newEndDate}`
        : `users/${employeeInfo.id}/time-tracking/overview?period=day&startDate=${newStartDate}&endDate=${newEndDate}`,
      onResponse: setApiData,
    });
  }

  return (
    <Box className="w-full">
      <Box className="flex justify-evenly pb-4 flex-wrap gap-2">
        <TimeLabelElement
          src={"/assets/uploads/times/account.webp"}
          label={t("Time Account")}
          totalTime={employeeInfo.timeAccount * 60}
          percent={1}
          hidePercent
          useFix
        />
        <TimeLabelElement
          src={"/assets/uploads/times/vacation.webp"}
          label={t("Vacation available")}
          totalTime={employeeInfo.vacationTotal}
          percent={
            employeeInfo.vacationTotal !== 0
              ? 1 - employeeInfo.vacationRequested / employeeInfo.vacationTotal
              : 0
          }
          format="day"
          sufix={t("Days")}
        />
        <TimeLabelElement
          src={"/assets/uploads/times/sick.webp"}
          label={t("Sick")}
          totalTime={employeeInfo.sickDaysTaken}
          percent={1}
          hidePercent
          format="day"
          sufix={t("Days")}
        />
      </Box>

      <ExoTimeOverviewCal
        times={apiData?.data.times}
        onChange={handleChangePeriod}
        activeTime={apiData?.data.activeTimeTracking}
      />
    </Box>
  );
};
