import { Box, Button, Typography } from '@mui/material';
import CardGrid from '../../../components/cards/layout/CardGrid';
import {
  requestTypesIcons,
  requestTypesStrings,
} from '../../../components/special/requestTypes';
import CardContainer from '../../../components/cards/layout/CardContainer';
import { API_GET, API_PATCH, DBRequest, useConfig } from '../../../api/api';
import Feedback from '../../../components/special/Feedback';
import { useEffect, useState } from 'react';
import ExoWorkday from '../../../components/exo/ExoWorkday';
import DateTimeLabel from '../../../components/label/DateTimeLabel';
import { useTranslation } from 'react-i18next';
import SuccessCheck from '../../../components/special/SuccessCheck';
import FailureCross from '../../../components/special/FailureCross';
import PictureNameCard from '../../../components/cards/PictureNameCards/PictureNameCard';

const ExtendTimeCorrection = ({ request, onUpdate }) => {
  const { t } = useTranslation();
  const config = useConfig();

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: 'info',
    alertText: 'test',
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  const [showRequest, setShowRequest] = useState(null);

  useEffect(() => {
    DBRequest({
      config,
      path: `employeerequests/${request.id}`,
      method: API_GET,
      onResponse: setShowRequest,
    });
  }, [request, config]);

  return (
    <>
      <CardGrid>
        <CardContainer span={5} className="">
          <Typography
            variant="h3"
            className="flex items-center justify-center gap-2"
          >
            {requestTypesIcons[request.type]}
            {t(requestTypesStrings[request.type])}
          </Typography>
          <Typography variant="h6">
            <DateTimeLabel dateTime={request.createdAt} />
          </Typography>
        </CardContainer>
        <PictureNameCard
          data={request.user}
          type="user"
          title={t('Requester')}
          pictureUrl={
            request.user.picture ? request.user.picture.temporaryUrl : ''
          }
          name={`${request.user.firstName} ${request.user.lastName}`}
          mail={request.user.email}
          phone={request.user.phone}
          position={request.user.position}
        />

        <CardContainer>
          <RequestController
            request={request}
            onUpdate={onUpdate}
            onFeedback={handleAlertOpen}
          />
        </CardContainer>
        <CardContainer span={99} rows={2}>
          <Box className="flex w-full justify-between">
            {showRequest && (
              <ExoWorkday
                res={2}
                userId={request.user.id}
                resHeight={15}
                corrections={showRequest.data.corrections}
                customColumnLabel
                noInfo
                comment={request.comment}
              />
            )}
          </Box>
        </CardContainer>
      </CardGrid>

      <Feedback setState={setAlertState} state={alertState} />
    </>
  );
};

export const RequestController = ({ request, onUpdate, onFeedback }) => {
  const config = useConfig();
  const { t } = useTranslation();
  function handleStatusUpdate(value) {
    const data = { status: value };

    DBRequest({
      config,
      path: `employeerequests/${request.id}`,
      method: API_PATCH(data),
      onResponse: handleUpdate,

      onFeedback,
    });
  }

  function handleUpdate(response) {
    onUpdate(response.data);
  }

  return (
    <Box className="flex flex-col gap-2">
      {request.status !== 1 && (
        <>
          {request.status !== 0 && <FailureCross />}
          <Button
            color="success"
            variant="contained"
            onClick={() => handleStatusUpdate(1)}
          >
            {t('Accept')}
          </Button>
        </>
      )}
      {request.status !== 2 && (
        <>
          {request.status !== 0 && <SuccessCheck />}
          <Button
            color="error"
            variant="contained"
            onClick={() => handleStatusUpdate(2)}
          >
            {t('Decline')}
          </Button>
        </>
      )}
    </Box>
  );
};

export default ExtendTimeCorrection;
