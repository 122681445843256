import {
  Box,
  Button,
  Collapse,
  IconButton,
  Link,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import React, { createElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from '../../cal-list/ExoCalList';
import DateLabel from '../../../../label/DateLabel';
import { TimeLabel } from '../../../../label/TimeLabel';
import DurationLabel from '../../../../label/DurationLabel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import StyledTextContainer from '../../../../preview/StyledTextContainer';
import RenderHTML from '../../../../preview/RenderHTML';
import { tokens } from '../../../../../global/theme/tokens';
import VideocamIcon from '@mui/icons-material/Videocam';
import ExoAvatar from '../../../ExoAvatar';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import HelpIcon from '@mui/icons-material/Help';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ContactsIcon from '@mui/icons-material/Contacts';
import GroupsIcon from '@mui/icons-material/Groups';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { NavLink } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import { ExoSkeleton } from '../../../ExoSkeleton';
export const CalMeetingShow = ({
  dataCollection,
  onClose,
  onEdit,
  isSmallScreen,
  isLoading,
  wholeDayData,
  writePermissions,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [openParticipants, setOpenParticipants] = useState(false);

  const {
    startDateTime,
    endDateTime,
    agenda,
    label,
    calendar,
    color,
    internalNote,
    wholeDay,
    onlineMeeting,
    location,
    owner,
    participants,
    project,
  } = dataCollection;

  const daysDiff = moment(dataCollection.endDateTime).diff(
    moment(dataCollection.startDateTime),
    'days'
  );

  const partTypeMap = {
    contact: Contact,
    company: Company,
    employee: Contact,
    user: User,
  };

  const statusMap = {
    0: {
      label: t('No Response'),
      color: colors.text,
      Icon: (
        <RadioButtonUncheckedIcon
          className="opacity-75"
          sx={{ fontSize: '16px' }}
        />
      ),
      message: t('Response expected'),
    },
    1: {
      label: t('Accepted'),
      color: colors.success,
      Icon: (
        <ThumbUpIcon
          className="opacity-75"
          sx={{ fontSize: '16px' }}
          color="success"
        />
      ),
      message: t('Meeting confirmed'),
    },
    2: {
      label: t('Declined'),
      color: colors.error,
      Icon: (
        <ThumbDownIcon
          className="opacity-75"
          sx={{ fontSize: '16px' }}
          color="error"
        />
      ),
      message: t('Meeting canceled'),
    },
    3: {
      label: t('Maybe'),
      color: colors.grey[400],
      Icon: <HelpIcon className="opacity-75" sx={{ fontSize: '16px' }} />,
      message: t('Meeting confirmed with reservation'),
    },
  };

  const integrationMap = {
    googleMeet: {
      logo: '/assets/meeting-provider/google-meet-logo.svg',
      height: '60%',
    },
    teams: {
      logo: '/assets/meeting-provider/teams-logo.png',
      height: '100%',
    },
    zoom: {
      logo: '/assets/meeting-provider/zoom-logo.svg',
      height: '50%',
    },
  };

  const replaceSpaceWithPlus = (str) => str.replace(/ /g, '+');

  if (isLoading)
    return (
      <Box className="flex h-full flex-col pr-4">
        <IconButton
          onClick={onClose}
          className="top-2 right-2 "
          sx={{
            position: 'absolute',
          }}
        >
          <CloseIcon />
        </IconButton>
        <ExoSkeleton size="24px" className="pb-[4px]" width="70%" />
        {!writePermissions && (
          <ExoSkeleton size="21px" className="pb-[8px]" width="60%" />
        )}
        {wholeDayData ? (
          <ExoSkeleton size="21px" className="py-[8px]" width="40%" />
        ) : (
          <>
            <ExoSkeleton
              size="21px"
              className="pt-[8px] pb-[4px]"
              width="85%"
            />
            <ExoSkeleton size="21px" className="pb-[8px]" width="40%" />
          </>
        )}
        <ExoSkeleton size="100%" className="flex h-full" />
      </Box>
    );

  return (
    <Box className="flex h-full flex-col">
      <IconButton
        onClick={onClose}
        className="top-2 right-2 "
        sx={{
          position: 'absolute',
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box className="flex flex-col justify-between h-full  gap-4  overflow-y-auto pr-2">
        <Box>
          <Box>
            <Typography
              variant="h4"
              className="pb-1 pr-[55px]"
              fontWeight={500}
            >
              {label}
            </Typography>
            {!writePermissions && (
              <Typography
                fontWeight={500}
                sx={{ color: colors.error }}
                className="pb-2"
              >
                <WarningIcon className="mr-2" />
                {t('read-only')}
              </Typography>
            )}
          </Box>
          {calendar && <Calendar cal={{ ...calendar, color: color }} noIcon />}
          <Box className="flex gap-1 pt-2 pb-2 flex-wrap">
            <Typography className="pr-4" fontWeight="500">
              <CalendarMonthIcon className="mr-2" />
              <DateLabel date={startDateTime} />
              {daysDiff > 0 && (
                <>
                  {' - '}
                  <DateLabel date={endDateTime} />
                </>
              )}
            </Typography>
            {!wholeDay && (
              <Typography className="pr-4" fontWeight="500">
                <AccessTimeIcon className="mr-2" />
                <TimeLabel time={startDateTime} format="HH:mm" />
                {` ${t("o'clock")}`}
              </Typography>
            )}
            {!wholeDay && (
              <Typography className="pr-4" fontWeight="500">
                <AvTimerIcon className="mr-2" />
                <DurationLabel
                  startDateTime={startDateTime}
                  endDateTime={endDateTime}
                />
              </Typography>
            )}
          </Box>

          {project && (
            <Box>
              <Typography fontWeight={600}>{t('Project')}</Typography>
              <NavLink to={`/projects/management/${project.id}`}>
                <Button className="flex items-center gap-3">
                  <img
                    className="w-[40px] h-[40px] object-contain rounded-md"
                    src={
                      project.image
                        ? project.image.temporaryUrl
                        : project.client?.logo?.temporaryUrl
                    }
                  />
                  <Typography>{project.name}</Typography>
                </Button>
              </NavLink>
            </Box>
          )}

          {agenda && (
            <Box className="max-h-[200px] overflow-y-auto">
              <Typography fontWeight={600}>{t('Agenda')}</Typography>
              <StyledTextContainer noWidth backgroundColor="transparent">
                <RenderHTML HTML={agenda} />
              </StyledTextContainer>
            </Box>
          )}

          {onlineMeeting && (
            <Box className="pb-1">
              <Typography fontWeight={600} className="pb-1">
                {t('Online')}
              </Typography>
              <Box className="flex gap-2 items-center w-full h-[30px]">
                <Link href={onlineMeeting.url} target="_blank">
                  <IconButton
                    className="w-full"
                    size="small"
                    disableElevation
                    sx={{
                      bgcolor: colors.info + '30',
                      border: `1px solid ${colors.info}80`,
                      ':hover': {
                        bgcolor: colors.info + '60',
                        border: `1px solid ${colors.info}80`,
                      },
                    }}
                  >
                    <VideocamIcon className="px-[1px]" />
                  </IconButton>
                </Link>
                {integrationMap[onlineMeeting.type] ? (
                  <img
                    src={integrationMap[onlineMeeting.type].logo}
                    style={{
                      height: integrationMap[onlineMeeting.type].height,
                    }}
                    className="mr-2"
                  />
                ) : (
                  <Typography>{t('Online Meeting Link')}</Typography>
                )}
              </Box>
            </Box>
          )}

          {location && (
            <Box className="pb-2  py-2">
              <Typography fontWeight={600}>{t('Location')}</Typography>
              <Box className="flex items-center gap-1">
                <Link
                  href={`https://www.google.de/maps/place/${location.street}+${location.houseNumber},+${location.zipCode}+${location.city}`}
                  target="_blank"
                >
                  <IconButton
                    className="w-full"
                    size="small"
                    disableElevation
                    sx={{
                      bgcolor: colors.info + '30',
                      border: `1px solid ${colors.info}80`,
                      ':hover': {
                        bgcolor: colors.info + '60',
                        border: `1px solid ${colors.info}80`,
                      },
                    }}
                  >
                    <GoogleIcon className="px-[1px]" />
                  </IconButton>
                </Link>
                <Link
                  href={`http://maps.apple.com/?address=${replaceSpaceWithPlus(
                    location.houseNumber
                  )},${replaceSpaceWithPlus(
                    location.street
                  )},${replaceSpaceWithPlus(location.city)},${
                    location.zipCode
                  }`}
                  target="_blank"
                >
                  <IconButton
                    className="w-full"
                    size="small"
                    disableElevation
                    sx={{
                      bgcolor: colors.info + '30',
                      border: `1px solid ${colors.info}80`,
                      ':hover': {
                        bgcolor: colors.info + '60',
                        border: `1px solid ${colors.info}80`,
                      },
                    }}
                  >
                    <AppleIcon className="pb-[2px] pr-[1px]" />
                  </IconButton>
                </Link>
                <Typography className="pb-1 pl-1">{`${location.street} ${location.houseNumber} - ${location.zipCode} ${location.city}`}</Typography>
              </Box>
            </Box>
          )}

          {participants && participants.length > 0 && (
            <Box className="flex flex-col gap-1">
              <Button
                sx={{ padding: 0, justifyContent: 'space-between' }}
                onClick={() => setOpenParticipants(!openParticipants)}
              >
                <Typography fontWeight={600} className="pb-1">
                  {`${t('Participants')} (${participants.length})`}
                </Typography>
                <ArrowDropDownIcon
                  sx={{
                    transform: `rotate(${openParticipants ? 180 : 0}deg)`,
                  }}
                />
              </Button>
              <Collapse in={openParticipants}>
                <Box className="flex flex-col gap-1">
                  {participants?.map(
                    (part) =>
                      partTypeMap[part.type] &&
                      createElement(partTypeMap[part.type], {
                        part,
                        status: statusMap[part.status],
                        isSmallScreen,
                      })
                  )}
                </Box>
              </Collapse>
            </Box>
          )}

          {internalNote && (
            <Box className="max-h-[200px] overflow-y-auto">
              <Divider />
              <Typography fontWeight={600}>{t('Internal note')}</Typography>
              <StyledTextContainer noWidth backgroundColor="transparent">
                <RenderHTML HTML={internalNote} />
              </StyledTextContainer>
            </Box>
          )}
        </Box>

        {false && (
          <ExoAvatar
            className="bottom-0 right-4"
            sx={{
              position: 'absolute',
            }}
            size="40px"
            picture={owner?.picture?.temporaryUrl}
            name={`${owner?.lastName} ${owner?.lastName}`}
          />
        )}

        {isSmallScreen && writePermissions && (
          <Box>
            <Button variant="outlined" color="info" onClick={onEdit}>
              {t('Edit')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const Divider = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box className="py-4 px-4">
      <Box
        sx={{
          height: '1px',
          bgcolor: colors.text + '30',
        }}
        className="rounded-full"
      />
    </Box>
  );
};

const Contact = ({ part, status, isSmallScreen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { email, participant } = part;
  const { firstName, lastName, salutation } = participant;

  const Icon = ContactsIcon;

  return (
    <Box
      className="flex items-center gap-2 px-2 py-[2px] rounded-md"
      sx={{
        bgcolor: status.color + '20',
        border: `1px solid ${status.color}30`,
      }}
    >
      {status?.Icon}
      <Box className="flex w-full justify-between flex-col">
        <Typography fontSize={12} fontWeight={500}>
          <Icon className="opacity-50 py-[3px] mr-[2px]" />
          {`${salutation} ${firstName} ${lastName}`}
        </Typography>
        <Link href={`mailto:${email}`}>
          <Typography
            fontSize={12}
            className=" opacity-50"
            sx={{ color: colors.text }}
          >
            [{email}]
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

const Company = ({ part, status, isSmallScreen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { email, participant } = part;
  const { nameShort } = participant;

  const Icon = ApartmentIcon;

  return (
    <Box
      className="flex items-center gap-2 px-2 py-[2px] rounded-md"
      sx={{
        bgcolor: status.color + '20',
        border: `1px solid ${status.color}30`,
      }}
    >
      {status?.Icon}
      <Box className="flex w-full justify-between flex-col">
        <Typography fontSize={12} fontWeight={500}>
          <Icon className="opacity-50 py-[3px] mr-[2px]" />
          {nameShort}
        </Typography>
        <Link href={`mailto:${email}`}>
          <Typography
            fontSize={12}
            className=" opacity-50"
            sx={{ color: colors.text }}
          >
            [{email}]
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

const User = ({ part, status, isSmallScreen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { email, participant } = part;
  const { firstName, lastName, salutation } = participant;

  const Icon = GroupsIcon;

  return (
    <Box
      className="flex items-center gap-2 px-2 py-[2px] rounded-md"
      sx={{
        bgcolor: status.color + '20',
        border: `1px solid ${status.color}30`,
      }}
    >
      {status?.Icon}
      <Box className="flex w-full justify-between flex-col">
        <Typography fontSize={12} fontWeight={500}>
          <Icon className="opacity-50 py-[3px] mr-[2px]" />
          {`${salutation} ${firstName} ${lastName}`}
        </Typography>
        <Link href={`mailto:${email}`}>
          <Typography
            fontSize={12}
            className=" opacity-50"
            sx={{ color: colors.text }}
          >
            [{email}]
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};
